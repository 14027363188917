import Layout from "../common/components/Layout";
import WithOutHeaderSideLayout from "../common/components/WithOutHeaderSideLayout";
import PublicLayout from "../common/components/PublicLayout";

import { Allloader } from "../common/components/layout/loader";
import Empty from "../pages/brand/campaign/Empty";
import Campaigns from "../pages/campaigns/";
import AddCampaigns from "../pages/campaigns/add";
import EditCampaigns from "../pages/campaigns/edit";
import Campaigndetails from "../pages/campaigns/detail";
import Storedetails from "../pages/brand/dashboard/Storedetails";
import Dashboard from "../pages/campaigns/dashboard/";
import CustomerDashboard from "../pages/campaigns/customerDashboard";
import Products from "../pages/products/index";
import AddProducts from "../pages/products/add";
import ForgetPassword from "../pages/forgetPassword/";
import ResetPassword from "../pages/resetPassword";
import ChangePassword from "../pages/changePassword";
import JoinCampaigns from "../pages/join/campaigns/";
import Notification from "../pages/join/campaigns/notification"
import JoinCampaignsDetails from "../pages/join/campaigns/details"
import JoinCampaignsInventory from "../pages/join/campaigns/inventory"
import JoinCampaignsSuccess from "../pages/join/campaigns/success";
import Public from '../pages/public/'
import CampaignDetail from '../pages/public/detail'
import AddStore from '../pages/public/add'
import Otp from '../pages/public/otp'
import VerifyAccount from '../pages/public/verifyAccount'
import MultiStore from '../pages/public/store'
import PublicSuccess from "../pages/public/success";
import MyProfile from "../pages/myprofile/index";
import Contact from "../pages/frontend/contact";
import ProxyLogin from "../pages/proxyLogin";
import SaveToPhone from '../pages/public/saveToPhone';
import Home from '../pages/frontend/index'
// import Stasher from '../pages/frontend/stasher'
import Businesses from '../pages/frontend/businesses'
import BrandAndMarketing from '../pages/frontend/brand'
import Privacy from '../pages/frontend/privacy'
import Settings from "../pages/settings/index"; 
import CustomerView from '../pages/public/customerView';
import FindStore from '../pages/public/findStore';
export const AppRouter = [
  // {
  //   path: "/",
  //   exact: true,
  //   name: "Login",
  //   layout: WithOutHeaderSideLayout,
  //   component: Login,
  // },
  {
    path: "/",
    exact: true,
    name: "Home",
    layout: WithOutHeaderSideLayout,
    component: Home,
  },
  // {
  //   path: "/stasher",
  //   exact: true,
  //   name: "Stasher",
  //   layout: WithOutHeaderSideLayout,
  //   component: Stasher,
  // },
  {
    path: "/business",
    exact: true,
    name: "Businesses",
    layout: WithOutHeaderSideLayout,
    component: Businesses,
  },
  {
    path: "/brand",
    exact: true,
    name: "BrandAndMarketing",
    layout: WithOutHeaderSideLayout,
    component: BrandAndMarketing,
  },
  {
    path: "/contact",
    exact: true,
    name: "Contact",
    layout: WithOutHeaderSideLayout,
    component: Contact,
  },
  {
    path: "/frontend/privacy",
    exact: true,
    name: "Privacy",
    layout: WithOutHeaderSideLayout,
    component: Privacy,
  },
  {
    path: "/public",
    exact: true,
    name: "Website",
    layout: PublicLayout,
    component: Public,
  },
  {
    path: "/public/detail/:campaignId",
    exact: true,
    name: "Website",
    layout: PublicLayout,
    component: CampaignDetail,
  },
  {
    path: "/public/add",
    exact: true,
    name: "Website",
    layout: PublicLayout,
    component: AddStore,
  },
  {
    path: "/public/otp",
    exact: true,
    name: "Website",
    layout: PublicLayout,
    component: Otp,
  },
  {
    path: "/public/verify-account",
    exact: true,
    name: "Website",
    layout: PublicLayout,
    component: VerifyAccount,
  },
  {
    path: "/public/store",
    exact: true,
    name: "Website",
    layout: PublicLayout,
    component: MultiStore,
  },
  {
    path: "/public/success",
    exact: true,
    name: "Website",
    layout: PublicLayout,
    component: PublicSuccess,
  },
  {
    path: "/join-campaign",
    exact: true,
    name: "Campaign",
    layout: WithOutHeaderSideLayout,
    component: JoinCampaigns,
  },
  {
    path: "/join-campaign/notifications",
    exact: true,
    name: "Campaign",
    layout: WithOutHeaderSideLayout,
    component: Notification,
  },
  {
    path: "/join-campaign/detail",
    exact: true,
    name: "Campaign",
    layout: WithOutHeaderSideLayout,
    component: JoinCampaignsDetails,
  },
  {
    path: "/join-campaign/inventory",
    exact: true,
    name: "Campaign",
    layout: WithOutHeaderSideLayout,
    component: JoinCampaignsInventory,
  },
  {
    path: "/join-campaign/success",
    exact: true,
    name: "Campaign",
    layout: WithOutHeaderSideLayout,
    component: JoinCampaignsSuccess,
  },
  // {
  //   path: "/details",
  //   exact: true,
  //   name: "Campaign Details",
  //   layout: Layout,
  //   component: Campaignsdetails,
  // },
  {
    path: "/storedetails",
    exact: true,
    name: "Store Details",
    layout: Layout,
    component: Storedetails,
  },
  {
    path: "/empty",
    exact: true,
    name: "Empty",
    layout: Layout,
    component: Empty,
  },
  {
    path: "/allloader",
    exact: true,
    name: "All Loader",
    layout: Layout,
    component: Allloader,
  },
  {
    path: "/products",
    exact: true,
    name: "Products",
    layout: Layout,
    component: Products,
  },
  {
    path: "/product/add",
    exact: true,
    name: "AddProducts",
    layout: Layout,
    component: AddProducts,
  },
  {
    path: "/campaigns",
    exact: true,
    name: "Home",
    layout: Layout,
    component: Campaigns,
  },
  {
    path: "/campaign/add",
    exact: true,
    name: "Add Campaigns",
    layout: Layout,
    component: AddCampaigns,
  },
  {
    path: "/campaign/edit/:id",
    exact: true,
    name: "Edit Campaigns",
    layout: Layout,
    component: EditCampaigns,
  },
  {
    path: "/campaign/details/:id",
    exact: true,
    name: "Campaign Details",
    layout: Layout,
    component: Campaigndetails,
  },
  {
    path: "/campaign/dashboard/:id",
    exact: true,
    name: "Dashboard",
    layout: Layout,
    component: Dashboard,
  },
  {
    path: "/campaign/customer-dashboard/:id",
    exact: true,
    name: "Customer Dashboard",
    layout: Layout,
    component: CustomerDashboard,
  },
  {
    path: "/forget-password",
    exact: true,
    name: "Forget Password",
    layout: WithOutHeaderSideLayout,
    component: ForgetPassword,
  },
  {
    path: "/reset-password/:id",
    exact: true,
    name: "Forget Password",
    layout: WithOutHeaderSideLayout,
    component: ResetPassword,
  },
  {
    path: "/change-password",
    exact: true,
    name: "Change Password",
    layout: Layout,
    component: ChangePassword,
  },
  {
    path: "/settings",
    exact: true,
    name: "Settings",
    layout: Layout,
    component: Settings,
  },
  {
    path: "/profile",
    exact: true,
    name: "My Profile",
    layout: Layout,
    component: MyProfile,
  },
  {
    path: "/proxy-login",
    exact: true,
    name: "Proxy Login",
    layout: WithOutHeaderSideLayout,
    component: ProxyLogin,
  },

  {
    path: "/c/:campaignCode",
    exact: true,
    name: "Website",
    layout: WithOutHeaderSideLayout,
    component: CustomerView,
  },
  {
    path: "/c/:campaignCode/save-to-phone",
    exact: true,
    name: "Website",
    layout: WithOutHeaderSideLayout,
    component: SaveToPhone,
  },
  {
    path: "/c/:campaignCode/find-store",
    exact: true,
    name: "Website",
    layout: WithOutHeaderSideLayout,
    component: FindStore,
  },
  {
    path: "/:campaignId",
    exact: true,
    name: "campaign details",
    layout: PublicLayout,
    component: CampaignDetail,
  },
];
