import React, { useState } from "react";
import { Tooltip, Input, Button } from "antd";
import { CopyOutlined, EyeOutlined } from "@ant-design/icons";

const ClipboardCopy = (props) => {
  const [isCopied, setIsCopied] = useState(false);

  // This is the function we wrote earlier
  async function copyTextToClipboard(text) {
    if ("clipboard" in navigator) {
      return await navigator.clipboard.writeText(text);
    } else {
      return document.execCommand("copy", true, text);
    }
  }

  // onClick handler function for the copy button
  const handleCopyClick = () => {
    // Asynchronously call copyTextToClipboard
    copyTextToClipboard(props.copyText)
      .then(() => {
        // If successful, update the isCopied state value
        setIsCopied(true);
        setTimeout(() => {
          setIsCopied(false);
        }, 1500);
      })
      .catch((err) => {});
  };

  return (
    <Input.Group compact>
      <Input
        style={{ width: "calc(100% - 100px)" }}
        defaultValue={props.copyText}
      />      
      <Tooltip title={isCopied ? "Copied!" : "Copy"} onClick={handleCopyClick}>
        <Button icon={<CopyOutlined />} style={{borderRadius: '0 5px 5px 0px!important'}}/>
      </Tooltip>
      <Tooltip title="View">
        <a href={props.copyText}  className="ml-2">
        <Button icon={<EyeOutlined />} className="btn"/></a>
      </Tooltip>
    </Input.Group>
  );
};

export { ClipboardCopy };
