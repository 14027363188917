import { SearchOutlined } from "@ant-design/icons";
import { Input } from "antd";
import React from "react";

const Search = (props) => {
  return (
    <Input
      placeholder={props.placeholder || "Search"}
      prefix={<SearchOutlined />}
      className="search-input"
      // onChange={props.action}
      onKeyUp={props.action}
    />
  );
};
export { Search };
