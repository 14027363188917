import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { PageTitle, Breadcrumbsec } from "../../common/components/layout/";
import { Layout, Row, Col, Space, Button, message, Form } from "antd";
import { SaveOutlined } from "@ant-design/icons";
import { ProductsAdd } from "../../modules/products/";
import { ApiRoutes } from "../../config/ApiRoutes";
import { ApiHelper } from "../../Helpers";
import { Loader } from "../../common/components/Loader";
import "../../modules/products/products.less";
const { Content } = Layout;
const title = "Create Product";
const prevpagetitle = "Product List";
const currentpagetitle = "Create";
const prevlink = "/products";

const AddProducts = () => {
  const [addFrom, setAddFrom] = useState(true);
  const [selectedProductData, setSelectedProductData] = useState([]);
  const [loader, setLoader] = useState(false);

  const [imageError, setImageError] = useState(false);
  const [productImage, setProductImage] = useState([]);
  const [form] = Form.useForm();

  const onFinish = async (status = true) => {
    // setAddFrom(status);
    if (productImage[0]?.thumbUrl === undefined) {
      setImageError(true);
      return false;
    }
    const values = await form.validateFields();
    let temp = selectedProductData;
    temp.push({
      name: values.productName,
      upc: values.productUPC,
      productImage: productImage[0]?.thumbUrl
    });
    setSelectedProductData(temp);
    setImageError(false);
    setProductImage([]);
    form.resetFields();
    setAddFrom(status);
    if (!status) {
      return {
        temp,
        status
      };
    }
  };
  const removeAddDiv = (status) => {
    form.resetFields();
    setProductImages("");
    setImageError(false);
    setAddFrom(status);
  };
  const setProductImages = (data) => {
    setProductImage(data);
    setImageError(false);
  };
  const removeProduct = (key) => {
    let temp = selectedProductData;
    const filtered = temp.filter(function (value, index) {
      return key !== index;
    });
    setSelectedProductData(filtered);
  };

  const history = useHistory();

  const checkUPSNumber = async (upc) => {
    let status = true;
    selectedProductData.forEach((value) => {
      if (value.upc === upc) status = false;
    });

    if (!status) return false;
    const response = await new ApiHelper().FetchFromServer(
      ApiRoutes.CHECK_UPS_NUMBER.service,
      ApiRoutes.CHECK_UPS_NUMBER.url,
      ApiRoutes.CHECK_UPS_NUMBER.method,
      ApiRoutes.CHECK_UPS_NUMBER.authenticate,
      { upc: upc.trim() }
    );
    if (response.code === 200) return true;
    else return false;
  };

  const setAddProduct = async () => {
    let product = selectedProductData;
    
    if (addFrom) {
      const data = await onFinish(false);
      if (data === false) return false;
      if (data.status === false) {
        product = data.temp;
      }
      if (data.status || product.length === 0) {
        message.error({
          content: "Please fill product info.",
          duration: 2
        });
        return false;
      }
    }
    
    setLoader(true);
    const response = await new ApiHelper().FetchFromServer(
      ApiRoutes.PRODUCT_ADD.service,
      ApiRoutes.PRODUCT_ADD.url,
      ApiRoutes.PRODUCT_ADD.method,
      ApiRoutes.PRODUCT_ADD.authenticate,
      undefined,
      { product: product }
    );
    if (response.code === 200 && response.data.success === 1) {
      message.success({
        content: response.data.message,
        duration: 2
      });
      setTimeout(function () {
        history.push({
          pathname: `/products`
        });
        setLoader(false);
      }, 1000);
      ///setProduct(response.data.data);
    } else {
      setLoader(false);
      message.error({
        content: response.messages || response.error,
        duration: 2
      });
    }
  };
  return (
    <>
      <Space className="filter-sec m-d-block headerFix">
        <PageTitle title={title} />
        <Breadcrumbsec
          prevpagetitle={prevpagetitle}
          currentpagetitle={currentpagetitle}
          prevlink={prevlink}
        />
        <Button
          type="primary"
          htmlType="submit"
          icon={<SaveOutlined />}
          onClick={() => setAddProduct()}
        >
          Submit Product
        </Button>
      </Space>
      <Content
        className="content-body"
        style={{ minHeight: 200, margin: "0px 25px" }}
        id="Products"
      >
        <Row>
          {loader ? (
            <section
              className="text-center"
              style={{
                height: "80vh",
                display: "flex",
                justifyContent: "center",
                alignItems: "center"
              }}
            >
              <Loader />
            </section>
          ) : (
            <Col lg={24} xs={24}>
              <div style={{ margin: "0px 14px 14px 14px" }}>
                <ProductsAdd
                  addFrom={addFrom}
                  setAddFrom={setAddFrom}
                  selectedProductData={selectedProductData}
                  setSelectedProductData={setSelectedProductData}
                  imageError={imageError}
                  productImage={productImage}
                  setProductImage={setProductImage}
                  setImageError={setImageError}
                  removeProduct={removeProduct}
                  form={form}
                  setProductImages={setProductImages}
                  onFinish={onFinish}
                  removeAddDiv={removeAddDiv}
                  checkUPSNumber={checkUPSNumber}
                />
              </div>
            </Col>
          )}
        </Row>
      </Content>
    </>
  );
};
export default AddProducts;
