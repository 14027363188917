import React from "react";
import { Row, Col, Skeleton } from "antd";
// import { AppConfig } from "../../config/AppConfig";
// import dummyImage from '../../assets/images/templateNotFound.png';

const Productlist = ({ loading, campaignData }) => {
  return (
    <>
      <div className="productsBox">
        <Row gutter="48" className="d-flex">
          <Col xl={24} xs={24}>
            <h2 className="heading">Products</h2>
          </Col>
          {campaignData && campaignData.products.map((item, key) => {
            return (
              <Col xl={6} xs={24} key={key} className='d-flex' style={{flexGrow: 1}}>
                <div className="innerBox w-100">
                  <Skeleton paragraph={{ rows: 4 }} loading={loading} active>
                    <img
                      src={item.imageName}
                       alt="icon"
                    />
                    <div className="details">
                      <h4
                        title={item.productCodeOveride}
                      >
                        {item.productCodeOveride}
                      </h4>
                      <p>
                        <strong>UPC | </strong> <span>{item.upc}</span>
                      </p>
                    </div>
                  </Skeleton>
                </div>
              </Col>
            )
          })}
        </Row>
      </div>
    </>
  );
};

export default Productlist;
