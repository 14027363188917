

const timeZone = (date, timezone) => {
   // let nDate = moment(date).utcOffset(0, true).format();
  // return date;
  // moment.tz.setDefault("-0530");
//   let timezonedetail= moment.tz.guess();
//    console.log(timezonedetail)
//     let newDate = moment(date).tz("-05:00", false).utc().format();
   
//    // let nDate = moment(date).utcOffset(0, true).format();
//     return newDate;
     let nDate = new Date(date+"+05:30").toLocaleString('en-US', {
        timeZone: timezone,
        hour: '2-digit', hour12: false, minute: '2-digit', second: '2-digit', year: 'numeric',
        month: '2-digit', day: '2-digit'
    });
    nDate = nDate.replace('24:', '00:');
    return nDate.replace(',', '').replace(/(\d\d)\/(\d\d)\/(\d{4})/, "$3-$1-$2"); 
}
export {
    timeZone
}