import React from "react";
import { Route, Switch } from "react-router-dom";
import { AppRouter } from "./config/AppRouter";
import Public from './pages/public/'
import PublicLayout from "./common/components/PublicLayout";
import { AppConfig } from "./config/AppConfig";
import "./assets/css/app.less";

/* Browser history manage */
const AppRoute = ({ component: Component, layout: Layout, ...rest }) => (
  <Route
    {...rest}
    render={(props) => {
      return (
        <Layout>
          <Component {...props} />
        </Layout>
      );
    }}
  />
);

const App = () => {
  let host = window.location.host;
  let parts = host.split(".");
  let subdomain = '';
  if (parts.length >= Number(AppConfig.DOMAIN_LENGTH)) {
    subdomain = parts[0];
    // Remove the subdomain from the parts list
    parts.splice(0, 1);
    // Set the location to the new url
  }
  return (
    <Switch>
      {AppRouter.map((route, index) => {
        return (
          <AppRoute
            key={index}
            exact={route.exact}
            path={route.path}
            layout={subdomain!=='' && route.name==='Home' ? PublicLayout : route.layout}
            subdomain={subdomain}
            component={subdomain!=='' && route.name==='Home' ? Public : route.component}
          />
        );
      })}
    </Switch>
  );
};

export default App;
