import React from "react";
import { Row, Col, Image } from "antd";
import {
  FundOutlined,
  CompassOutlined,
  ShoppingOutlined,
} from "@ant-design/icons";


const Storeinformation = ({ storeData }) => {
  return (
    <>
      <div
        className="bg-white store-information-box"
        style={{ borderRadius: 4 }}
      >
        <div className="card-header">
          <div className="stock-box">
            <div>
              <h2>{storeData ? storeData.storeName : ''}</h2>
              <p className="mb-0">
                {storeData ? storeData.address.addressLine1 : ''}, <br /> {storeData ? `${storeData.address.cityName}, ${storeData.address.stateName} ${storeData.address.zip}` : ''}
              </p>
            </div>
            <div className="store-icon">
              <svg xmlns="http://www.w3.org/2000/svg" width="47.781" height="39.719" viewBox="0 0 47.781 39.719">
                <path id="Icon_awesome-store" data-name="Icon awesome-store" d="M46.7,9.2,41.666,1.164A2.483,2.483,0,0,0,39.563,0H8.223a2.483,2.483,0,0,0-2.1,1.164L1.086,9.2c-2.6,4.15-.295,9.922,4.561,10.581a8.062,8.062,0,0,0,1.063.07,7.66,7.66,0,0,0,5.725-2.568,7.666,7.666,0,0,0,11.45,0,7.666,7.666,0,0,0,11.45,0,7.685,7.685,0,0,0,5.725,2.568,7.975,7.975,0,0,0,1.063-.07C47,19.13,49.307,13.359,46.7,9.2ZM41.076,22.342a9.843,9.843,0,0,1-2.288-.295v7.742H9V22.047a10.277,10.277,0,0,1-2.288.295,10.468,10.468,0,0,1-1.4-.093,9.756,9.756,0,0,1-1.272-.279V37.237a2.48,2.48,0,0,0,2.482,2.482H41.278a2.48,2.48,0,0,0,2.482-2.482V21.97a7.921,7.921,0,0,1-1.272.279A10.789,10.789,0,0,1,41.076,22.342Z" transform="translate(-0.004)" fill="#2e333e" opacity="0.89" />
              </svg>
            </div>
          </div>
          <div className="d-flex  justify-content-between">
            <p>
              {" "}
              <strong>
                <CompassOutlined /> Latitude:
              </strong>{" "}
              {storeData ? storeData.address.latitude : ''}
            </p>
            <p>
              {" "}
              <strong>
                {" "}
                <CompassOutlined /> Longitude:
              </strong>{" "}
              {storeData ? storeData.address.longitude : ''}
            </p>
          </div>
        </div>
        <div className="card-body">
          <Row gutter={16}>
            <Col lg={12} xs={24}>
              <div className="count-box">
                <FundOutlined />
                <p>Total Stock</p>
                <h3>{storeData ? storeData.totalStock : ''}</h3>
              </div>
            </Col>
            <Col lg={12} xs={24}>
              <div className="count-box">
                <ShoppingOutlined />
                <p>Total Products</p>
                <h3>{storeData ? storeData.totalProducts : ''}</h3>
              </div>
            </Col>
          </Row>
          <Row gutter={14}>
            <Col lg={24} xs={24}>
              <Row gutter={12}>
                <Image.PreviewGroup>
                  {storeData && storeData.imageGallery.map((item, key) => (
                    <Col lg={8} xs={24} key={key}>
                      <Image src={item.image} />
                    </Col>
                  ))}
                </Image.PreviewGroup>
              </Row>
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
};
export default Storeinformation;
