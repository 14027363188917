import React, { useState } from "react";
import { Row, Col, Button, Card, Input } from "antd";
import {
  RightOutlined,
  CloudUploadOutlined,
  EditOutlined
} from "@ant-design/icons";
import ImageCropper from "../../common/components/imageCropper/ImageCropper";
import Draggable from "react-draggable";

const CustomerTemplate = (props) => {
  const [cropImage, setCropImage] = useState(props?.formData?.customerTemplate?.preview || '');
  const [position, setPosition] = useState(props?.formData?.customerTemplate?.position || {x:0,y:0});
  const [textBorderColor, setTextBorderColor] = useState(props?.formData?.customerTemplate?.textBorderColor || "#FB984A");
  const [textBackgroundColor, setTextBackgroundColor] = useState(props?.formData?.customerTemplate?.textBackgroundColor || "#FB984A");
  const [textColor, setTextColor] = useState(props?.formData?.customerTemplate?.textColor || "#000000");
  const [isVisibleTemplate, setIsVisibleTemplate] = useState(props?.formData?.customerTemplate?.preview ? false : true);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isSeted, setIsSeted] = useState(true);

  const [image, setImage] = useState({
    preview: props?.formData?.customerTemplate?.preview || '',
    raw: props?.formData?.customerTemplate?.preview || ''
  });
  const handleChange = (e) => {
    if (e.target.files.length) {
      let reader = new FileReader();
      let file = e.target.files[0];
      reader.readAsDataURL(file);
      setIsModalVisible(true);
      setCropImage(URL.createObjectURL(e.target.files[0]));
      if (isSeted) {
        setTimeout(() => {
          setCropImage(URL.createObjectURL(e.target.files[0]));
          setIsSeted(false);
        }, 500);
      }
    }
  };
  const changeValue = () => {
    props.setFormData({
      customerTemplate: {
        urlTemplate: image.preview,
        base64Template: image.raw,
        textBackgroundColor: textBackgroundColor,
        textColor: textColor,
        textBorderColor: textBorderColor,
        position: position
      }
    });
  };
  const trackPos = (data) => {
    let updatedValue = { x: data.x, y: data.y };
    setPosition((position) => ({ ...position, ...updatedValue }));
  };

  return (
    <>
      <Card className="campaignInformationCard showWhenChecked" hoverable>
        <Row gutter="24">
          {isVisibleTemplate ? (
            <>
              <Col xl={6} xs={24} className="templateUploadSec">
                <Row
                  className="customTemplateBox"
                  style={{
                    position: "relative",
                    padding: 18
                  }}
                >
                  <div className="innerBox">
                    <label htmlFor="template-button" style={{ width: "100px" }}>
                      {image.preview ? (
                        <img
                          src={image.preview}
                          alt="dummy"
                          style={{ width: "100%" }}
                        />
                      ) : (
                        <>
                          <div className="ant-upload-select-picture-card">
                            <CloudUploadOutlined />
                            <h5 className="text-center">
                              Upload custom template
                            </h5>
                          </div>
                        </>
                      )}
                    </label>
                    <input
                      type="file"
                      id="template-button"
                      style={{ display: "none" }}
                      onChange={handleChange}
                    />
                    <br />
                  </div>
                </Row>
                {image.preview ? (
                  <Row className="mt-3 buttonSelectionSec">
                    <p className="mb-0">Set button background color</p>
                    <div className="colorPicker input-group mb-3">
                      <Input
                        type="text"
                        className="p-form-control"
                        placeholder="#000000"
                        value={textBackgroundColor || ""}
                        disabled={true}
                      />
                      <div className="input-group-append">
                        <span className="input-group-text" id="basic-addon1">
                          <input
                            type="color"
                            className="c-form-control"
                            value={textBackgroundColor || ""}
                            onChange={(e) =>
                              setTextBackgroundColor(e.target.value)
                            }
                          />
                        </span>
                      </div>
                    </div>
                    <p className="mb-0">Set button text color </p>
                    <div className="colorPicker input-group mb-3">
                      <Input
                        type="text"
                        className="p-form-control"
                        placeholder="#000000"
                        value={textColor || ""}
                        disabled={true}
                      />
                      <div className="input-group-append">
                        <span className="input-group-text" id="basic-addon2">
                          <input
                            type="color"
                            className="c-form-control"
                            value={textColor || ""}
                            onChange={(e) => setTextColor(e.target.value)}
                          />
                        </span>
                      </div>
                    </div>
                    <p className="mb-0">Set button border color</p>
                    <div className="colorPicker input-group mb-3">
                      <Input
                        type="text"
                        value={textBorderColor || ""}
                        className="p-form-control"
                        placeholder="#000000"
                        disabled={true}
                      />
                      <div className="input-group-append">
                        <span className="input-group-text" id="basic-addon2">
                          <input
                            type="color"
                            className="c-form-control"
                            value={textBorderColor || ""}
                            onChange={(e) => setTextBorderColor(e.target.value)}
                          />
                        </span>
                      </div>
                    </div>
                  </Row>
                ) : (
                  ""
                )}
              </Col>
            </>
          ) : (
            ""
          )}

          {image.preview ? (
            <>
              {isVisibleTemplate ? (
                <Col xl={18} xs={24}>
                  <div className="cTemplateSec">
                    <div className="cTemplatePreview">
                      <div className="cTemplateButtonSec">
                        <div
                          className="box"
                          style={{
                            height: "450px",
                            width: "301px",
                            position: "relative",
                            overflow: "hidden",
                            padding: "0"
                          }}
                        >
                          <div style={{ height: "450px", width: "301px" }}>
                            <img
                              src={image.preview}
                              alt="dummy"
                              style={{ width: "100%" }}
                            />

                            <Draggable  bounds={{ top: -402, left: 0, right: 160, bottom:-50 }} onDrag={(e, data) => trackPos(data)} defaultPosition={position}>
                              <div className="box">
                                {/* <div>
                                      x: {position.x.toFixed(0)}, y: {position.y.toFixed(0)}
                                </div>  */}
                                <button
                                  style={{
                                    background: `${textBackgroundColor}`,
                                    color: `${textColor}`,
                                    border: `1px solid ${textBorderColor}`
                                  }}
                                >
                                  Save to Phone
                                </button>
                              </div>
                            </Draggable>
                          </div>
                        </div>
                        <div className="btnFooter">
                          <Button
                            type="primary"
                            size="large"
                            className="mr-4"
                            onClick={() => setIsVisibleTemplate(false)}
                          >
                            Preview Template
                          </Button>
                        </div>
                      </div>
                    </div>
                  </div>
                </Col>
              ) : (
                <Col xl={24} xs={24}>
                  <div className="cTemplateSec">
                    <div className="cTemplatePreview">
                      <div className="cTemplateButtonSec">
                        <div
                          className="box"
                          style={{
                            height: "450px",
                            width: "301px",
                            position: "relative",
                            overflow: "hidden",
                            padding: "0"
                          }}
                        >
                          <div
                            style={{
                              height: "450px",
                              width: "301px",
                              position: "relative"
                            }}
                          >
                            <img
                              src={image.preview}
                              alt="dummy"
                              style={{ width: "100%" }}
                            />
                            <button
                              style={{
                                background: `${textBackgroundColor}`,
                                color: `${textColor}`,
                                border: `1px solid ${textBorderColor}`,
                                cursor: 'default',
                                x: `${position?.x}`,
                                y: `${position?.y}`,
                                transform: `translate(${position.x}px,${position.y}px)`
                              }}
                            >
                              Save to Phone
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      className="btnFooter text-center mb-5"
                      style={{ position: "absolute", top: "0px", right: "0px" }}
                    >
                      <Button
                        type="primary"
                        className="mr-4"
                        onClick={() => setIsVisibleTemplate(true)}
                      >
                        <EditOutlined /> Edit
                      </Button>
                    </div>
                  </div>
                </Col>
              )}
              {/* Preview Template Section */}
            </>
          ) : (
            ""
          )}

          {cropImage ? (
            <ImageCropper
              isModalVisible={isModalVisible}
              setIsModalVisible={setIsModalVisible}
              cropImage={cropImage}
              setImage={setImage}
            />
          ) : (
            ""
          )}
          <Col xl={24} xs={24}>
            <label
              onClick={changeValue}
              htmlFor="step-4"
              className="enable-when-valid btnNext ant-btn ant-btn-primary"            
            >
              Next <RightOutlined />
            </label>
          </Col>
        </Row>
      </Card>
    </>
  );
};
export { CustomerTemplate };
