import { useHistory, useLocation } from "react-router-dom";
import React, { useState, useEffect } from "react";
import { Layout, Form, message } from "antd";
import { ApiRoutes } from "../../config/ApiRoutes";
import { ApiHelper, getCampaignUserData } from "../../Helpers";
import { AddStore } from "../../modules/public/";
import "../../modules/public/public.less";
import Geocode from "react-geocode";
Geocode.setApiKey(process.env.REACT_APP_MAP_KEY);

const { Content } = Layout;

const AddMerchant = () => {
  const [form] = Form.useForm();
  const history = useHistory();
  const search = useLocation().search;
  const brandData = getCampaignUserData();
  const campaignId = new URLSearchParams(search).get("campaignId");
  const [campaignData, setCampaignData] = useState(null);
  const [stateData, setStateData] = React.useState([]);
  const [cityData, setCityData] = React.useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isSetLocation, setIsSetLocation] = useState(false);
  const [businessCategoryData, setBusinessCategoryData] = useState(false);
  const [businessSubcategoryData, setBusinessSubcategoryData] = useState(false);
  const [latitude, setLatitude] = useState("");
  const [longitude, setLongitude] = useState("");
  const [initialLocation, setInitialLocation] = useState({
    lat: "",
    lng: ""
  });

  useEffect(() => {
    getBrandDetails();
    getStateList(brandData.country);
    // eslint-disable-next-line
  }, []);

  const getBrandDetails = async (subdomain) => {
    const response = await new ApiHelper().FetchFromServer(
      ApiRoutes.STORE_CAMPAIGN_DETAIL.service,
      ApiRoutes.STORE_CAMPAIGN_DETAIL.url,
      ApiRoutes.STORE_CAMPAIGN_DETAIL.method,
      ApiRoutes.STORE_CAMPAIGN_DETAIL.authenticate,
      {
        id: campaignId
      }
    );
    const responseBusiness = await new ApiHelper().FetchFromServer(
      ApiRoutes.BUSINESS_CATEGORY_LIST.service,
      ApiRoutes.BUSINESS_CATEGORY_LIST.url,
      ApiRoutes.BUSINESS_CATEGORY_LIST.method,
      ApiRoutes.BUSINESS_CATEGORY_LIST.authenticate,
      undefined
    );

    if (response.code === 200) {
      if (response.data === undefined) {
        setCampaignData(null);
      } else {
        setCampaignData(response.data);
      }
    } else {
      message.error({
        content: response.messages || response.error,
        duration: 2
      });
    }
    if (responseBusiness.code === 200) {
      if (responseBusiness.data === undefined) {
        setBusinessCategoryData(null);
      } else {
        setBusinessCategoryData(responseBusiness.data.data);
      }
    }
  };

  const getSubcategory = async (parentOfferCategoryId) => {
    if (!parentOfferCategoryId) {
      setBusinessSubcategoryData([]);
      form.setFieldsValue({ businessSubcategory: '' })
      return false;
    }
    form.setFieldsValue({ businessSubcategory: '' })
    const responseBusiness = await new ApiHelper().FetchFromServer(
      ApiRoutes.BUSINESS_SUBCATEGORY_LIST.service,
      ApiRoutes.BUSINESS_SUBCATEGORY_LIST.url,
      ApiRoutes.BUSINESS_SUBCATEGORY_LIST.method,
      ApiRoutes.BUSINESS_SUBCATEGORY_LIST.authenticate,
      { parentOfferCategoryId: parentOfferCategoryId },
      undefined
    );

    if (responseBusiness.code === 200) {
      if (responseBusiness.data === undefined) {
        setBusinessSubcategoryData(null);
      } else {
        setBusinessSubcategoryData(responseBusiness.data.data);
      }
    }
  };

  const getStateList = async (country) => {
    if (!country) {
      setCityData([]);
      return false;
    }
    const response = await new ApiHelper().FetchFromServer(
      ApiRoutes.STATE_LIST.service,
      ApiRoutes.STATE_LIST.url,
      ApiRoutes.STATE_LIST.method,
      ApiRoutes.STATE_LIST.authenticate,
      { country: country }
    );
    if (response.code === 200) {
      setStateData(response.data.data);
    }
  };

  const getCityList = async (state) => {
    if (!state) {
      setCityData([]);
      return false;
    }
    const response = await new ApiHelper().FetchFromServer(
      ApiRoutes.CITY_LIST.service,
      ApiRoutes.CITY_LIST.url,
      ApiRoutes.CITY_LIST.method,
      ApiRoutes.CITY_LIST.authenticate,
      { state: state }
    );
    if (response.code === 200) {
      setCityData(response.data.data);
    }
  };


  const onFinish = async () => {
    const values = await form.validateFields();
    if (values.merchantStoreType === "singleStore") {
      setIsLoading(true);
      let cityName, stateName;
      for (let x in stateData) {
        if (stateData[x].stateId === values.stateId) {
          stateName = stateData[x].stateName;
          break;
        }
      }
      for (let x in cityData) {
        if (cityData[x].cityId === values.cityId) {
          cityName = cityData[x].cityName;
          break;
        }
      }
      const address = `${values.addressLine1} ${cityName} ${stateName} ${values.zipCode}`;
      const latLng = await Geocode.fromAddress(address);
      if (latLng.status === "OK") {
        const { lat, lng } = latLng.results[0].geometry.location;
        setLatitude(lat);
        setLongitude(lng);
        setIsSetLocation(true);
        setInitialLocation({
          lat: "",
          lng: ""
        });
        setInitialLocation({
          lat,
          lng
        });
      }
      if (latitude === "" || longitude === "longitude") {
        message.warning({
          content: "Verify or change location then click on next button.",
          duration: 2
        });
        setIsLoading(false);
        return false;
      }
    } else {
      setIsSetLocation(false);
      setInitialLocation({
        lat: "",
        lng: ""
      });
    }
    setIsLoading(true);
    const response = await new ApiHelper().FetchFromServer(
      ApiRoutes.REGISTER_MERCHANT.service,
      ApiRoutes.REGISTER_MERCHANT.url,
      ApiRoutes.REGISTER_MERCHANT.method,
      ApiRoutes.REGISTER_MERCHANT.authenticate,
      undefined,
      { ...values, countryId: brandData.country, latitude, longitude }
    );
    setIsLoading(false);
    if (response.code === 200) {
      history.push(
        `/public/otp?campaignId=${campaignId}&organizationId=${response.data.data.organizationId}&merchantStoreType=${values.merchantStoreType}&email=${values.email}`
      );
    } else {
      let errorData = [];
      if (response.code === 422) {
        for (let x in response.data) {
          errorData.push({
            name: Object.keys(response.data[x])[0],
            errors: [Object.values(response.data[x])[0]]
          })
        }
      }
      form.setFields(errorData);
      message.error({
        content: response.messages[0] || response.error,
        duration: 2
      });
    }
  };

  const handleCityChange = async (value) => {
    // const values = await form.validateFields();
    const values = await form.getFieldsValue(true);
    if (values.stateId && values.cityId && values.addressLine1 && values.zipCode) {
      if (values.merchantStoreType === "singleStore") {
        setIsLoading(true);
        let cityName, stateName;
        for (let x in stateData) {
          if (stateData[x].stateId === values.stateId) {
            stateName = stateData[x].stateName;
            break;
          }
        }
        for (let x in cityData) {
          if (cityData[x].cityId === values.cityId) {
            cityName = cityData[x].cityName;
            break;
          }
        }
        const address = `${values.addressLine1} ${cityName} ${stateName} ${values.zipCode}`;
        const latLng = await Geocode.fromAddress(address);
        if (latLng.status === "OK") {
          const { lat, lng } = latLng.results[0].geometry.location;
          setInitialLocation({
            lat: "",
            lng: ""
          });
          setLatitude(lat);
          setLongitude(lng);
          setInitialLocation({
            lat,
            lng
          });
          setIsLoading(false);
          return false;
        }
      }
    }
  };

  return (
    <>
      <Layout>
        <Content className="publicContentContainer"
          style={{ minHeight: 200, padding: "80px", background: "#fff" }}
        >
          <AddStore
            form={form}
            getStateList={getStateList}
            getCityList={getCityList}
            stateData={stateData}
            cityData={cityData}
            isLoading={isLoading}
            onFinish={onFinish}
            campaignData={campaignData}
            handleCityChange={handleCityChange}
            isSetLocation={isSetLocation}
            setLatitude={setLatitude}
            setLongitude={setLongitude}
            initialLocation={initialLocation}
            brandData={brandData}
            businessCategoryData={businessCategoryData}
            businessSubcategoryData={businessSubcategoryData}
            getSubcategory={getSubcategory}
            campaignSelectState={campaignData ? campaignData.stateCity : []}
          />
        </Content>
      </Layout>
    </>
  );
};
export default AddMerchant;
