import { Upload } from "antd";
import React, { useState, useEffect } from "react";
import ImgCrop from "antd-img-crop";
import { CloudUploadOutlined } from "@ant-design/icons";

const ImageUpload = (props) => {
  const [fileList, setFileList] = useState(props.productImage || []);
  const onChange = ({ file, fileList }) => {
    setFileList(fileList);
    file.status === "removed"
      ? props.setProductImage([])
      : props.setProductImage(fileList);
  };

  useEffect(() => {
    setFileList(props.productImage || []);
  }, [props.productImage]);
  const onPreview = async (file) => {
    let src = file.url;
    if (!src) {
      src = await new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(file.originFileObj);
        reader.onload = () => resolve(reader.result);
      });
    }
    const image = new Image();
    image.src = src;
    const imgWindow = window.open(src);
    imgWindow.document.write(image.outerHTML);
  };

  const dragDropText = () => {
    if (fileList.length < 1) {
      return (
        <div className="dragDropText">
          <CloudUploadOutlined />
          <p>Drag and Drop Here</p>
        </div>
      );
    }
  };

  return (
    <ImgCrop rotate>
      <Upload
        // action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
        listType="picture-card"
        fileList={fileList}
        onChange={onChange}
        response={false}
        onPreview={onPreview}
      >
        {dragDropText()}
      </Upload>
    </ImgCrop>
  );
};
export { ImageUpload };
