import React from 'react';
import Fade from 'react-reveal/Fade';
import { PhoneOutlined , MailOutlined , FacebookOutlined , TwitterOutlined , YoutubeOutlined , InstagramOutlined } from "@ant-design/icons";
import './footer.less'
const FrontendFooter = () => {
    return (
        <section id="footer" className='footer-section py-5'>
            <div className='container'>
                <Fade bottom><h5 className='text-center mb-5'>SpacePointe</h5></Fade>
                <Fade bottom><p  className='text-center'>SpacePointe is a payments technology company with a mission to transform the point-of-sale for formal and informal sectors. Focused on inclusive technology, SpacePointe’s platform enables thousands of businesses (globally) to do more by leveraging the power of the point-of-sale beyond just payment acceptance, with leading-edge tools that enables them to manage their business as well as access a plethora of revenue generation opportunities at the till.</p></Fade>
            </div>
            <div className='container'>
                <div className='col-lg-4 col-12 offset-lg-4 pt-3 pb-5 d-flex justify-content-between'>
                    <Fade bottom><div className='text-center borderR'>   
                        <PhoneOutlined /><br/>
                        <small>+1-888-205-5939</small><br/>
                        <small className='text-uppercase'>Phone</small>
                    </div></Fade>
                    <Fade bottom><div className='text-center '>   
                        <MailOutlined/><br/>
                        <small><a href="mailto:support@kardstash.com">support@kardstash.com</a></small><br/>
                        <small className='text-uppercase'>Email</small>
                    </div></Fade>
                    {/* <Fade bottom><div className='text-center'>   
                        <MailOutlined/><br/>
                        <small>support@kardStash.com</small><br/>
                        <small className='text-uppercase'>Email</small>
                    </div></Fade> */}
                </div>
                <div className='col-lg-6 offset-lg-3  d-flex pb-3 justify-content-center social'>
                    <Fade bottom><a href="https://www.facebook.com/people/KardStash/100064042934199/" target="_blank" rel="noreferrer"><FacebookOutlined /></a>
                     <a href="https://twitter.com/kardstash" target="_blank" rel="noreferrer"><TwitterOutlined  /></a>
                     <a href="https://www.youtube.com/watch?v=5NRG8cWMqpI" target="_blank" rel="noreferrer"><YoutubeOutlined /></a>
                     <a href="https://www.instagram.com/kardstash/?hl=en" target="_blank" rel="noreferrer"><InstagramOutlined  /></a></Fade>
                </div>
                <div className='col-lg-6 offset-lg-3 pb-5 copyright d-flex justify-content-center '>
                  {/* <Fade bottom><Link to="/">Privacy Policy</Link> &nbsp;|&nbsp; <Link to="/">Terms &amp; Conditions</Link>&nbsp;  | &copy; All rights reserved.</Fade> */}
                  <Fade bottom>&copy; All rights reserved.</Fade>
                </div>
            </div>
        </section>
    );
}

export default FrontendFooter;