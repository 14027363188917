import { Row, Col, Tooltip, Button, Card } from "antd";
import React, { useState, useEffect, useRef } from "react";
import {
  CalendarOutlined,
  DownloadOutlined,
  CloudDownloadOutlined
} from "@ant-design/icons";
import { triggerBase64Download } from "react-base64-downloader";
import QRImage from "react-qr-image";
import { ClipboardCopy } from "../../common/components/";
import moment from "moment";
import { AppConfig } from "../../config/AppConfig";
import {
  getUserData,
  getCurrency,
  timeZone,
  abbreviateNumber
} from "../../Helpers";
import { Link } from "react-router-dom";
import TemplateNotFound from "../../assets/images/templateNotFound.png";

const CampaignDetails = (props) => {
  const Currency = getCurrency();
  const timezone = getUserData().timezone;
  const imageRef = useRef(null);
  const [campaign, setCampaign] = useState(props.data?.campaign);
  const [products, setProducts] = useState(props.data?.product);
  const [stateCity, setStateCity] = useState(props.data?.stateCity);
  const [showMore, setShowMore] = useState(false);
  const [showTermMore, setShowTermMore] = useState(false);
  const [showDisclaimerMore, setDisclaimerShowMore] = useState(false);
  useEffect(() => {
    setCampaign(props.data?.campaign);
    setProducts(props.data?.products);
    setStateCity(props.data?.stateCity);
  }, [props.data]);
  const state = [];
  let status =
    moment().format("YYYY-MM-DD") > campaign.CEndDate
      ? "Expired"
      : parseInt(campaign?.Cstatus) === 2
        ? "Published"
        : parseInt(campaign?.Cstatus) === 3
          ? "Active"
          : parseInt(campaign?.Cstatus) === 4
            ? "Expired"
            : "Draft";
  return (
    <>
      <Card className="campaignDetail showWhenChecked">
        <Row gutter="12">
          <Col xl={15} xs={24}>
            <div className="left">
              <div className="campaignBox">
                <div className="nameBox d-flex mb-5">
                  <h2 className="mb-0 pr-2">{campaign?.CampaignName}</h2>
                  <div className="ml-auto d-flex align-item-center">
                    <span className={`statusBox ${status}`}>{status}</span>
                    {/* <Button type="danger">{campaign?.CampaignName}</Button> */}

                    <Link to={`/campaign/edit/${campaign?.CampaignId}`}>
                      <Button type="primary" className="btnBlue ml-3">
                        Edit
                      </Button>{" "}
                    </Link>
                  </div>
                </div>
                <div className="createdDateBox d-flex mb-5">
                  <div className="codeBox d-flex">
                    <span>Campaign Code: {campaign?.CampaignCode}</span>
                  </div>
                  <div className="dateBox d-flex justify-content-between">
                    <h4>
                      {moment(timeZone(campaign?.createDt, timezone)).format(
                        "DD MMM yyyy"
                      )}
                    </h4>
                    <span>
                      {moment(timeZone(campaign?.createDt, timezone)).format(
                        "HH:mm:ss"
                      )}
                    </span>
                  </div>
                </div>
                <div className="copyBox">
                  <span>Merchant Url</span>
                  <ClipboardCopy
                    copyText={`${AppConfig.CAMPAIGN_URL.replace(
                      "campaign_slug",
                      getUserData().webUrl
                    )}/${campaign?.CampaignCode}`}
                  />
                </div>
                {campaign?.customerShortUrl ? (
                  <>
                    <br />
                    <div className="copyBox">
                      <span>Customer Url</span>
                      <ClipboardCopy copyText={campaign?.customerShortUrl} />
                    </div>
                  </>
                ) : (
                  ""
                )}
              </div>
              <Row gutter="24" className="OfferValueBox">
                <Col xl={8} xs={24}>
                  <div className="innerBox denominationBox">
                    <h5>Denomination</h5>
                    <p>
                      {Currency} {campaign?.Denomination}
                    </p>
                  </div>
                </Col>
                <Col xl={8} xs={24}>
                  <div className="innerBox voucherBox">
                    <h5>No of Vouchers</h5>
                    <p>{campaign?.CardCount}</p>
                  </div>
                </Col>
                <Col xl={8} xs={24}>
                  <div className="innerBox valueBox">
                    <h5>Total Offer Value</h5>
                    <p>
                      {Currency} {abbreviateNumber(campaign?.TotalOffer)}
                    </p>
                  </div>
                </Col>
              </Row>
              <div className="productsBox mb-5">
                <Row gutter="24" className="d-flex">
                  <Col xl={24} xs={24}>
                    <h3 className="heading">Products</h3>
                  </Col>
                  {products
                    ? products.map((productsPreData,index) => (
                      <Col xl={8} xs={24} key={index} className='d-flex' style={{flexGrow: 1}}>
                        <div className="innerBox w-100">
                          <img
                            src={`${productsPreData.imageName}`}
                            alt="icon"
                          />
                          <div className="details">
                            <h4
                              title={productsPreData.productCodeOveride}
                            >
                              {productsPreData.productCodeOveride}
                            </h4>
                            <p>
                              UPC | <span>{productsPreData.upc}</span>
                            </p>
                          </div>
                        </div>
                      </Col>
                    ))
                    : ""}
                </Row>
              </div>
              <div className="productsBox terms">
                <Row gutter="24">
                  <Col xl={24} xs={24}>
                    {campaign?.privacyPolicy ? (
                      <>
                        <h3>Privacy Policy</h3> 
                        
                        <p>{campaign?.privacyPolicy ? <> {showMore || campaign.privacyPolicy.length < 250 ? <p style={{ whiteSpace: "pre-line" }}>{`${campaign?.privacyPolicy}` || ""}</p> : `${campaign?.privacyPolicy.substring(0, 250)}`} &nbsp;{campaign.privacyPolicy.length > 250 ? <><span className="readMoreText" onClick={() => setShowMore(!showMore)}>{showMore ? "Show less" : "Show more"}</span></> : ""}</> : ''}</p>
                      </>
                    ) : (
                      ""
                    )}
                    {campaign?.terms ? (
                      <>
                        <h3>Website to Brand</h3>
                        {/* <p>{campaign?.terms || ""}</p> */}
                        <p>{campaign?.terms ? <> {showTermMore || campaign.terms.length < 250 ? <p style={{ whiteSpace: "pre-line" }}>{`${campaign?.terms}` || ""}</p>  : `${campaign?.terms.substring(0, 250)}`} &nbsp;{campaign.terms.length > 250 ? <><span className="readMoreText" onClick={() => setShowTermMore(!showTermMore)}>{showTermMore ? "Show less" : "Show more"}</span></> : ""}</> : ''}</p>
                      </>
                    ) : (
                      ""
                    )}
                    {campaign?.disclaimer ? (
                      <>
                        <h3>Disclaimer</h3>
                        {/* <p>{campaign?.disclaimer || ""}</p> */}
                        <p>{campaign?.disclaimer ? <> {showDisclaimerMore || campaign.disclaimer.length < 250 ? <p style={{ whiteSpace: "pre-line" }}>{`${campaign?.disclaimer}` || ""}</p>  : `${campaign?.disclaimer.substring(0, 250)}`} &nbsp;{campaign.disclaimer.length > 250 ? <><span className="readMoreText" onClick={() => setDisclaimerShowMore(!showDisclaimerMore)}>{showDisclaimerMore ? "Show less" : "Show more"}</span></> : ""}</> : ''}</p>
                      </>
                    ) : (
                      ""
                    )}
                  </Col>
                </Row>
              </div>
            </div>
          </Col>
          <Col xl={9} xs={24}>
            <div className="right">
              {campaign && campaign.Cstatus !== "1" ? (
                <>
                  <div>
                    <Link to={`/campaign/dashboard/${campaign?.CampaignId}`}>
                      <Button type="primary" size="large" block>
                        Merchant Dashboard
                      </Button>{" "}
                    </Link>
                  </div>
                  <div  className="mt-2">
                    <Link to={`/campaign/customer-dashboard/${campaign?.CampaignId}`}>
                      <Button type="primary" size="large" block>
                        Customer Dashboard
                      </Button>{" "}
                    </Link>
                  </div>
                </>
              ) : (
                ""
              )}

              <Row>
                <Col xl={24} xs={24} className="dateBox">
                  <span className="startIcon">
                    <CalendarOutlined />
                  </span>
                  <div>
                    <span>Start Date</span>
                    <h4>
                      {moment(campaign?.CStartDate).format("DD MMM yyyy")}
                    </h4>
                  </div>
                </Col>
              </Row> 
              <Row>
                <Col xl={24} xs={24} className="dateBox mb-5">
                  <span className="endIcon">
                    <CalendarOutlined />
                  </span>
                  <div>
                    <span>End Date</span>
                    <h4>{moment(campaign?.CEndDate).format("DD MMM yyyy")}</h4>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col xl={24} xs={24} className="stateBox">
                  <label className="heading">States</label>
                  <div className="inner">
                    {props.data.allStateSelected !== ""
                      ? props.data.allStateSelected
                      : stateCity
                        ? stateCity.map((stateDataPre) => {
                          if (!state.includes(stateDataPre.stateName)) {
                            state.push(stateDataPre.stateName);
                            return <span>{stateDataPre.stateName}</span>;
                          } else {
                            return "";
                          }
                        })
                        : ""}
                  </div>
                  {/* <label className="heading">City</label>
                  <div className="inner">
                    {stateCity
                      ? stateCity.map((stateDataPre) => {
                          return <span>{stateDataPre.cityName}</span>;
                        })
                      : ""}
                  </div> */}
                </Col>
              </Row>
              <Row gutter="24" className="templateBox mb-5">
                <Col xl={12} xs={24}>
                  <div className="qrCodeBox" ref={imageRef}>
                    <QRImage
                      text={`${AppConfig.CAMPAIGN_URL.replace(
                        "campaign_slug",
                        getUserData().webUrl
                      )}/${campaign?.CampaignCode}`}
                    />
                    <span
                      className="downloadIcon"
                      onClick={() =>
                        triggerBase64Download(
                          imageRef.current.getElementsByTagName("img")[0].src,
                          "QRCode"
                        )
                      }
                    >
                      <Tooltip title="Download">
                        <DownloadOutlined />
                      </Tooltip>
                    </span>
                  </div>
                </Col>
                {props.data?.template !== null &&
                  props.data?.template !== "" &&
                  props.data?.template !== undefined &&
                  props.data?.template?.type === "custom" ? (
                  <Col xl={12} xs={24}>
                    <div className="previewBox">
                      <label className="heading">Template</label>
                      <img
                        src={`${props.data?.template?.CustomCode}`}
                        alt="Icon"
                      />
                    </div>
                  </Col>
                ) : props.data?.template?.type === "Default" ? (
                  <Col xl={12} xs={24}>
                    <div className="previewBox">
                      <label className="heading">Template</label>
                      {/* <object
                        data={`${AppConfig.API_ENDPOINT}/brand/campaign/download/${campaign?.CampaignId}`}
                        type="application/pdf"
                        width="100%"
                        height="100%"
                      >
                       
                      </object> */}
                      <p style={{ textAlign: "center", margin: "24px 0px 23px 0px" }}>
                        {" "}
                        <a
                          href={`${AppConfig.API_ENDPOINT}/brand/campaign/download/${campaign?.CampaignId}`}
                        >
                          <CloudDownloadOutlined style={{ fontSize: "3rem" }} />{" "}
                          <br />
                          Download <br/>PDF!
                        </a>
                      </p>
                    </div>
                  </Col>
                ) : (
                  <Col xl={12} xs={24}>
                    <div className="previewBox">
                      <label className="heading">Template</label>
                      <img src={TemplateNotFound} alt="icon" className="p-5" />
                    </div>
                  </Col>
                )}
              </Row>
              {campaign?.customerTemplateUrl ? (
                <Row>
                  <Col xl={24} xs={24} className="stateBox">
                    <label className="heading">Customer Template { }</label>
                    <div className="cTemplateSec">
                      <div className="cTemplatePreview">
                        <div className="cTemplateButtonSec">
                          <div
                            className="box"
                            style={{
                              height: "450px",
                              width: "290px",
                              position: "relative",
                              overflow: "hidden",
                              padding: "0"
                            }}
                          >
                            <div
                              style={{
                                height: "450px",
                                width: "290px",
                                position: "relative"
                              }}
                            >
                              <img
                                src={campaign?.customerTemplateUrl}
                                alt="dummy"
                                style={{ width: "100%", border: "0px" }}
                              />
                              <button
                                style={{
                                  background: `${campaign?.textBackgroundColor}`,
                                  color: `${campaign?.textColor}`,
                                  border: `1px solid ${campaign?.textBorderColor}`,
                                  cursor: 'default',
                                  // x: `${position?.x}`,
                                  // y: `${position?.y}`,
                                  transform: `translate(${JSON.parse(campaign?.buttonPosition).x
                                    }px,${JSON.parse(campaign?.buttonPosition).y
                                    }px)`
                                }}
                              >
                                Save to Phone
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Col>
                </Row>
              ) : (
                ""
              )}
            </div>
          </Col>
        </Row>
      </Card>
    </>
  );
};
export { CampaignDetails };
