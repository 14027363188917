import { Col, Layout, Row, Space } from "antd";
import React from "react";
import { Breadcrumbsec, PageTitle } from "../../common/components/layout/";
import "./dashboard.less";
import Productlist from "./Productlist";
import Storeinformation from "./Storeinformation";

const { Content } = Layout;
const title = "Store Details";
const prevpagetitle = "Dashboard";
const currentpagetitle = "Details";
const prevlink = "/";

const Storedetails = () => {
  return (
    <>
      <Space className="filter-sec m-d-block">
        <PageTitle title={title} />
        <Breadcrumbsec
          prevpagetitle={prevpagetitle}
          currentpagetitle={currentpagetitle}
          prevlink={prevlink}
        />
      </Space>
      <Content
        className="content-body"
        style={{ minHeight: 200 }}
        id="Dashboard"
      >
        <Row gutter={0}>
          <Col lg={8} xs={24}>
            <Storeinformation />
          </Col>
          <Col lg={16} xs={24}>
            <Productlist />
          </Col>
        </Row>
      </Content>
    </>
  );
};
export default Storedetails;
