import { motion } from "framer-motion";
import { Row, Col, Card,  Drawer } from "antd";
import React, { useEffect, useState } from "react";
import { ProductEdit } from "../../modules/products/";
const container = {
  hidden: { opacity: 1, scale: 0, width: "100%", padding: 0 },
  visible: {
    opacity: 1,
    scale: 1,
    transition: {
      delayChildren: 0.3,
      staggerChildren: 0.2,
    },
  },
};
const item = {
  hidden: { y: 20, opacity: 0, listStyleType: "none" },
  visible: {
    y: 0,
    opacity: 1,
  },
};

const ProductList = (props) => {
  const [product, setProduct] = useState(props.product || []);
  const [editTab, setEditTab] = useState("");
  const showDrawer = (index) => {
    setEditTab(index);
  };
  const onClose = () => {
    setEditTab("");
  };
  useEffect(() => {
    setProduct(props.product);
  }, [props.product]);
  return (
    <>
      <motion.ul
        className="container"
        variants={container}
        initial="hidden"
        animate="visible"
      >
        <Row gutter={[16, 16]}>
          {product.map((productsPreData, index) => (
            <Col xl={6} lg={8} xs={24} className="productList" key={index}>
              <motion.li
                key={productsPreData.key}
                className="item"
                variants={item}
              >
                <Card
                  key={productsPreData.key}
                  className="productListCard"
                  hoverable
                >
                  <div className="cardHeader">
                    <img
                      src={`${productsPreData.imageName}`}
                      alt="ProductImage"
                    />
                    {/* {editTab !== "" ? (
                      // <Tooltip title="Disable Edit">
                      //   <span
                      //     className="editBox shadow"
                      //     style={{
                      //       cursor: "default",
                      //       background: "#9BA8AA",
                      //     }}
                      //   >
                      //     <EditOutlined />
                      //   </span>
                      // </Tooltip>
                    ) : (
                      // <Tooltip title="Edit">
                      //   <span
                      //     className="editBox shadow"
                      //     onClick={() => showDrawer(index)}
                      //   >
                      //     <EditOutlined />
                      //   </span>
                      // </Tooltip>
                    )} */}
                  </div>
                  <div className="cardFooter shadow-lg">
                    <h4>{productsPreData.productCodeOveride}</h4>
                    <p>
                      UPC <span className="separator">|</span>{" "}
                      <span>{productsPreData.upc}</span>
                    </p>
                  </div>
                  <Drawer
                    placement="right"
                    closable={true}
                    onClose={onClose}
                    visible={editTab === index ? true : false}
                    getContainer={false}
                    style={{ position: "absolute" }}
                    className="drawerForm"
                  >
                    <ProductEdit
                      productName={productsPreData.productCodeOveride}
                      productUPC={productsPreData.upc}
                      productImage={`${productsPreData.imageName}`}
                      organizationProductId={
                        productsPreData.organizationProductId
                      }
                      index={index}
                      showDrawer={showDrawer}
                      productInfoUpdate={props.productInfoUpdate}
                    />
                  </Drawer>
                </Card>
              </motion.li>
            </Col>
          ))}
        </Row>
      </motion.ul>
    </>
  );
};

export { ProductList };
