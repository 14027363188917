import { Layout, Row, Col, message } from "antd";
import React, { useState, useEffect } from "react";
import { ApiRoutes } from "../../config/ApiRoutes";
import { ApiHelper } from "../../Helpers";
import { CampaignDetails } from "../../modules/campaigns";
import "../../modules/campaigns/campaigns.less";
import { Loader } from "../../common/components/";

const { Content } = Layout;
// const title = "Campaigns (12)";
const Campaigns = (props) => {
  // const imageRef = useRef(null);
  const query = new URLSearchParams(props.match.params);
  const campaignId = query.get("id");
  // const [campaign, setCampaign] = useState();
  const [data, setData] = useState();
  const [loader, setLoader] = useState(true);
  useEffect(() => {
    getCampaignDetail();
    // eslint-disable-next-line
  }, []);
  const getCampaignDetail = async () => {
    //setLoader(true);
    const response = await new ApiHelper().FetchFromServer(
      ApiRoutes.CAMPAIGN_DETAIL.service,
      ApiRoutes.CAMPAIGN_DETAIL.url,
      ApiRoutes.CAMPAIGN_DETAIL.method,
      ApiRoutes.CAMPAIGN_DETAIL.authenticate,
      { id: campaignId }
    );
    if (response.code === 200) {
      setData(response.data);

     // setCampaign(response.data.campaign);
    } else {
      message.error({
        content: response.messages || response.error,
        duration: 2,
      });
    }
    setLoader(false);
  };
  //   const params = props.match.params;
  return (
    <>
      {loader ? (
        <section className="text-center" style={{height: '80vh', display: 'flex', justifyContent: 'center', alignItems: 'center'}}><Loader /></section>
      ) : (
        <>
          <section
            id="Campaigns"
            className="headerFix"
          >
            <div className="campaignHeader">
              {/* <span className="codeBox">
                Campaign Code #{campaign?.CampaignCode}
              </span>
              <div className="copyBox">
                <ClipboardCopy
                  copyText={`${AppConfig.CAMPAIGN_URL.replace('campaign_slug', getUserData().webUrl)}/${campaign?.CampaignCode}`}
                />
              </div> */}
              {/* <div
                className="downloadBox"
                onClick={() =>
                  triggerBase64Download(
                    imageRef.current.getElementsByTagName("img")[0].src,
                    "QRCode"
                  )
                }
              >
                <Tooltip title="Download">
                  {" "}
                  <DownloadOutlined />
                </Tooltip>
              </div> */}
              {/* <div className="qrBox" ref={imageRef} style={{ marginLeft: 'inherit'}}>
                <Tooltip title="Qr Code">
                  {" "}
                  <QRImage
                    text={`${AppConfig.API_ENDPOINT}/${campaign?.CampaignCode}`}
                  />
                </Tooltip>
              </div> */}
            </div>
          </section>
          <Content
            className="content-body"
            style={{ minHeight: 200}}
            id="Campaigns"
          >
            <Row>
              <Col lg={24} xs={24}>
                <div style={{ margin: "0px 40px 14px 40px" }}>
                  <CampaignDetails data={data} />
                </div>
              </Col>
            </Row>
          </Content>
        </>
      )}
    </>
  );
};
export default Campaigns;
