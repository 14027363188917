import { Row, Col, Select } from "antd";
import React from "react";
const { Option } = Select;
const provinceData = [
  "California",
  "Ohio",
  "Texas",
  "Florida",
  "Hawaii",
  "Alaska",
  "Virginia",
];
const cityData = {
  California: [
    "Los Angeles",
    "San Diego",
    "San Jose",
    "San Francisco",
    "Fresno",
    "Sacramento",
    "Oxnard",
    "Ontario",
  ],
  Ohio: [
    "Akron",
    "Alliance",
    "Amherst",
    "Ashland",
    "Ashtabula",
    "Athens",
    "Aurora",
  ],
  Texas: ["Abbott", "Abernathy", "Abilene"],
  Florida: [
    "Orlando",
    "Ormond Beach",
    "Oviedo",
    "Palatka",
    "Palm Bay",
    "Palm Beach",
  ],
  Hawaii: ["Honolulu", "East Honolulu", "Pearl City"],
  Alaska: ["Adak", "Akhiok", "Akiak"],
  Virginia: ["Virginia Beach", "Norfolk", "Chesapeake"],
};
const StateCity = () => {
  const [cities, setCities] = React.useState(cityData[provinceData[0]]);
  const [secondCity, setSecondCity] = React.useState(
    cityData[provinceData[0]][0]
  );

  const handleProvinceChange = (value) => {
    setCities(cityData[value]);
    setSecondCity(cityData[value][0]);
  };

  const onSecondCityChange = (value) => {
    setSecondCity(value);
  };
  return (
    <Row gutter={48}>
      <Col lg={12} xs={24}>
      <Select
        placeholder="Select State"
        onChange={handleProvinceChange}
        style={{width: '100%'}}
      >
        {provinceData.map((province) => (
          <Option key={province}>{province}</Option>
        ))}
      </Select>
      </Col>
      <Col lg={12} xs={24}>
      <Select
        placeholder="Select City"
        value={secondCity}
        onChange={onSecondCityChange}
        style={{width: '100%'}}
      >
        {cities.map((city) => (
          <Option key={city}>{city}</Option>
        ))}
      </Select>
      </Col>
    </Row>
  );
};
export { StateCity };
