import React from 'react';
import { Layout } from 'antd';
import moment from "moment";
const { Footer } = Layout;

const Footerlayout = () => {
 
  return (
    <>
      <Footer className="text-center">&copy; {moment().format("YYYY")} Kardstash. All Rights Reserved</Footer>
    </>
  );
}
export default Footerlayout;
