import { Row, Col, Form, Input, Button, Card, Tooltip } from "antd";
import React from "react";
import { PlusCircleOutlined, DeleteOutlined } from "@ant-design/icons";
import { ImageUpload } from "../../common/components/layout/";

const ProductsAdd = (props) => {
  const {
    selectedProductData,
    addFrom,
    imageError,
    productImage,
    onFinish,
    form,
    removeProduct,
    setProductImages,
    removeAddDiv,
    checkUPSNumber
  } = props;
  return (
    <>
      <Row className="productAdd" gutter="24">
        {selectedProductData.map((selectedProductPreData, key) => (
          <Col xl={6} lg={8} xs={24} key={key}>
            <Card className="selectedProduct productAddCard" hoverable>
              <Form.Item className="selectedImage">
                <img src={selectedProductPreData.productImage} alt="Product" />
              </Form.Item>
              <Form.Item label="Product Name">
                <Input
                  type="text"
                  value={selectedProductPreData.name}
                  disabled
                />
              </Form.Item>
              <Form.Item label="Product UPC">
                <Input
                  type="text"
                  value={selectedProductPreData.upc}
                  disabled
                />
              </Form.Item>

              <Tooltip title="Delete">
                <DeleteOutlined
                  className="shadow"
                  onClick={() => removeProduct(key)}
                />
              </Tooltip>
            </Card>
          </Col>
        ))}
        <Col
          xl={6}
          lg={8}
          xs={24}
          style={{ display: addFrom ? "block" : "none" }}
        >
          <Form
            layout="vertical"
            name="productEdit"
            form={form}
            autoComplete="off"
            onFinish={onFinish}
          >
            <Card className="productAddCard" hoverable>
              <ImageUpload
                setProductImage={setProductImages}
                productImage={productImage}
              />
              {imageError ? (
                <div className="ant-form-item-explain ant-form-item-explain-error">
                  <div role="alert">Please select your Product Image</div>
                </div>
              ) : (
                ""
              )}

              <Form.Item
                label="Product Name"
                name="productName"
                rules={[
                  {
                    required: true,
                    message: "Please input your Product Name"
                  }
                ]}
              >
                <Input type="text" />
              </Form.Item>
              <Form.Item
                label="Product UPC"
                name="productUPC"
                rules={[
                  {
                    required: true,
                    message: "Please input your Product UPC"
                  },
                  {
                    min: 7,
                    message: "Value should accept at least 7 digit number."
                  },
                  {
                    max: 14,
                    message: "Value should be less than 14 character."
                  },
                  () => ({
                    async validator(_, value) {
                      if (value) {
                        const re = /^[^*|\":<>[\]{}`\\()';@&$]+$/; //eslint-disable-line 
                        // console.log('re.test(value)', re.test(value))
                        if (!re.test(value)) {
                          return Promise.reject("UPC number does not enter special characters.")
                        }
                        return Promise.resolve()
                      }
                    }
                  }),
                  () => ({
                    async validator(_, value) {
                      if (value !== undefined) {
                        const status = await checkUPSNumber(value);
                        if (status) {
                          return Promise.resolve()
                        }
                        return Promise.reject("UPC already exists.")
                      }
                    },
                  }),
                ]}
              >
                <Input
                  type="text"
                  onKeyDown={(e) =>
                    e.keyCode === 13 ? e.preventDefault() : ""
                  }
                />
              </Form.Item>
              {/* <Tooltip title="Add">
                <Button type="primary" htmlType="submit">
                  Add
                </Button>
              </Tooltip> */}
              {selectedProductData.length >= 1 ?
                <Tooltip title="Delete">
                  <DeleteOutlined
                    onClick={() => removeAddDiv(false)}
                    className="deleteAddBox shadow"
                  />
                </Tooltip> : ''}
            </Card>
          </Form>
        </Col>
        <Col xl={6} lg={8} xs={24}>
          <Form.Item className="productAddCard">
            <Button
              className="btnAddProduct"
              onClick={() => onFinish()}
              block
              icon={<PlusCircleOutlined />}
            >
              Add Product
            </Button>
            {/* {addFrom ? (
              <Button
                className="btnAddProduct disabled"
                //   onClick={() => add()}
                block
                icon={<PlusCircleOutlined />}
              >
                Add Product
              </Button>
            ) : (
              <Button
                className="btnAddProduct"
                onClick={() => removeAddDiv(true)}
                block
                icon={<PlusCircleOutlined />}
              >
                Add Product
              </Button>
            )} */}
          </Form.Item>
        </Col>
      </Row>
    </>
  );
};
export { ProductsAdd };
