import { Layout as Layouts } from "antd";
import { LeftOutlined, RightOutlined } from "@ant-design/icons";
import MediaQuery from "react-responsive";
import React, { useEffect } from "react";

import { getToken } from "../../Helpers";
import Footerlayout from "./footer/index";
import Sidebarlayout from "./sidebar/Sidebar";
import Sidebarprofile from "./sidebarProfile/Sidebarprofile";

const Layout = (props) => {
  const { Sider } = Layouts;
  const [isCollapsed, setIsCollapsed] = React.useState(false);
  useEffect(() => {
    if (getToken() === "" || getToken() === null) {
      window.location.href = "/";
    }
  }, []);
  return (
    <>
      <div className="ekiakrtApp" id="kardstashLayout">
        <Layouts style={{ minHeight: "100vh" }}>
          <Sidebarprofile />
          <MediaQuery minDeviceWidth={992}>
            <Sider
              trigger={null}
              collapsible
              collapsed={isCollapsed}
              width={230}
              className="kardstashSidebar"
            >
              <Sidebarlayout />
            </Sider>
          </MediaQuery>
          <MediaQuery maxDeviceWidth={991}>
            <Sider
              trigger={null}
              collapsible
              collapsed={!isCollapsed}
              width={230}
              className="kardstashSidebar"
            >
              <Sidebarlayout />
            </Sider>
          </MediaQuery>
          <Layouts className="site-layout">
            {React.createElement(isCollapsed ? RightOutlined : LeftOutlined, {
              className: "trigger",
              onClick: () => setIsCollapsed(!isCollapsed),
            })}
            <>{props.children}</>
            <Footerlayout />
          </Layouts>
        </Layouts>
      </div>
    </>
  );
};

export default Layout;
