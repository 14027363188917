import React, { useLayoutEffect } from "react";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4maps from "@amcharts/amcharts4/maps";
import am4geodata_usaLow from "@amcharts/amcharts4-geodata/nigeriaLow";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
// import { query_results } from "../../../common/components/data/ngData";
am4core.useTheme(am4themes_animated);
am4core.addLicense("ch-custom-attribution");

const Ukmap = ({heatMap}) => {
  useLayoutEffect(() => {
    // Create map instance
    let chart = am4core.create("mapBox", am4maps.MapChart);
    chart.maxZoomLevel = 64;

    // Set map definition
    chart.geodata = am4geodata_usaLow;

    // Set projection
    //chart.projection = new am4maps.projections.AlbersUsa();

    // Add button
    let zoomOut = chart.tooltipContainer.createChild(am4core.ZoomOutButton);
    zoomOut.align = "right";
    zoomOut.valign = "top";
    zoomOut.margin(20, 20, 20, 20);
    zoomOut.events.on("hit", function () {
      if (currentSeries) {
        currentSeries.hide();
      }
      chart.goHome();
      zoomOut.hide();
      currentSeries = regionalSeries.NG.series;
      currentSeries.show();
    });
    zoomOut.hide();

    // Create map polygon series
    let polygonSeries = chart.series.push(new am4maps.MapPolygonSeries());
    polygonSeries.useGeodata = true;
    polygonSeries.calculateVisualCenter = true;

    // Configure series
    let polygonTemplate = polygonSeries.mapPolygons.template;
    polygonTemplate.tooltipText = "{name}";
    polygonTemplate.fill = chart.colors.getIndex(0);
    polygonTemplate.fill = am4core.color("#FCD3B7");

    // Load data when map polygons are ready
    chart.events.on("ready", loadStores);

    // Loads store data
    function loadStores() {
      let loader = new am4core.DataSource();
      //loader.url = "https://s3-us-west-2.amazonaws.com/s.cdpn.io/t-160/TargetStores.json";
      // loader.events.on("parseended", function (ev) {
      // setupStores(query_results);
      setupStores(heatMap);
      //});
      loader.load();
    }

    // Creates a series
    function createSeries(heatfield) {
      let series = chart.series.push(new am4maps.MapImageSeries());
      series.dataFields.value = heatfield;

      let template = series.mapImages.template;
      template.verticalCenter = "middle";
      template.horizontalCenter = "middle";
      template.propertyFields.latitude = "lat";
      template.propertyFields.longitude = "long";
      template.tooltipText = "[bold]{name}\n[bold]Stock {count}\n[bold]\n{stores} stores[/]";
      template.fill = am4core.color("#FB984A");
      let circle = template.createChild(am4core.Circle);
      circle.radius = 10;
      circle.fillOpacity = 0.7;
      circle.verticalCenter = "middle";
      circle.horizontalCenter = "middle";
      circle.nonScaling = true;

      let label = template.createChild(am4core.Label);
      label.text = "{count}";
      label.fill = am4core.color("#fff");
      label.verticalCenter = "middle";
      label.horizontalCenter = "middle";
      label.nonScaling = true;

      series.heatRules.push({
        target: circle,
        property: "radius",
        min: 10,
        max: 30,
      });

      // Set up drill-down
      series.mapImages.template.events.on("hit", function (ev) {
        // Determine what we've clicked on
        let data = ev.target.dataItem.dataContext;

        // No id? Individual store - nothing to drill down to further
        if (!data.target) {
          return;
        }

        // Create actual series if it hasn't been yet created
        if (!regionalSeries[data.target].series) {
          regionalSeries[data.target].series = createSeries("count");
          regionalSeries[data.target].series.data = data.markerData;
        }

        // Hide current series
        if (currentSeries) {
          currentSeries.hide();
        }

        // Control zoom
        if (data.type === "state") {
          let statePolygon = polygonSeries.getPolygonById("NG-" + data.state);
          chart.zoomToMapObject(statePolygon);
        } else if (data.type === "city") {
          chart.zoomToGeoPoint(
            {
              latitude: data.lat,
              longitude: data.long,
            },
            64,
            true
          );
        }
        zoomOut.show();

        // Show new targert series
        currentSeries = regionalSeries[data.target].series;
        currentSeries.show();
      });

      return series;
    }

    let regionalSeries = {};
    let currentSeries;

    function setupStores(data) {
      // Init country-level series
      regionalSeries.NG = {
        markerData: [],
        series: createSeries("stores"),
      };

      // Set current series
      currentSeries = regionalSeries.NG.series;

      // Process data
      // am4core.array.each(query_results, function (storedata) {
      am4core.array.each(heatMap, function (storedata) {
        // Get store data
        let store = {
          state: storedata.MAIL_ST_PROV_C,
          long: am4core.type.toNumber(storedata.LNGTD_I),
          lat: am4core.type.toNumber(storedata.LATTD_I),
          location: storedata.co_loc_n,
          city: storedata.mail_city_n,
          storeName: storedata.store_name,
          count: am4core.type.toNumber(storedata.count),
        };

        // Process state-level data
        if (regionalSeries[store.state] === undefined) {
          let statePolygon = polygonSeries.getPolygonById("NG-" + store.state);
          if (statePolygon) {
            // Add state data
            regionalSeries[store.state] = {
              target: store.state,
              type: "state",
              name: statePolygon.dataItem.dataContext.name,
              count: store.count,
              stores: 1,
              lat: statePolygon.visualLatitude,
              long: statePolygon.visualLongitude,
              state: store.state,
              storeName: store.storeName,
              markerData: [],
            };
            regionalSeries.NG.markerData.push(regionalSeries[store.state]);
          } else {
            // State not found
            return;
          }
        } else {
          regionalSeries[store.state].stores++;
          regionalSeries[store.state].count += store.count;
        }

        // Process city-level data
        if (regionalSeries[store.city] === undefined) {
          regionalSeries[store.city] = {
            target: store.city,
            type: "city",
            name: `Store Name: ${store.storeName} ,\n ${store.city}`,
            count: store.count,
            stores: 1,
            lat: store.lat,
            long: store.long,
            storeName: store.storeName,
            state: store.state,
            markerData: [],
          };
          regionalSeries[store.state].markerData.push(
            regionalSeries[store.city]
          );
        } else {
          regionalSeries[store.city].stores++;
          regionalSeries[store.city].count += store.count;
        }

        // Process individual store
        regionalSeries[store.city].markerData.push({
          name: store.location,
          count: store.count,
          stores: 1,
          lat: store.lat,
          long: store.long,
          storeName: store.storeName,
          state: store.state,
        });
      });

      regionalSeries.NG.series.data = regionalSeries.NG.markerData;
    }
  }, [heatMap]);
  return (
    <>
      <div
        className="bg-white"
        style={{ borderRadius: 4, padding: 14 }}
      >
        <div id="mapBox" style={{ width: "100%", height: "439px" }}></div>
        <div className="overflowMapHidden"></div>
      </div>
    </>
  );
};
export default Ukmap;
