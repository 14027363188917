import React from 'react';
import { Row, Col, Form, Button } from "antd";
import { messages } from "../../common/messages";
import { CheckCircleFilled } from "@ant-design/icons";
import MonoConnect from '@mono.co/connect.js';

const Verifyaccount = ({ code, setCode, accountStatus, lan }) => {
    const monoConnect = React.useMemo(() => {
        const monoInstance = new MonoConnect({
            onClose: () => console.log('Widget closed'),
            onLoad: () => console.log('Widget loaded successfully'),
            onSuccess: ({ code }) => {
                setCode(code);
            },
            key: process.env.REACT_APP_MONO_CONNECT_KEY
        })
        monoInstance.setup()
        return monoInstance;
        // eslint-disable-next-line
    }, [])
    return (
        <>
            {code === '' ?
                <>
                    {/* Verify  Account Details Section  Start */}
                    <Row className="verifyAccountBox" gutter="24">
                        <Col xl={24} xs={24}>
                            {/* <Form layout="vertical" name=""> */}
                            <Row gutter="48">
                                <Col xl={12} xs={24} offset={4} className="innerBox">
                                    {accountStatus !== 'unverified' ? <>
                                        <h1>{messages.verifyAccountDetails[lan]}</h1>
                                        <Form.Item>
                                            <Button type="primary" onClick={() => monoConnect.open()}>
                                                {messages.verify[lan]}
                                            </Button>
                                        </Form.Item>
                                    </> : <h1 className='text-center mb-0'>{messages.pleaseVerifyYourAccount[lan]}!
                                    </h1>}
                                </Col>
                            </Row>
                            {/* </Form> */}
                        </Col>
                    </Row>
                    {/* Verify  Account Details Section  End */}
                </>
                : <>
                    {/* Verified Section  Start */}
                    <Row className="verifiedAccountBox" gutter="24">
                        <Col xl={24} xs={24}>
                            <Form layout="vertical" name="">
                                <Row gutter="48">
                                    <Col xl={12} xs={24} offset={4} className="innerBox">
                                        <CheckCircleFilled />
                                        <h1>{messages.verified[lan]}!</h1>
                                        <p>{messages.yourAccountDetailSuccessfullyVerified[lan]}</p>
                                        {/* <Button type="primary">
                                    Add Inventory
                                </Button> */}
                                    </Col>
                                </Row>
                            </Form>
                        </Col>
                    </Row>
                    {/* Verified Section  End */}
                </>
            }

        </>
    );
};

export { Verifyaccount };