import { Layout, Row, Col, Space, message, Card } from "antd";
import React, { useEffect, useState } from "react";
import { PageTitle } from "../../common/components/layout/";
import Chart from "../../common/components/Chart";
import { Loader } from "../../common/components/";
import { CountryGraph } from "../../modules/dashboard/";
import "../../modules/dashboard/dashboard.less";
import { ApiRoutes } from "../../config/ApiRoutes";
import { ApiHelper, getUserData } from "../../Helpers";


import Nigeriamap from "../../modules/dashboard/ng/Nigeriamap";
import Ukmap from "../../modules/dashboard/uk/Ukmap";
import Usmap from "../../modules/dashboard/us/Usmap";
import { Emptymsg } from "../../common/components/layout/";

const { Content } = Layout;
const title = "Customer Dashboard";
const CustomerDashboard = (props) => {
  const query = new URLSearchParams(props.match.params);
  const campaignId = query.get("id");
  const [campaign, setCampaign] = useState([]);
  const [customerData, setCustomerData] = useState({
    assign: 0,
    downloaded: 0,
    redeemed: 0,
  });
  const [heatMap, setHeatMap] = useState(null);
  const [loader, setLoader] = useState(true);
  useEffect(() => {
    getCampaignDetail();
    // eslint-disable-next-line
  }, []);
  const getCampaignDetail = async () => {
    const response = await new ApiHelper().FetchFromServer(
      ApiRoutes.CAMPAIGN_PRODUCT_STOCK.service,
      ApiRoutes.CAMPAIGN_PRODUCT_STOCK.url,
      ApiRoutes.CAMPAIGN_PRODUCT_STOCK.method,
      ApiRoutes.CAMPAIGN_PRODUCT_STOCK.authenticate,
      { campaignId: campaignId }
    );
    const responseDashboard = await new ApiHelper().FetchFromServer(
      ApiRoutes.CAMPAIGN_CUSTOMER_DASHBOARD.service,
      ApiRoutes.CAMPAIGN_CUSTOMER_DASHBOARD.url,
      ApiRoutes.CAMPAIGN_CUSTOMER_DASHBOARD.method,
      ApiRoutes.CAMPAIGN_CUSTOMER_DASHBOARD.authenticate,
      { campaignId: campaignId }
    );
    setLoader(false);
    if (response.code === 200) {
      setCampaign(response.data.data);
    } else {
      message.error({
        content: response.messages || response.error,
        duration: 2,
      });
    }
    if (responseDashboard.code === 200) {
      setCustomerData(responseDashboard.data.data);
      let graphData = [];
      for (let x in responseDashboard.data.data.graphData) {
        if (responseDashboard.data.data.graphData[x].code) {
          graphData.push({
            "co_loc_n": responseDashboard.data.data.graphData[x].address,
            "store_name": responseDashboard.data.data.graphData[x].organizationName,
            "MAIL_ST_PROV_C": responseDashboard.data.data.graphData[x].code,
            "LNGTD_I": responseDashboard.data.data.graphData[x].longitude,
            "LATTD_I": responseDashboard.data.data.graphData[x].latitude,
            "mail_city_n": responseDashboard.data.data.graphData[x].address,
            "count": responseDashboard.data.data.graphData[x].totalStock,
          });
        }
      }
      setHeatMap(graphData);
    }
  };
  return (
    <>
      <Space className="single-filter-sec m-d-block headerFix">
        <PageTitle title={title} />
      </Space>
      <Content
        className="content-body"
        style={{ minHeight: 200 }}
        id="Dashboard"
      >
        <Row>
          <Col lg={24} xs={24}>
            {loader ? (
              <section className="text-center" style={{ height: '80vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}><Loader /></section>
            ) : (
              <div
                style={{
                  margin: "0px 40px 14px 40px"
                }}
              >

                <Row gutter={[16, 16]}>
                  <Col lg={24} xs={24}>
                    {campaign?.isoCode === "UK" ? (<>
                      {heatMap ? <Ukmap heatMap={heatMap} /> : ''}
                    </>) : ''}
                    {campaign?.isoCode === "NGA" ? (<>
                      {heatMap ? <Nigeriamap heatMap={heatMap} /> : ''}
                    </>) : ''}
                    {campaign?.isoCode === "US" || campaign?.isoCode === "USA" ? (<>
                      {heatMap ? <Usmap heatMap={heatMap} /> : ''}
                    </>) : ''}
                  </Col>
                </Row><br />

                <Row gutter={[16, 16]}>

                  <Col lg={24} xs={24}>
                    <Row gutter={24}>
                      <Col lg={12} xs={12}>
                        <Card>
                          <h3>Downloaded</h3>
                          {customerData.assign
                            ?
                            <Chart id={1} optain={customerData.downloaded} total={customerData.assign} />
                            : ''}
                        </Card>
                      </Col>
                      <Col lg={12} xs={12}>
                        <Card>
                          <h3>Redeemed</h3>
                          {customerData.assign
                            ?
                            <Chart id={2} optain={customerData.redeemed} total={customerData.assign} />
                            : ''}
                        </Card>
                      </Col>
                    </Row>
                  </Col>
                </Row>

              </div>)}
          </Col>
        </Row>
      </Content>
    </>
  );
};
export default CustomerDashboard;
