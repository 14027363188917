import React ,{useEffect} from "react";
import { Avatar, Tooltip } from "antd";
import { AndroidOutlined, AppleOutlined, StarFilled } from "@ant-design/icons";
import Fade from 'react-reveal/Fade';
import Banner1 from '../../assets/images/Frontend/stasher.png'
import Doller from '../../assets/images/Frontend/Group 24807.png'
import About from '../../assets/images/Frontend/Group 24884.png'
import Brandoffer from '../../assets/images/Frontend/brand offer.png'
import Productoffer from '../../assets/images/Frontend/product offer.png'
import Businessoffer from '../../assets/images/Frontend/business offer.png'
import Features from '../../assets/images/Frontend/Group 24886.png'
import Features1 from '../../assets/images/Frontend/map.png'
import Download from '../../assets/images/Frontend/app home.png'
import User1 from '../../assets/images/Frontend/customer01.png'
import User2 from '../../assets/images/Frontend/customer02.png'
import User3 from '../../assets/images/Frontend/customer03.png'
import BollOrange from '../../assets/images/Frontend/BollOrange.png'
import BollGreen from '../../assets/images/Frontend/BollGreen.png'
import ProductIcon from '../../assets/images/Frontend/product.png'
import BrandIcon from '../../assets/images/Frontend/brand-image.png'
import BusinessIcon from '../../assets/images/Frontend/business_black.png'
import LocationIcon from '../../assets/images/Frontend/location km.png'
const Stasher = () => {
    useEffect(() => {
        window.scrollTo(0, 0)
      }, [])
    return (
        <>
            <section id="banner" className="container-fluid banner-section d-flex content">
                <div className="container align-self-center">
                    <div className='row'>
                        <div className="col-lg-6 pt-5 ">
                            <Fade left><h1 className="pt-5 pb-2">The easiest way to  save, everywhere you spend  money!</h1></Fade>
                            <div className='d-flex m-d-flex mb-5'>
                                <Fade left><a href='https://apps.apple.com/in/app/kardstash/id1542355560' rel="noreferrer" target="_blank" className='btn-app-store bg-fill mr-2'><AppleOutlined /> App Store</a></Fade>
                                <Fade left><a href='https://play.google.com/store/apps/details?id=com.Kardstash' rel="noreferrer" className="btn-android-store bg-unfill" target="_blank"><AndroidOutlined /> Google Play</a></Fade>
                            </div>
                            <div className='d-flex mt-5'>
                                <Fade bottom><Avatar.Group size="large" className='me-4'>
                                    <Tooltip title="Customer" placement="top"><Avatar src={User1} alt="icon" size={{ xs: 24, sm: 32, md: 40, lg: 64, xl: 64 }} /></Tooltip>
                                    <Tooltip title="Customer" placement="top"><Avatar src={User2} alt="icon" size={{ xs: 24, sm: 32, md: 40, lg: 64, xl: 64 }} /></Tooltip>
                                    <Tooltip title="Customer" placement="top"><Avatar src={User3} alt="icon" size={{ xs: 24, sm: 32, md: 40, lg: 64, xl: 64 }} /></Tooltip>
                                </Avatar.Group></Fade>
                                <div className='d-flex flex-column justify-content-center'>
                                    <Fade bottom><h5 className='mb-1'>Our Happy Customer</h5></Fade>
                                    <Fade bottom><p className='mb-0'><StarFilled style={{ fontSize: '1.2rem', color: '#FFC300' }} /> <strong style={{ fontSize: '1.2rem' }}>4.6 </strong>(10.15k Review)</p></Fade>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 py-2">
                            <Fade bottom><img src={Banner1} alt="icon" width="100%" /></Fade>
                        </div>
                    </div>
                </div>
            </section>
            <section id="free" className="container-fluid free-section  spaceY d-flex content">
                <div className="container align-self-center">
                    <div className='row'>
                        <div className="col-lg-12 pb-5 ">
                            <Fade bottom><h2 className='text-center'>It's completely free</h2></Fade>
                        </div>
                    </div>
                    <Fade bottom><div className='inner-box shadow-lg'>
                        <div className='row'>
                            <div className="col-lg-5 col-12 py-3 d-flex left">
                                <img src={Doller} alt="icon" width="35%" className='mx-3' />
                                <div className='align-self-center ps-3'>
                                    <h4 className='mb-1'>Shop and Save</h4>
                                    <p className='mb-0'>kardStash is the breakpoint in marketing where the joy in doing business truly begins to happen!</p>
                                </div>
                            </div>
                            <div className="col-lg-7 col-12 py-3 right">
                                <div className='d-flex align-item-center justify-content-around h-100'>
                                    <div className='step text-center py-3'>
                                        <h3 className='f-ternary mb-0'>1 step</h3>
                                        <p className='mb-0'>Join</p>
                                    </div>
                                    <div className='step text-center borderLR py-3'>
                                        <h3 className='f-ternary mb-0'>2 step</h3>
                                        <p className='mb-0'>Stash</p>
                                    </div>
                                    <div className='step text-center py-3'>
                                        <h3 className='f-ternary mb-0'>3 step</h3>
                                        <p className='mb-0'>Redeem and Release</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div></Fade>
                </div>
            </section>
            <section id="about" className="container-fluid about-section  spaceY d-flex content">
                <div className="container align-self-center">
                    <div className='row'>
                        <div className="col-lg-6 col-12">
                            <Fade left><img src={About} alt="icon" width="90%" className='img-fluid pe-5' /></Fade>
                        </div>
                        <div className="col-lg-6 col-12 pt-5">
                            <Fade right><h4 className='heading secondary'>What they say</h4></Fade>
                            <Fade right><h2>About KardStash</h2></Fade>
                            <Fade right><p className='mb-4'>KardStash's mission is to connect small and local businesses with customers who are looking for great deals! We help customers save money with access to offers from their local businesses. Its free!</p></Fade>

                            <Fade right><p className='mb-4'>It is powered by SpacePointe and designed to create a way for small businesses to market their services to their community in a way that is not cost prohibitive.</p></Fade>

                            <Fade right><p className='mb-4'>Stashers are consumers who have joined KardStash and have stashed offers in their wallets, ready to shop and save!</p></Fade>

                            <Fade right><p>KardStash is the breakpoint in marketing where the joy in doing business truly begins to happen!</p></Fade>
                        </div>
                    </div>
                </div>
            </section>
            <section id="offer" className="container-fluid offer-section  spaceY d-flex content">
                <div className="container align-self-center">
                    <div className='row'>
                        <div className="col-lg-12 col-12">
                            <Fade left><h4 className='heading secondary'>Offers</h4></Fade>
                            <Fade left><h2>KardStash offers</h2></Fade>
                            <div className="d-flex align-items-start tab-section">
                                <Fade left><div className="nav flex-column nav-pills me-3" id="v-pills-tab" role="tablist" aria-orientation="vertical">
                                    <button className="nav-link d-flex align-item-center active" id="Product-tab" data-bs-toggle="pill" data-bs-target="#Product" type="button" role="tab" aria-controls="Product" aria-selected="true"><img src={ProductIcon} width="50" alt="icon" className='me-4' />Product</button>
                                    <button className="nav-link d-flex align-item-center" id="Brand-tab" data-bs-toggle="pill" data-bs-target="#Brand" type="button" role="tab" aria-controls="Brand" aria-selected="false"><img src={BrandIcon} width="50" alt="icon" className='me-4' /> Brand</button>
                                    <button className="nav-link d-flex align-item-center" id="Business-tab" data-bs-toggle="pill" data-bs-target="#Business" type="button" role="tab" aria-controls="Business" aria-selected="false"><img src={BusinessIcon} width="50" alt="icon" className='me-4' /> Business</button>
                                </div>
                                </Fade>

                                <div className="tab-content" id="v-pills-tabContent">
                                    <Fade right><div className="tab-pane fade show active" id="Product" role="tabpanel" aria-labelledby="Product-tab"><img src={Brandoffer} alt="icon" width="100%" className='img-fluid' /></div> </Fade>
                                    <Fade right><div className="tab-pane fade" id="Brand" role="tabpanel" aria-labelledby="Brand-tab"><img src={Productoffer} alt="icon" width="100%" className='img-fluid' /></div></Fade>
                                    <Fade right><div className="tab-pane fade" id="Business" role="tabpanel" aria-labelledby="Business-tab"><img src={Businessoffer} alt="icon" width="100%" className='img-fluid' /></div></Fade>
                                </div>


                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section id="features" className="container-fluid features-section  spaceY d-flex content">
                <div className='boll-orange-section'>
                    <Fade bottom><img src={BollOrange} alt="icon" /></Fade>
                </div>
                <div className="container align-self-center">
                    <div className='row'>
                        <div className="col-lg-6 col-12">
                            <Fade left><img src={Features} alt="icon" width="100%" className='img-fluid' /></Fade>
                        </div>
                        <div className="col-lg-6 col-12 pt-5">
                            <Fade right><h4 className='heading ternary'>Features</h4></Fade>
                            <Fade right><h2 className='mb-5'>KardStash App offer<br /> Variety of features</h2></Fade>
                            <Fade right><p className='mb-4'><strong>Enjoy Incredible deals everyday –</strong> hundreds of merchants and services are joining kardstash everyday and that means great deals are available to you everyday. This is the fun way to save ​wherever you spend money​</p></Fade>
                            <Fade right><p className='mb-4'><strong>Contactless redemption – </strong>with your KardStash app, you can limit contact payment, all you need to redeem your offers is to scan the QR code at the merchant’s store.</p></Fade>
                            <Fade right><p className='mb-4'><strong>Get access to big brand promotions – </strong>promotions from big brands show up instantly in your App and you can stash them in your wallet.</p></Fade>
                            <Fade right><p><strong>Manage your Stash – </strong>your Stash is your digital wallet and you have complete control of stashing your offers, unstashing an offer, gifting an offer or redeeming an offer, all from the KardStash app.</p></Fade>
                            <Fade right><p><strong>It is easy and convenient to Shop, spend and save… </strong>with the KardStash App available for IOS and Android, saving wherever you spend is always at the tip of your fingers</p></Fade>
                        </div>
                    </div>
                </div>
            </section>
            <section id="features" className="container-fluid features-section  spaceY d-flex content">
                <div className='boll-green-section'>
                    <Fade bottom><img src={BollGreen} alt="icon" /></Fade>
                </div>
                <div className="container align-self-center">
                    <div className='row'>

                        <div className="col-lg-6 col-12 pt-5">
                            <Fade left><h4 className='heading secondary mt-5'>Features</h4></Fade>
                            <Fade left><h2 className='mb-5'>Track the store, grocery<br /> location near you</h2></Fade>
                            <Fade left><p className='mb-4'><strong>Search for discount offers by geolocation or industry – </strong>with one touch, you can filter offers by geolocation and by industry and see what offers are available from your local businesses</p></Fade>

                        </div>
                        <div className="col-lg-6 col-12 position-relative">
                            <Fade right><img src={Features1} alt="icon" width="100%" className='img-fluid' /></Fade>
                            <Fade bottom><img src={LocationIcon} className="location-icon" alt="icon" /></Fade>
                        </div>

                    </div>
                </div>
            </section>
            <section id="download" className="container-fluid download-section  spaceY d-flex content">
                <div className="container align-self-center">
                    <div className='row'>
                        <div className="col-lg-6 col-12 pt-5 pe-5 left">
                            <Fade left><h4 className='heading px-0'>Download the App</h4></Fade>
                            <Fade left><h2 className='mb-5'>Get started with <br /> KardStash  Today!</h2></Fade>
                            <Fade left><p className='mb-5'>And start enjoying the ultimate shopping experience.</p></Fade>
                            {/* <Fade bottom>
                                <div className="input-group   my-5">
                                    <input type="text" className="form-control shadow" placeholder="Enter email address" aria-label="Enter email address" aria-describedby="basic-addon2" />
                                    <span className="input-group-text shadow" id="basic-addon2"><Button type="primary" size="large">Get started</Button ></span>
                                </div>
                            </Fade> */}
                        </div>
                        <div className="col-lg-6 col-12 right">
                            <Fade right><img src={Download} alt="icon" width="100%" className='img-fluid' /></Fade>
                        </div>

                    </div>
                </div>
            </section>
        </>
    );
};
export default Stasher;