import React from "react";
import { Row, Col, Form, Button, Input, Select } from "antd";
import CountryPhoneInput, { ConfigProvider } from "antd-country-phone-input";
import MapContainer from "../../common/components/MapContainer";
import en from "world_countries_lists/data/en/world.json";
import "flagpack/dist/flagpack.css";

const AddStore = (props) => {
  const [storeType, setStoreType] = React.useState("");
  const {
    form,
    onFinish,
    getCityList,
    stateData,
    cityData,
    isLoading,
    campaignData,
    handleCityChange,
    setLatitude,
    setLongitude,
    initialLocation,
    brandData,
    businessCategoryData,
    businessSubcategoryData,
    getSubcategory,
    campaignSelectState,
  } = props;
  const { Option } = Select;
  let phoneCode = "";
  if (brandData.country === 1) {
    phoneCode = "US";
  }
  if (brandData.country === 18) {
    phoneCode = "NG";
  }
  const handleStoreType = async (value) => {
    setStoreType(value);
  };
  return (
    <Row className="merchantRegisterBox" gutter="24">
      <Col xl={24} xs={24}>
        <div className="header mb-5">
          <h2 className="mb-0">Merchant Registration</h2>
          <h4
            className="mb-0"
            style={{
              color: brandData ? brandData.primary : "",
              borderColor: brandData ? brandData.primary : ""
            }}
          >
            Campaign Code &nbsp; &nbsp;{" "}
            {campaignData ? campaignData.campaign.CampaignCode : ""}
          </h4>
        </div>
      </Col>
      <Col xl={24} xs={24}>
        <Form
          layout="vertical"
          className="merchantRegisterForm"
          form={form}
        // validateTrigger='onSubmit'
        >
          <Row gutter={24}>
            <Col xs={24} xl={12}>
              <Form.Item
                label="Do you have single or multiple stores"
                name="merchantStoreType"
                rules={[
                  { required: true, message: "Please select your store type!" }
                ]}
              >
                <Select
                  size="large"
                  placeholder="Select Store Type"
                  onChange={handleStoreType}
                >
                  <Option value="">Select Store Type</Option>
                  <Option value="multiStore">Multi Store</Option>
                  <Option value="singleStore">Single Store</Option>
                </Select>
              </Form.Item>
            </Col>
            <Col xs={24} xl={12}>
              <Form.Item name="phoneCode" hidden={true}>
                <Input />
              </Form.Item>
              <Form.Item
                label="Phone No."
                name="businessPhone"
                rules={[
                  { required: true, message: "Please input your phone no.!" },
                  {
                    pattern: new RegExp(/^[0-9]{7,13}$/i),
                    message: "Invalid phone no.!"
                  }
                ]}
              >
                <ConfigProvider
                  locale={en}
                  areaMapper={(area) => {
                    return {
                      ...area,
                      emoji: (
                        <span className={`fp ${area.short.toLowerCase()}`} />
                      )
                    };
                  }}
                >
                  <CountryPhoneInput
                    defaultValue={{ short: phoneCode }}
                    onChange={(e) => {
                      form.setFieldsValue({
                        phoneCode: e.code,
                        businessPhone: e.phone
                      });
                    }}
                  />
                </ConfigProvider>
              </Form.Item>
            </Col>
            <Col xs={24} xl={12}>
              <Form.Item
                label="Business name"
                name="organizationName"
                rules={[
                  {
                    required: true,
                    message: "Please input your organization name!"
                  }
                ]}
              >
                <Input placeholder="Enter business name" size="large" />
              </Form.Item>
            </Col>
            {/* {storeType === "singleStore" ? (<> */}
            <Col xs={24} xl={12}>
              <Form.Item
                label="Business Category"
                name="businessCategory"
                rules={[
                  {
                    // required: storeType === "singleStore" ? true : false,
                    required: true,
                    message: "Please select your business category!"
                  }
                ]}
              >
                <Select
                  allowClear
                  showSearch
                  placeholder="Select business category"
                  size="large"
                  onChange={(e) => getSubcategory(e)}
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                // filterSort={(optionA, optionB) =>
                //   optionA.children
                //     .toLowerCase()
                //     .localeCompare(optionB.children.toLowerCase())
                // }
                >
                  <Option value="">Select business category</Option>
                  {businessCategoryData &&
                    businessCategoryData.map((item, key) => {
                      return (
                        <Option value={item.offerCategoryId} key={key}>
                          {item.categoryName}
                        </Option>
                      );
                    })}
                </Select>
              </Form.Item>
            </Col>
            <Col xs={24} xl={12}>
              <Form.Item
                label="Business Subcategory"
                name="businessSubcategory"
                rules={[
                  {
                    required: true,
                    message: "Please select your business subcategory!"
                  }
                ]}
              >
                <Select
                  allowClear
                  showSearch
                  placeholder="Select business subcategory"
                  size="large"
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                // filterSort={(optionA, optionB) =>
                //   optionA.children
                //     .toLowerCase()
                //     .localeCompare(optionB.children.toLowerCase())
                // }
                >
                  <Option value="">Select business subcategory</Option>
                  {businessSubcategoryData &&
                    businessSubcategoryData.map((item, key) => {
                      return (
                        <Option value={item.offerCategoryId} key={key}>
                          {item.categoryName}
                        </Option>
                      );
                    })}
                </Select>
              </Form.Item>
            </Col>
            {/* </>
            ) : (
              ""
            )} */}
            <Col xs={24} xl={12}>
              <Form.Item
                label="Email id"
                name="email"
                rules={[
                  {
                    type: "email",
                    required: true,
                    message: "Please input your email!"
                  }
                ]}
              >
                <Input placeholder="Enter email id" size="large" />
              </Form.Item>
            </Col>
            <Col xs={24} xl={12}>
              <Form.Item
                label="Username"
                name="userName"
                rules={[
                  { required: true, message: "Please input your user name!" }
                ]}
              >
                <Input placeholder="Enter username" size="large" />
              </Form.Item>
            </Col>
            <Col xs={24} xl={12}>
              <Form.Item
                label="First name"
                name="firstName"
                rules={[
                  { required: true, message: "Please input your first name!" }
                ]}
              >
                <Input placeholder="Enter first name" size="large" />
              </Form.Item>
            </Col>
            <Col xs={24} xl={12}>
              <Form.Item
                label="Last name"
                name="lastName"
                rules={[
                  { required: true, message: "Please input your last name!" }
                ]}
              >
                <Input placeholder="Enter last name" size="large" />
              </Form.Item>
            </Col>
            <Col xs={24} xl={12}>
              <Form.Item
                label="State"
                name="stateId"
                rules={[
                  { required: true, message: "Please select your state!" }
                ]}
              >
                <Select
                  size="large"
                  onChange={(e) => getCityList(e)}
                  allowClear
                  showSearch
                  showArrow
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  maxTagCount="responsive"
                  style={{ width: "100%" }}
                  placeholder="Select State"
                >
                  <Option value="">Select State</Option>
                  {campaignSelectState !== 'ALL States' && campaignSelectState.length > 0
                    ? campaignSelectState &&
                    campaignSelectState.map((item, key) => {
                      return (
                        <Option value={item.StateId} key={key}>
                          {item.stateName}
                        </Option>
                      );
                    }) : stateData &&
                    stateData.map((item, key) => {
                      return (
                        <Option value={item.stateId} key={key}>
                          {item.stateName}
                        </Option>
                      );
                    })}
                </Select>
              </Form.Item>
            </Col>
            <Col xs={24} xl={12}>
              <Form.Item
                label="City"
                name="cityId"
                rules={[
                  { required: true, message: "Please select your city!" }
                ]}
              >
                <Select
                  size="large"
                  onChange={() => handleCityChange()}
                  allowClear
                  showSearch
                  showArrow
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  maxTagCount="responsive"
                  style={{ width: "100%" }}
                  placeholder="Select City"
                >
                  <Option value="">Select City</Option>
                  {cityData &&
                    cityData.map((item, key) => {
                      return (
                        <Option value={item.cityId} key={key}>
                          {item.cityName}
                        </Option>
                      );
                    })}
                </Select>
              </Form.Item>
            </Col>
            <Col xs={24} xl={12}>
              <Form.Item
                label="Street address"
                name="addressLine1"
                rules={[
                  {
                    required: true,
                    message: "Please input your street address!"
                  }
                ]}
              >
                <Input
                  placeholder="Enter street address"
                  size="large"
                  onKeyPress={() => handleCityChange()}
                />
              </Form.Item>
            </Col>
            <Col xs={24} xl={12}>
              <Form.Item
                label="Zip Code"
                name="zipCode"
                rules={[
                  { required: true, message: "Please input your zip code" },
                  {
                    pattern: new RegExp(/^[a-zA-Z0-9]{5,8}$/i),
                    message: "Invalid zip code"
                  }
                ]}
              >
                <Input
                  placeholder="Enter zip code"
                  size="large"
                  onKeyPress={() => handleCityChange()}
                />
              </Form.Item>
            </Col>
            <Col xs={24} xl={12}>
              {initialLocation.lat ? (
                <>
                  Set Geocoordinate
                  <div style={{ height: "300px" }}>
                    <MapContainer
                      setLatitude={setLatitude}
                      setLongitude={setLongitude}
                      initialLocation={initialLocation}
                    />
                  </div>
                </>
              ) : (
                ""
                // <div className="fillBox" style={{ height: "300" }}>
                //   <Empty
                //     image={
                //       <svg
                //         xmlns="http://www.w3.org/2000/svg"
                //         width="55.689"
                //         height="74.253"
                //         viewBox="0 0 55.689 74.253"
                //       >
                //         <path
                //           id="map-marker-alt-solid"
                //           d="M24.983,72.755C3.911,42.207,0,39.072,0,27.845a27.845,27.845,0,0,1,55.689,0c0,11.227-3.911,14.362-24.983,44.91a3.482,3.482,0,0,1-5.723,0Zm2.862-33.308a11.6,11.6,0,1,0-11.6-11.6A11.6,11.6,0,0,0,27.845,39.447Z"
                //           fill="#30649b"
                //         />
                //       </svg>
                //     }
                //     style={{
                //       display: "flex",
                //       flexDirection: "column",
                //       justifyContent: "center",
                //       alignItems: "center",
                //       width: "100%",
                //       margin: "0px auto",
                //       padding: "32px 0",
                //       borderRadius: "10px",
                //       backgroundColor: "#fff",
                //       textAlign: "left"
                //     }}
                //     description={
                //       <span>
                //         <p>Please, fill in the following fields:</p>
                //         <ul>
                //           <li>State</li>
                //           <li>City</li>
                //           <li>Street Address</li>
                //           <li>Zipcode</li>
                //         </ul>
                //       </span>
                //     }
                //   ></Empty>
                // </div>
              )}
            </Col>
            <Col xs={24} xl={24} className="btnBox mt-5">
              <Form.Item>
                {/* <Button  className="mr-3" style={{backgroundColor: '#000000',color:'#fff', borderColor:'#000000'}}>Add more store</Button> */}
                {/* <Button className="mr-3" style={{ backgroundColor: '#000000', color: '#fff', borderColor: '#000000' }}>Save</Button> */}
                {isLoading ? (
                  <Button
                    loading
                    style={{
                      backgroundColor: brandData ? brandData.primary : "",
                      color: "#fff",
                      borderColor: brandData ? brandData.primary : ""
                    }}
                  >
                    Next
                  </Button>
                ) : (
                  <Button
                    onClick={onFinish}
                    style={{
                      backgroundColor: brandData ? brandData.primary : "",
                      color: "#fff",
                      borderColor: brandData ? brandData.primary : ""
                    }}
                  >
                    Next
                  </Button>
                )}
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Col>
    </Row>
  );
};

export { AddStore };
