import { Form, Input, Button, message } from "antd";
import { useHistory, Link, useParams } from "react-router-dom";
import React, { useEffect } from "react";
import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons';
import { ApiRoutes } from "../../config/ApiRoutes";
import { ApiHelper, getToken } from "../../Helpers";
const ResetPassword = () => {
  const { id } = useParams()
  const history = useHistory();
  useEffect(() => {
    if (getToken() !== "" && getToken() !== null) {
      history.push({
        pathname: `/dashboard`,
      });
    }
    // eslint-disable-next-line
  }, []);
  const onFinish = async (values) => {
    const response = await new ApiHelper().FetchFromServer(
      ApiRoutes.RESET_PASSWORD.service,
      ApiRoutes.RESET_PASSWORD.url.replace(":id", id),
      ApiRoutes.RESET_PASSWORD.method,
      ApiRoutes.RESET_PASSWORD.authenticate,
      undefined,
      {
        newPassword: values.newPassword,
      }
    );
    if (response.code === 200) {
      message.success({
        content: response.messages[0],
        duration: 2,
      });
      history.push({
        pathname: `/`,
      });
    } else {
      message.error({
        content: response.message || response.messages || response.error,
        duration: 2,
      });
    }
  };

  const onFinishFailed = (errorInfo) => {
  };

  return (
    <Form
      name="basic"
      style={{ marginTop: "10%" }}
      labelCol={{
        span: 8,
      }}
      wrapperCol={{
        span: 8,
      }}
      initialValues={{
        remember: true,
      }}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      autoComplete="off"
    >
      <Form.Item
        label="New Password"
        name="newPassword"
        rules={[
          {
            required: true,
            message: 'New password is required',
          },
          {
            min: 6,
            message: 'Minimum 6 characters are required',
          },
          {
            max: 15,
            message: 'Maximum 15 characters are allow',
          },
        ]}
      >
        <Input.Password placeholder="New Password" iconRender={visible => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}/>
      </Form.Item>
      <Form.Item
        label="Confirm Password"
        name="password"
        rules={[
          {
            required: true,
            message: 'Confirm password is required',
          },
          ({ getFieldValue }) => ({
            validator(_, value) {
              if (!value || getFieldValue('newPassword') === value) {
                return Promise.resolve()
              }
              return Promise.reject("New password and confirm password does not match")
            },
          }),
        ]}
      >
        <Input.Password placeholder="Confirm Password" iconRender={visible => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}/>
      </Form.Item>

      <Form.Item
        wrapperCol={{
          offset: 8,
          span: 16,
        }}
      >
        <Button type="primary" htmlType="submit">
          Submit
        </Button><br />
        <Link to="/">Back to login</Link>
      </Form.Item>
    </Form>
  );
};
export default ResetPassword;
