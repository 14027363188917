import React, { useState, useCallback } from "react";
import Cropper from "react-easy-crop";
import { Row, Col, Typography, Slider, Modal } from "antd";
import getCroppedImg from "./createImage";
import "./styles.css";
// const classes=styles;
const ImageCropper = (props) => {
  const { isModalVisible, setIsModalVisible, cropImage, setImage } = props;
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [rotation, setRotation] = useState(0);
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState();
  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);
  const handleCancel = () => {
    setIsModalVisible(false);
  };
  const showCroppedImage = useCallback(async () => {
    try {
      //eslint-disable-next-line 
      const croppedImage = await getCroppedImg(
        cropImage,
        croppedAreaPixels,
        rotation
      );
      setImage({
        preview: croppedImage.preview,
        raw: croppedImage.image || "",
      });
      setIsModalVisible(false);
      // setCroppedImage(croppedImage);
    } catch (e) {
      console.error(e);
    }
    
  }, [croppedAreaPixels, rotation]);

  return (
    <Modal
      title="Crop the Image"
      visible={isModalVisible}
      onOk={showCroppedImage}
      onCancel={handleCancel}
    >
      <Row>
        {cropImage ? (
          <Col className="crop-container">
            <Cropper
              image={cropImage}
              crop={crop}
              rotation={rotation}
              zoom={zoom}
              aspect={3 / 4}
              onCropChange={setCrop}
              onRotationChange={setRotation}
              onCropComplete={onCropComplete}
              onZoomChange={setZoom}
            />{" "}
          </Col>
        ) : (
          "11"
        )}

        <Col xl={12} xs={12}>
          <Typography variant="overline">Zoom</Typography>
          <Slider
            value={zoom}
            min={1}
            max={3}
            step={0.1}
            aria-labelledby="Zoom"
            onChange={(zoom) => setZoom(zoom)}
          />
        </Col>
        <Col xl={12} xs={12}>
          <Typography variant="overline">Rotation</Typography>
          <Slider
            value={rotation}
            min={0}
            max={360}
            step={1}
            aria-labelledby="Rotation"
            onChange={(rotation) => setRotation(rotation)}
          />
        </Col>
        {/* <Button
          onClick={showCroppedImage}
          variant="contained"
          color="primary"
          classes={{ root: classes.cropButton }}
        >
          Show Result
        </Button> */}
      </Row>
    </Modal>
  );
};
export default ImageCropper;
