import { Select } from "antd";
import React from "react";
const { Option } = Select;
const Country = () => {
  return (
    <Select defaultValue="usa" style={{ width: 160 }}>
      <Option value="usa">United States</Option>
      <Option value="nigeria">Nigeria</Option>
    </Select>
  );
};
export { Country };
