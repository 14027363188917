import React from "react";
import { Layout, Row, Col } from "antd";
import { PageTitle, Emptymsg } from "../../../common/components/layout/";

const { Content } = Layout;
const title = "Empty";
const emptytitle = "No data found";
const emptydescription ="";

const Empty = () => {
  return (
    <>
      <PageTitle title={title} />

      <Content className="emptyBox content-body" style={{ minHeight: 200 }}>
        <Row>
          <Col  xs={{ span: 24, offset: 0 }} lg={{ span: 12, offset: 6 }}>
              <Emptymsg
                  emptytitle={emptytitle}
                  emptydescription={emptydescription}
                />
          </Col>
        </Row>
      </Content>
    </>
  );
};
export default Empty;
