import { Row, Col, DatePicker, message } from "antd";
import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import moment from "moment";
import { ApiRoutes } from "../../config/ApiRoutes";
import { ApiHelper, getCampaignUserData } from "../../Helpers";
import { Loader, EmptyComponents } from "../../common/components/";
import InfiniteScroll from "react-infinite-scroller";
import { AppConfig, limit } from "../../config/AppConfig";
import dummyImage from "../../assets/images/templateNotFound.png";
const { RangePicker } = DatePicker;
const NotificationList = (notificationData) => {
  const { notifications, readNotification } = notificationData;

  return (
    notifications &&
    notifications.map((notification) => {
      // const time = timeDiff(notification.date);
      const additionalData = JSON.parse(notification.additionalData);
      return (
        <section
          className="notificationData shadow-sm"
          key={notification.notificationId}
          onClick={() =>
            readNotification(
              notification.notificationId,
              false,
              additionalData.CampaignId
            )
          }
        >
          <div className="icon">
            <img
              src={
                notification.imageName
                  ? notification.imageName.startsWith("https://")
                    ? notification.imageName
                    : `${AppConfig.API_ENDPOINT}/${notification.imageName}`
                  : dummyImage
              }
              onError={(e) => {
                e.target.onerror = null;
                e.target.src = dummyImage;
              }}
              alt="logo"
            />
          </div>
          <div className="notificationContent">
            <p className="title mb-0">{notification.notification}</p>
            <p className="date mb-0">
              <small>
                {moment(notification?.date).format("MM-DD-YYYY HH:mm:ss")}
              </small>
              <small>{notification?.day} ago</small>
            </p>
          </div>
        </section>
      );
    })
  );
};
const AllNotification = () => {
  const history = useHistory();
  const [offset, setOffset] = useState(0);
  const [loader, setLoader] = useState(true);
  const [hasMore, setHasMore] = useState(true);
  const [notificationData, setNotificationData] = useState([]);
  const [search, setSearch] = useState({
    startDate: "",
    endDate: ""
  });
  const organizationId = getCampaignUserData().organizationId;
  const readNotification = async (notificationId, read, campaignId = "") => {
    await new ApiHelper().FetchFromServer(
      ApiRoutes.NOTIFICATION_STATUS.service,
      ApiRoutes.NOTIFICATION_STATUS.url,
      ApiRoutes.NOTIFICATION_STATUS.method,
      ApiRoutes.NOTIFICATION_STATUS.authenticate,
      undefined,
      { organizationId: organizationId, notificationId: notificationId, read }
    );
    if (campaignId !== "")
      history.push(`/join-campaign/detail?campaignId=${campaignId}`);
  };
  useEffect(() => {
    getNotification();
    // eslint-disable-next-line
  }, []);
  const getNotification = async (
    page = offset,
    filter = true,
    startDate = search.startDate,
    endDate = search.endDate
  ) => {
    setHasMore(false);
    const response = await new ApiHelper().FetchFromServer(
      ApiRoutes.NOTIFICATION_LIST.service,
      ApiRoutes.NOTIFICATION_LIST.url,
      ApiRoutes.NOTIFICATION_LIST.method,
      ApiRoutes.NOTIFICATION_LIST.authenticate,
      {
        organizationId: organizationId,
        employeeId: getCampaignUserData().employeeId,
        limit: limit,
        offset: page + 1,
        startDate,
        endDate
      }
    );
    setOffset(page);
    if (response.code === 200) {
      if (response.data.data.length < limit) setHasMore(false);
      else setHasMore(true);
      if (response.data.data.length > 0) {
        if (notificationData.length > 0 && filter) {
          setNotificationData([...notificationData, ...response.data.data]);
        } else {
          setNotificationData(response.data.data);
        }
      } else setHasMore(false);
    } else {
      message.error({
        content: response.messages || response.error,
        duration: 2
      });
    }
    setLoader(false);
  };

  const filter = (date, dateString) => {
    setLoader(true);
    setSearch({
      startDate: dateString[0],
      endDate: dateString[1]
    });
    getNotification(0, false, dateString[0], dateString[1]);
  };
  return (
    <>
      <Row gutter={[24, 24]} className="notificationView">
        <Col xl={24} lg={24} xs={24}>
          <section className="innerBox">
            <header>
              <h2 className="mb-1">Notifications</h2>
              <RangePicker onChange={filter} />
            </header>
            <Row gutter={[24, 24]} className="scrollbar">
              <Col xl={24} lg={24} xs={24}>
                <div className="notificationBox w-100">
                  <main className="scrollbar">
                    {loader ? (
                      <section
                        className="text-center"
                        style={{
                          height: "80vh",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center"
                        }}
                      >
                        <Loader />
                      </section>
                    ) : notificationData.length > 0 ? (
                      <InfiniteScroll
                        pageStart={offset}
                        loadMore={getNotification}
                        hasMore={hasMore}
                        useWindow={false}
                        loader={
                          <section
                            className="text-center"
                            style={{
                              height: "80vh",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center"
                            }}
                          >
                            <Loader />
                          </section>
                        }
                      >
                        <NotificationList
                          notifications={notificationData}
                          readNotification={readNotification}
                        />
                      </InfiniteScroll>
                    ) : (
                      // </InfiniteScroll>
                      <EmptyComponents
                        title="Notification"
                        message="No Notification Available."
                      />
                    )}
                  </main>
                </div>
              </Col>
            </Row>
          </section>
        </Col>
      </Row>
    </>
  );
};

export { AllNotification };
