import { PageHeader } from "antd";
import React from "react";

const PageTitle = (props) => {
  return (
    <PageHeader
      style={{ padding: 14 }}
      className="site-page-header"
      title={props.title}
      subTitle={props.subtitle}
    />
  );
};
export { PageTitle };
