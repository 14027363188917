import React from "react";
import { Layout as Layouts } from "antd";

// import Footerlayout from "./footer/index";

const WithOutHeaderSideLayout = (props) => {
  return (
    <>
      <div className="ekiakrtApp" id="kardstashLayout">
        <Layouts style={{ minHeight: "100vh" }}>
          <Layouts className="full-layout">
            <>{props.children}</>
            {/* <Footerlayout /> */}
          </Layouts>
        </Layouts>
      </div>
    </>
  );
};

export default WithOutHeaderSideLayout;
