import React, { useEffect } from "react";
import { Row, Col, Form, Input, Select } from "antd";
import CountryPhoneInput, { ConfigProvider } from "antd-country-phone-input";
import MapContainer from "../../common/components/MapContainer";
import en from "world_countries_lists/data/en/world.json";
import "flagpack/dist/flagpack.css";
// import { AppConfig } from "../../config/AppConfig";
// import dummyImage from "../../assets/images/templateNotFound.png";
import { ApiRoutes } from "../../config/ApiRoutes";
import { ApiHelper } from "../../Helpers";
import Geocode from "react-geocode";
Geocode.setApiKey(process.env.REACT_APP_MAP_KEY);

const MultiStore = (props) => {
  const {
    form,
    campaignData,
    brandData,
    inventoryData,
    rowNum,
    merchantStoreType,
    disabled,
    setLatitude,
    setLongitude,
    campaignSelectState,
  } = props;
  const { Option } = Select;
  let phoneCode = "";
  if (brandData.country === 1) {
    phoneCode = "US";
  }
  if (brandData.country === 18) {
    phoneCode = "NG";
  }

  const [stateData, setStateData] = React.useState([]);
  const [cityData, setCityData] = React.useState([]);
  const [initialLocation, setInitialLocation] = React.useState({
    lat: "",
    lng: ""
  });

  useEffect(() => {
    getStateList(brandData?.country);
  }, [brandData]);

  const getStateList = async (country) => {
    if (!country) {
      setCityData([]);
      return false;
    }
    const response = await new ApiHelper().FetchFromServer(
      ApiRoutes.STATE_LIST.service,
      ApiRoutes.STATE_LIST.url,
      ApiRoutes.STATE_LIST.method,
      ApiRoutes.STATE_LIST.authenticate,
      { country: country }
    );
    if (response.code === 200) {
      setStateData(response.data.data);
    }
  };

  const getCityList = async (state) => {
    if (!state) {
      setCityData([]);
      return false;
    }
    const response = await new ApiHelper().FetchFromServer(
      ApiRoutes.CITY_LIST.service,
      ApiRoutes.CITY_LIST.url,
      ApiRoutes.CITY_LIST.method,
      ApiRoutes.CITY_LIST.authenticate,
      { state: state }
    );
    if (response.code === 200) {
      setCityData(response.data.data);
    }
  };

  const handleCityChange = async (value) => {
    const values = await form.getFieldsValue(true);
    let cityName, stateName;
    for (let x in stateData) {
      if (stateData[x].stateId === values[rowNum].stateId) {
        stateName = stateData[x].stateName;
        break;
      }
    }
    for (let x in cityData) {
      if (cityData[x].cityId === values[rowNum].cityId) {
        cityName = cityData[x].cityName;
        break;
      }
    }
    const address = `${values[rowNum].addressLine1} ${cityName} ${stateName} ${values[rowNum].zipCode}`;
    const latLng = await Geocode.fromAddress(address);
    if (latLng.status === "OK") {
      const { lat, lng } = latLng.results[0].geometry.location;
      setInitialLocation({
        lat: "",
        lng: ""
      });
      setLatitude(lat);
      setLongitude(lng);
      setInitialLocation({
        lat,
        lng
      });
    }
  };

  return (
    <>
      {merchantStoreType !== "singleStore" ? (
        <Row className="merchantRegisterBox mb-5" gutter="24">
          <Col xl={24} xs={24}>
            <div className="header mb-5">
              <h2 className="mb-0">Enter store details</h2>
              <h4
                className="mb-0"
                style={{
                  color: brandData ? brandData.primary : "",
                  borderColor: brandData ? brandData.primary : ""
                }}
              >
                Campaign Code &nbsp; &nbsp;{" "}
                {campaignData ? campaignData.campaign.CampaignCode : ""}
              </h4>
            </div>
          </Col>
          <Col xl={24} xs={24}>
            <Row gutter={24}>
              <Col xs={24} xl={12}>
                <Form.Item
                  label="Business name"
                  name={[rowNum, "organizationName"]}
                  rules={[
                    {
                      required: true,
                      message: "Please input your organization name!"
                    }
                  ]}
                >
                  <Input
                    placeholder="Enter business name"
                    size="large"
                    disabled={disabled}
                  />
                </Form.Item>
              </Col>
              <Col xs={24} xl={12}>
                <Form.Item name={[rowNum, "phoneCode"]} hidden={true}>
                  <Input />
                </Form.Item>
                <Form.Item
                  label="Phone no."
                  name={[rowNum, "businessPhone"]}
                  rules={[
                    {
                      required: true,
                      message: "Please input your business phone!"
                    }
                  ]}
                >
                  <ConfigProvider
                    locale={en}
                    areaMapper={(area) => {
                      return {
                        ...area,
                        emoji: (
                          <span className={`fp ${area.short.toLowerCase()}`} />
                        )
                      };
                    }}
                  >
                    <CountryPhoneInput
                      defaultValue={{ short: phoneCode }}
                      onChange={(e) => {
                        form.setFieldsValue({
                          [`${rowNum}`]: {
                            phoneCode: e.code,
                            businessPhone: e.phone
                          }
                        });
                      }}
                      disabled={disabled}
                    />
                  </ConfigProvider>
                </Form.Item>
              </Col>
              <Col xs={24} xl={12}>
                <Form.Item
                  label="Email id"
                  name={[rowNum, "email"]}
                  rules={[
                    {
                      type: "email",
                      required: true,
                      message: "Please input your email!"
                    }
                  ]}
                >
                  <Input
                    placeholder="Enter email id"
                    size="large"
                    disabled={disabled}
                  />
                </Form.Item>
              </Col>
              <Col xs={24} xl={12}>
                <Form.Item
                  label="Username"
                  name={[rowNum, "userName"]}
                  rules={[
                    { required: true, message: "Please input your user name!" }
                  ]}
                >
                  <Input
                    placeholder="Enter username"
                    size="large"
                    disabled={disabled}
                  />
                </Form.Item>
              </Col>
              <Col xs={24} xl={12}>
                <Form.Item
                  label="First name"
                  name={[rowNum, "firstName"]}
                  rules={[
                    { required: true, message: "Please input your first name!" }
                  ]}
                >
                  <Input
                    placeholder="Enter first name"
                    size="large"
                    disabled={disabled}
                  />
                </Form.Item>
              </Col>
              <Col xs={24} xl={12}>
                <Form.Item
                  label="Last name"
                  name={[rowNum, "lastName"]}
                  rules={[
                    { required: true, message: "Please input your last name!" }
                  ]}
                >
                  <Input
                    placeholder="Enter last name"
                    size="large"
                    disabled={disabled}
                  />
                </Form.Item>
              </Col>
              <Col xs={24} xl={12}>
                <Form.Item
                  label="State"
                  name={[rowNum, "stateId"]}
                  rules={[
                    { required: true, message: "Please select your state!" }
                  ]}
                >
                  <Select
                    placeholder="Select state"
                    size="large"
                    showSearch
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    onChange={(e) => getCityList(e)}
                    disabled={disabled}
                  >
                    <Option value="">Select State</Option>
                    {campaignSelectState !== 'ALL States' && campaignSelectState.length > 0
                      ? campaignSelectState &&
                      campaignSelectState.map((item, key) => {
                        return (
                          <Option value={item.StateId} key={key}>
                            {item.stateName}
                          </Option>
                        );
                      }) : stateData &&
                      stateData.map((item, key) => {
                        return (
                          <Option value={item.stateId} key={key}>
                            {item.stateName}
                          </Option>
                        );
                      })}
                  </Select>
                </Form.Item>
              </Col>
              <Col xs={24} xl={12}>
                <Form.Item
                  label="City"
                  name={[rowNum, "cityId"]}
                  rules={[
                    { required: true, message: "Please select your city!" }
                  ]}
                >
                  <Select
                    placeholder="Select city"
                    size="large"
                    showSearch
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    onChange={handleCityChange}
                    disabled={disabled}
                  >
                    <Option value="">Select City</Option>
                    {cityData &&
                      cityData.map((item, key) => {
                        return (
                          <Option value={item.cityId} key={key}>
                            {item.cityName}
                          </Option>
                        );
                      })}
                  </Select>
                </Form.Item>
              </Col>

              <Col xs={24} xl={12}>
                <Form.Item
                  label="Street address"
                  name={[rowNum, "addressLine1"]}
                  rules={[
                    {
                      required: true,
                      message: "Please input your street address!"
                    }
                  ]}
                >
                  <Input
                    placeholder="Enter street address"
                    size="large"
                    disabled={disabled}
                  />
                </Form.Item>
              </Col>
              <Col xs={24} xl={12}>
                <Form.Item
                  label="Zip code"
                  name={[rowNum, "zipCode"]}
                  rules={[
                    { required: true, message: "Please input your zip code!" },
                    {
                      pattern: new RegExp(/^[a-zA-Z0-9]{5,8}$/i),
                      message: "Invalid zip code"
                    }
                  ]}
                >
                  <Input
                    placeholder="Enter zip code"
                    size="large"
                    disabled={disabled}
                  />
                </Form.Item>
              </Col>
              <Col xs={24} xl={12}>
                {initialLocation.lat ? (
                  <>
                    Set Geocoordinate
                    <div style={{ height: "300px" }}>
                      <MapContainer
                        setLatitude={setLatitude}
                        setLongitude={setLongitude}
                        initialLocation={initialLocation}
                      />
                    </div>
                  </>
                ) : (
                  '')}
              </Col>
            </Row>
          </Col>
        </Row>
      ) : (
        ""
      )}
      <Row className="merchantRegisterBox" gutter="24">
        <Col xl={24} xs={24}>
          <div className="header mb-5">
            <h2 className="mb-0">Inventory</h2>
          </div>
        </Col>
        <Col xl={24} xs={24}>
          <div layout="vertical" className="merchantRegisterForm">
            <Row gutter={24}>
              {inventoryData.length > 0 &&
                inventoryData.map((item, key) => {
                  return (
                    <Col xl={12} lg={12} xs={24} key={key}>
                      <section className="innerBox">
                        <img
                          src={item.imageurl}
                          alt=""
                          width="100"
                          height="90"
                        />
                        <div className="text-ellipse1">
                          <h3 className="text-ellipse1">
                            {item.productCodeOveride}
                          </h3>
                          <p>
                            <span>UPC | </span>
                            {item.upc}
                          </p>
                        </div>
                        <div className="stockSec ml-auto">
                          <Form.Item
                            className="mb-0"
                            name={[rowNum, "products", key, "currentQty"]}
                            rules={[
                              {
                                pattern: new RegExp(/^[0-9]*$/i),
                                message: "Invalid stock"
                              },
                              () => ({
                                async validator(_, value) {
                                  if (value.length > 5) {
                                    return Promise.reject(
                                      "Value should be less than 5 character."
                                    );
                                  }
                                  return Promise.resolve();
                                }
                              }),
                            ]}
                          >
                            <Input
                              size="large"
                              placeholder="Enter Stock..."
                              style={{ width: 140 }}
                              rules={[
                                {
                                  pattern: new RegExp(/^[0-9]*$/i),
                                  message: "Invalid stock"
                                },() => ({
                                  async validator(_, value) {
                                    if (value.length > 5) {
                                      return Promise.reject(
                                        "Value should be less than 5 character."
                                      );
                                    }
                                    return Promise.resolve();
                                  }
                                }),
                              ]}
                            />
                          </Form.Item>
                          <Form.Item
                            name={[rowNum, "products", key, "productId"]}
                            hidden={true}
                          >
                            <Input />
                          </Form.Item>
                          <Form.Item
                            name={[
                              rowNum,
                              "products",
                              key,
                              "organizationProductId"
                            ]}
                            hidden={true}
                          >
                            <Input />
                          </Form.Item>
                          <Form.Item
                            name={[
                              rowNum,
                              "products",
                              key,
                              "productInventoryId"
                            ]}
                            hidden={true}
                          >
                            <Input />
                          </Form.Item>
                          <Form.Item
                            name={[rowNum, "products", key, "productPriceId"]}
                            hidden={true}
                          >
                            <Input />
                          </Form.Item>
                        </div>
                      </section>
                    </Col>
                  );
                })}
            </Row>
          </div>
        </Col>
      </Row>
    </>
  );
};

export { MultiStore };
