import { NotificationOutlined } from "@ant-design/icons";
import { Breadcrumb } from "antd";
import React from "react";

const Breadcrumbsec = (props) => {
  return (
    <>
      <Breadcrumb>
        <Breadcrumb.Item href={props.prevlink}>
          <NotificationOutlined />
          <span>{props.prevpagetitle}</span>
        </Breadcrumb.Item>
        <Breadcrumb.Item>{props.currentpagetitle}</Breadcrumb.Item>
      </Breadcrumb>
    </>
  );
};
export { Breadcrumbsec };
