import React from "react";
import { Link } from "react-router-dom";
import { Button } from "antd";
import Fade from 'react-reveal/Fade';
import Men from '../../assets/images/Frontend/men003.png'
import Support from '../../assets/images/Frontend/set up.png'
import Save from '../../assets/images/Frontend/Data Insight.png'
import Audience from '../../assets/images/Frontend/Custom Branded URL.png'
import Comprehensive from '../../assets/images/Frontend/Reporting and Analytics.png'
import Analytics from '../../assets/images/Frontend/Engagement.png'
import Modeling from '../../assets/images/Frontend/Technology.png'

const Whatwegothere = () => {
    return (
        <>            
            <section id="what-we-section" className="container-fluid what-we-got-here-section  spaceY d-flex content">
                <div className="container align-self-center">
                    <div className='row'>
                        <div className="col-lg-12 pb-5 ">
                            <Fade bottom><h2 className='text-center mb-0'>What we got here</h2></Fade>
                        </div>
                    </div>
                    <div className='inner-box'>
                        <div className='row'>
                            <Fade bottom><div className="col-lg-4 col-12 text-left py-3">
                                <div className="d-flex px-4 py-3 align-item-center"><img src={Support} alt="icon" width="80" className="mr-3" /><h4 className='mb-1'>Set up</h4></div>
                                <div className='px-4'>
                                    <p className='mb-0'>Set up products that would be included in campaigns, determine the campaign discount amount, duration of the campaign and States where the campaign would be consumed.</p>
                                </div>
                            </div></Fade>
                            <Fade bottom><div className="col-lg-4 col-12 text-left py-3">
                                <div className="d-flex px-4 py-3 align-item-center"><img src={Audience} alt="icon" width="80" className="mr-3" /><h4 className='mb-1'>Custom Branded URL</h4></div>
                                <div className='px-4'>
                                    <p className='mb-0'>Brands receive custom branded URLs that is generated for their campaigns.</p>
                                </div>
                            </div></Fade>
                            <Fade bottom><div className="col-lg-4 col-12 text-left py-3">
                                <div className="d-flex px-4 py-3 align-item-center"><img src={Comprehensive} alt="icon" width="80" className="mr-3" /><h4 className='mb-1'>Reporting and Analytics</h4></div>
                                <div className='px-4'>
                                    <p className='mb-0'>KardStash platform provides a personalised dashboard where Brands can see data on a map of all merchants that has joined or opted into their campaigns, with locations and store details, as well as inventory count of products available in the merchants store.</p>
                                </div>
                            </div></Fade>
                            <Fade bottom><div className="col-lg-4 col-12 text-left py-5">
                                <div className="d-flex px-4 py-3 align-item-center"><img src={Analytics} alt="icon" width="80" className="mr-3" /><h4 className='mb-1'>Engagement</h4></div>
                                <div className='px-4'>
                                    <p className='mb-0'>Imagine you have all the data insight to drive demand, engagement and actions in the market place using the power of point of sale.</p>
                                </div>
                            </div></Fade>
                            <Fade bottom><div className="col-lg-4 col-12 text-left py-5">
                                <div className="d-flex px-4 py-3 align-item-center"><img src={Save} alt="icon" width="80" className="mr-3" /><h4 className='mb-1'>Data Insight</h4></div>
                                <div className='px-4'>
                                    <p className='mb-0'>View detailed data, from Consumer Cart data, inventory, stock, customer behavioural data, Distribution data and customer geolocation data</p>
                                </div>
                            </div></Fade>
                            <Fade bottom><div className="col-lg-4 col-12 text-left py-5">
                                <div className="d-flex px-4 py-3 align-item-center"><img src={Modeling} alt="icon" width="80" className="mr-3" /><h4 className='mb-1'>Technology</h4></div>
                                <div className='px-4'>
                                    <p className='mb-0'>Leverage technology to boost sales and manage the supply chain end to end, from production, to distributor, merchants and finally to consumer.</p>
                                </div>
                            </div></Fade>
                        </div>
                    </div>
                </div>
            </section>
            <section id="download" className="container-fluid demo-section  spaceY d-flex content">
                <div className="container align-self-center pb-5">
                    <div className='row'>
                        <div className="col-lg-6 col-12 right px-5 position-relative">
                            <Fade right><img src={Men} alt="icon" width="55%" className='img-fluid' /></Fade>
                        </div>
                        <div className="col-lg-6 col-12 pt-5 pe-5 left">
                            <Fade left><h2 className='mb-5'>Drive demand, <br/>growth and sales for your Brand</h2></Fade>
                            <Fade left><p className='mb-5'>Know your consumers and how to reach them. The power to drive demand has never been greater.</p></Fade>
                            <Fade bottom>
                                <a href='https://calendly.com/akennedy-21/15min' rel="noreferrer" target="_blank"><Button type="primary" size="large" className="text-uppercase">Request a demo</Button ></a>
                            </Fade>
                        </div>
                       

                    </div>
                </div>
            </section>
        </>
    );
};
export default Whatwegothere;