import React, { useRef, useLayoutEffect } from 'react';
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";

am4core.useTheme(am4themes_animated);

function Chart({ id, optain, total }) {
    const chart = useRef(null);

    useLayoutEffect(() => {
        // Create chart
        let chart = am4core.create(`chartdiv${id}`, am4charts.GaugeChart);
        chart.minWidth=300;
        chart.width=380;
        chart.parsingStepDuration=10;
        chart.maxWidth=500;
       // chart.innerWidth=350;
        console.log('chart',chart)
        // Create axis
        let axis = chart.xAxes.push(new am4charts.ValueAxis());
        console.log(axis)
        axis.min = 0;
        axis.max = total;
        axis.width=350;
        axis.height=350;
       // axis.measuredWidth=350;
        //axis.innerWidth=350;
        axis.contentWidth=350;
        axis.maxWidth=350;
        axis.parsingStepDuration=10;
        axis.strictMinMax = true;

        // Set inner radius
        chart.innerRadius = -20;

        // Add ranges
        if(total>=10){
        
            let range = axis.axisRanges.create();
            console.log(range)
            range.value = 0;
            // range.endValue = 70;
            range.endValue = (total*70)/100;
            range.axisFill.fillOpacity = 1;
            range.axisFill.fill = am4core.color("#88AB75");
            range.axisFill.zIndex = - 1;

            let range2 = axis.axisRanges.create();
            // range2.value = 70;
            range2.value = (total*70)/100;
            // range2.endValue = 90;
            range2.endValue = (total*90)/100;
            range2.axisFill.fillOpacity = 1;
            range2.axisFill.fill = am4core.color("#DBD56E");
            range2.axisFill.zIndex = - 1;

            let range3 = axis.axisRanges.create();
            // range3.value = 90;
            range3.value = (total*90)/100;
            // range3.endValue = 100;
            range3.endValue = total;
            range3.axisFill.fillOpacity = 1;
            range3.axisFill.fill = am4core.color("#DE8F6E");
            range3.axisFill.zIndex = - 1;
        }else{
            let range = axis.axisRanges.create();
            range.value = 0;
            // range.endValue = 70;
            range.endValue = (total*100)/100;
            range.axisFill.fillOpacity = 1;
            range.axisFill.fill = am4core.color("#88AB75");
            range.axisFill.zIndex = - 1;
        }    
        // Add hand
        let hand = chart.hands.push(new am4charts.ClockHand());
        console.log('optain',optain)
        hand.value = optain;
        /* hand.pin.disabled = true;
        hand.fill = am4core.color("#2D93AD");
        hand.stroke = am4core.color("#2D93AD");
        hand.innerRadius = am4core.percent(50);
        hand.radius = am4core.percent(80);
        hand.startWidth = 15; */

        /*  let hand2 = chart.hands.push(new am4charts.ClockHand());
         hand2.value = 22;
         hand2.pin.disabled = true;
         hand2.fill = am4core.color("#7D7C84");
         hand2.stroke = am4core.color("#7D7C84");
         hand2.innerRadius = am4core.percent(50);
         hand2.radius = am4core.percent(80);
         hand2.startWidth = 15; */

        // Animate
        /* setInterval(function () {
            hand.showValue(Math.random() * 100, 1000, am4core.ease.cubicOut);
            hand2.showValue(Math.random() * 100, 1000, am4core.ease.cubicOut);
        }, 2000); */
    }, []);

    return (
        // <div id="chartdiv" style={{ width: "100%", height: "500px" }}></div>
        <div id={`chartdiv${id}`} style={{ width: "100%", height: "300px" }}></div>
    );
}
export default Chart;