import { Row, Col, Form, Button } from "antd";
import React, { useState } from "react";
import OtpInput from 'react-otp-input';

const AddOtp = ({ onFinish, form, resendOtp, brandData, isLoading, email }) => {
  const [OTP, setOTP] = useState("");
  return (
    <Row gutter="24">
      <Col xl={12} xs={24} offset={6} className="otpSec">
        <div className="header mb-5">
          <h2 className="mb-0">OTP Verification</h2>
          <p>We have sent code to your email {email}</p>
        </div>
        <Form layout="vertical" form={form}>
          <Row gutter={24}>
            <Col xs={24} xl={24}>
              <Form.Item name="otp"
                rules={[{ required: true, message: '' }]}>
                <OtpInput
                  numInputs={5}
                  value={OTP} onChange={setOTP}
                  autoFocus
                  separator={<span></span>}
                  otpType="number" disabled={false} secure
                  focusStyle={{ color: '#FB984A', borderColor: '#FB984A' }}
                  className="otpBox mb-5"
                />
              </Form.Item>
              <Form.Item className="btnBox mb-0">
                {isLoading ?
                  <Button loading block className="mb-4" style={{ backgroundColor: brandData ? brandData.primary : '', color: '#fff', borderColor: brandData ? brandData.primary : '' }}>Verify OTP</Button>
                  : <Button onClick={onFinish} block className="mb-4" style={{ backgroundColor: brandData ? brandData.primary : '', color: '#fff', borderColor: brandData ? brandData.primary : '' }}>Verify OTP</Button>}
                <p className="mb-0">Didn't receive code? <span className="cursor-pointer" onClick={resendOtp} style={{ color: brandData ? brandData.secondary : '' }}>Resend</span></p>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Col>
    </Row>
  )
}

export { AddOtp };