import React, { useState, useEffect } from "react";
import { useParams, Link } from "react-router-dom";
import { Layout, message, Row, Col } from "antd";
import { ApiRoutes } from "../../config/ApiRoutes";
import { ApiHelper } from "../../Helpers";
import { Loader } from "../../common/components/Loader";
import moment from "moment";
import { WarningOutlined } from "@ant-design/icons";
import "./customer.less";

import "../../modules/public/public.less";
// const { Content } = Layout;
const CustomerView = () => {
  const { campaignCode } = useParams();
  const [loading, setLoading] = useState(true);
  const [campaignData, setCampaignData] = useState(null);

  useEffect(() => {
    getBrandDetails();
    // eslint-disable-next-line
  }, []);

  const getBrandDetails = async () => {
    setLoading(true);
    const response = await new ApiHelper().FetchFromServer(
      ApiRoutes.STORE_CAMPAIGN_DETAIL.service,
      ApiRoutes.STORE_CAMPAIGN_DETAIL.url,
      ApiRoutes.STORE_CAMPAIGN_DETAIL.method,
      ApiRoutes.STORE_CAMPAIGN_DETAIL.authenticate,
      {
        id: campaignCode
        // type: "voucher"
      }
    );
    setLoading(false);

    if (response.code === 200) {
      if (response.data === undefined) {
        setCampaignData(null);
      } else {
        if (
          response.data.campaign.Cstatus === "1" ||
          response.data.campaign.Cstatus === 1
        ) {
          message.error({
            content: "Campaign is not yet Published. Please try later.",
            duration: 2
          });
          window.location.href = window.location.origin;
        } else {
          setCampaignData(response.data);
        }
      }
    } else {
      message.error({
        content: response.messages || response.error,
        duration: 2
      });
      window.location.href = window.location.origin;
    }
  };
  return (
    <div id="mobile_body">
      <Layout id="mobile_page" style={{ padding: "10px" }}>
        {loading ? (
          <section
            className="text-center"
            style={{
              height: "80vh",
              display: "flex",
              justifyContent: "center",
              alignItems: "center"
            }}
          >
            <Loader />
          </section>
        ) : (
          <Row className="campaignDetailSec">
            {campaignData ? (
              moment(moment().format("YYYY-MM-DD")).isAfter(
                moment(campaignData.campaign.CEndDate).format("YYYY-MM-DD")
              ) ? (
                <Col xs={24} xl={24} style={{ minHeight: "500px" }}>
                  <div class="dialog">
                    <WarningOutlined />
                    <br />
                    <div class="h1">Oops! That campaign has expired.</div>
                    <div class="body">
                      The page you are looking for is no longer available.
                    </div>
                  </div>
                </Col>
              ) : (
                <Col xs={24} xl={24} style={{ minHeight: "400px" }}>
                  {campaignData?.campaign?.customerTemplateUrl ? <img
                    src={campaignData.campaign.customerTemplateUrl}
                    style={{ width: "100%", minHeight: "400px" }} alt="Customer Template"
                    
                  /> : ''}
                  <Link
                    to={`/c/${campaignCode}/save-to-phone`}
                    style={{
                      color: "inherit",
                      display: "inline-block",
                      transform: `translate(${
                        JSON.parse(campaignData?.campaign?.buttonPosition).x
                      }px,${
                        JSON.parse(campaignData?.campaign?.buttonPosition).y + 4
                      }px)`
                    }}
                  >
                    <button
                      style={{
                        background: `${campaignData?.campaign?.textBackgroundColor}`,
                        color: `${campaignData?.campaign?.textColor}`,
                        border: `1px solid ${campaignData?.campaign?.textBorderColor}`,
                        fontWeight: 600,
                        padding: "10px 20px",
                        cursor: "pointer"
                        
                      }}
                    >
                      Save to Phone
                    </button>
                  </Link>
                </Col>
              )
            ) : (
              ""
            )}
          </Row>
        )}
      </Layout>
    </div>
  );
};
export default CustomerView;
