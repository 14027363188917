
import React, { } from "react";
import { Success } from "../../modules/public/Success";
import "../../modules/public/public.less";
const PublicSuccess = () => {

  return (
    <div id="Join" style={{marginBottom:'auto'}}><Success /> </div>
  );
};
export default PublicSuccess;
