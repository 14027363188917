import { Row, Col, Form, Button, Card, message } from "antd";
import React, { useState, useEffect } from "react";
import { RightOutlined } from "@ant-design/icons";
import { CampaignInformationCard } from "./CampaignInformation";
import { PrivacyPolicyCard } from "./PrivacyPolicy";
import { ProductLocaitonCard } from "./ProductLocation";
import { TemplateSelectionCard } from "./TemplateSelect";
import { PreviewCampaign } from "./Preview";
import { ApiRoutes } from "../../config/ApiRoutes";
import { ApiHelper } from "../../Helpers";
import { useHistory } from "react-router-dom";
import { Loader } from "../../common/components/";
import {CustomerTemplate} from './CustomerTemplate'

const CampaignAdd = (props) => {
  const [form] = Form.useForm();
  const [loader, setLoader] = useState(false);
  const history = useHistory();
  const [state, setState] = useState(props.state);
  const [products, setProducts] = useState(props.products);

  const [data, setData] = useState({
    campaignCode: props.campaignCode,
    denomination: props?.data?.denomination || "",
    noOfVoucher: props?.data?.noOfVoucher || "",
    totalOfferValue: props?.data?.totalOfferValue || "",
    campaignName: props?.data?.campaignName || "",
    startDate: props?.data?.startDate || "",
    terms: props?.data?.terms || props.policyData?.websiteToBrand,
    privacyPolicy: props?.data?.privacyPolicy || props.policyData?.privacyPolicy,
    disclaimer: props?.data?.disclaimer || props.policyData?.disclaimer,
    endDate: props?.data?.endDate || "",
    templateProducts: props?.data?.templateProducts || "",
    state: props?.data?.state || "",
    customerTemplate:props?.data?.customerTemplate || '',
    customImage: props?.data?.customImage || "",
    templateId: props?.data?.templateId || "",
    Cstatus: props?.data?.Cstatus || "",
    templateType: parseInt(props?.data?.templateType) || 1 // 1 custom template or 2 default template
  });

  useEffect(() => {
    setState(props.state);
    setProducts(props.products);
  }, [props.state, props.products]);

  const formData = (value) => {
    setData({ ...data, ...value });
  };

  const step0 = true;
  const step1 = false;
  const step2 = false;
  const step3 = false;
  const step4 = false;
  const step5 = false;

  const saveDraft = async (type) => {
    if (
      data.campaignName === "" ||
      data.endDate === "" ||
      data.startDate === ""
    ) {
      message.error({
        content:
          "Please fill out the required fields (Campaign Name, Campaign Start & End Date). ",
        duration: 5
      });
      return false;
    }
    if (data.denomination < 0 || data.noOfVoucher < 0) {
      message.error({
        content: "No. of Voucher(s)/ Denomination has to be a Number.",
        duration: 5
      });
      return false;
    }
    if (data.noOfVoucher > 50000) {
      message.error({
        content: "Number of Voucher(s) should be less than 50k.",
        duration: 5
      });
      return false;
    }
    try {
      setLoader(true);
      let response;
      if (props.campaignId !== "" && props.campaignId !== undefined) {
        response = await new ApiHelper().FetchFromServer(
          ApiRoutes.CAMPAIGN_EDIT.service,
          ApiRoutes.CAMPAIGN_EDIT.url + "/" + props.campaignId,
          ApiRoutes.CAMPAIGN_EDIT.method,
          ApiRoutes.CAMPAIGN_EDIT.authenticate,
          undefined,
          {
            type: type,
            ...data
          }
        );
      } else {
        response = await new ApiHelper().FetchFromServer(
          ApiRoutes.CAMPAIGN_ADD.service,
          ApiRoutes.CAMPAIGN_ADD.url,
          ApiRoutes.CAMPAIGN_ADD.method,
          ApiRoutes.CAMPAIGN_ADD.authenticate,
          undefined,
          {
            type: type,
            ...data
          }
        );
      }
      if (response.code === 200) {
        message.success({
          content: response.data.message,
          duration: 2
        });

        setTimeout(function () {
          history.push({
            pathname: `/campaigns`
          });
          setLoader(false);
        }, 500);
      } else {
        
        if (response.messages.length > 0) {
          message.error({
            content: response.messages[0] || response.error,
            duration: 2
          });
        } else
          message.error({
            content: response.messages || response.error || response.messages[0],
            duration: 5
          });
        
        setLoader(false);
      }
      // setStep3(true);
    } catch (errorInfo) {
      //const error = errorInfo.errorFields.map((value) => value.errors[0] );
      message.error({
        content:
          "Please fill out the required fields (Campaign Name, Campaign Start & End Date). ",
        duration: 2
      });
    }
  };

  return loader ? (
    <section
      className="text-center"
      style={{
        height: "80vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
      }}
    >
      <section
        className="text-center"
        style={{
          height: "80vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center"
        }}
      >
        <Loader />
      </section>
    </section>
  ) : (
    <>
      <Row className="campaignAddBox" gutter="24">
        <Col xl={24} xs={24}>
          <Form layout="vertical" name="campaignAdd" form={form}>
            <div>
              <input
                type="radio"
                className="hidden"
                id="step-0"
                name="progress"
                defaultChecked={step0}
              />
              <label htmlFor="step-0" className="step">Campaign Information
                <label htmlFor="step-0" className="arrowIcon arrow1">
                  <RightOutlined />
                </label>
              </label>
              <CampaignInformationCard
                formData={data}
                data={data}
                setFormData={formData}
                currency={props.currency}
              />
            </div>

            <div>
              <input
                type="radio"
                className="hidden"
                id="step-1"
                name="progress"
                defaultChecked={step1}
              // checked={isActive === 'step-1' ? true : ''}
              />
              <label htmlFor="step-1" className="step">
                Products &amp; Location
                <label htmlFor="step-1" className="arrowIcon arrow2">
                  <RightOutlined />
                </label>
              </label>
              <ProductLocaitonCard
                state={state}
                products={products}
                data={data}
                // formData={data}
                setFormData={formData}
              />
            </div>

      

            <div>
              <input
                type="radio"
                className="hidden"
                id="step-2"
                name="progress"
                defaultChecked={step2}
              />
              <label htmlFor="step-2" className="step">
                Privacy Policy
                <label htmlFor="step-2" className="arrowIcon arrow1">
                  <RightOutlined />
                </label>
              </label>
              <PrivacyPolicyCard
                formData={data}
                data={data}
                setFormData={formData}
                currency={props.currency}
              />
            </div>

            <div>
              <input
                type="radio"
                className="hidden"
                id="step-3"
                name="progress"
                defaultChecked={step3}
              />
              <label htmlFor="step-3" className="step">
                Customer Template
                <label htmlFor="step-3" className="arrowIcon arrow3">
                  <RightOutlined />
                </label>
              </label>
              <CustomerTemplate
                formData={data}
                data={data}
                setFormData={formData}
                currency={props.currency}
              />
            </div>

            <div>
              <input
                type="radio"
                className="hidden"
                id="step-4"
                name="progress"
                defaultChecked={step4}
              />
              <label htmlFor="step-4" className="step">
                Template
                <label htmlFor="step-4" className="arrowIcon arrow2">
                  <RightOutlined />
                </label>
              </label>
              <TemplateSelectionCard
                formData={data}
                setFormData={formData}
                state={state}
                products={products}
                currency={props.currency}
                campaignCode={props.campaignCode}
                brandLogo={props.brandLogo}
              />
            </div>
            <div>
              <input
                type="radio"
                className="hidden"
                id="step-5"
                name="progress"
                defaultChecked={step5}
              />
              <label htmlFor="step-5" className="step">
                Preview
                <label htmlFor="step-5" className="arrowIcon arrow4">
                  <RightOutlined />
                </label>
              </label>
              <Card className="campaignPreview showWhenChecked">
                <PreviewCampaign
                  formData={data}
                  state={state}
                  products={products}
                  currency={props.currency}
                />
                <Col xl={24} xs={24} className="buttonBox">
                  <Button
                    type="primary"
                    htmlType="submit"
                    size="large"
                    className="btnDraft 2 mb-2"
                    initialValue="saveDraft"
                    name="type"
                    onClick={() => saveDraft("saveDraft")}
                  >
                    Save as draft
                  </Button>
                  {data?.campaignName !== "" &&
                    data?.endDate !== "" &&
                    data?.startDate &&
                    data?.denomination !== "" &&
                    data?.noOfVoucher !== "" &&
                    data?.totalOfferValue !== "" &&
                    (data?.templateProducts.length > 0) &&
                    data.state.length > 0 && 
                    (data?.customerTemplate!=='' &&  (data?.customerTemplate?.urlTemplate || data?.customerTemplate?.base64Template)  ) &&
                    (data?.customImage!=="" || data?.templateId!=='' || data?.templateType===2)
                    ? (
                    <Button
                      type="primary"
                      htmlType="submit"
                      size="large"
                      className="ml-3"
                      initialValue="Publish"
                      name="type"
                      onClick={() => saveDraft("Publish")}
                    >
                      Publish to merchants
                    </Button>
                  ) : (
                    ""
                  )}
                </Col>
              </Card>
            </div>
          </Form>
        </Col>
      </Row>
    </>
  );
};
export { CampaignAdd };
