import { Form, Input, Button, message } from "antd";
import { useHistory, Link } from "react-router-dom";
import React, { useEffect } from "react";
import { ApiRoutes } from "../../config/ApiRoutes";
import { ApiHelper, getToken } from "../../Helpers";
const ForgetPassword = () => {
  const history = useHistory();
  useEffect(() => {
    if (getToken() !== "" && getToken() !== null) {
      history.push({
        pathname: `/dashboard`,
      });
    }
    // eslint-disable-next-line
  }, []);
  const onFinish = async (values) => {
    const response = await new ApiHelper().FetchFromServer(
      ApiRoutes.FORGET_PASSWORD.service,
      ApiRoutes.FORGET_PASSWORD.url,
      ApiRoutes.FORGET_PASSWORD.method,
      ApiRoutes.FORGET_PASSWORD.authenticate,
      undefined,
      {
        email: values.email,
        password: values.password,
      }
    );
    if (response.code === 200) {
      message.success({
        content: response.messages[0],
        duration: 2,
      });
      history.push({
        pathname: `/`,
      });
    } else {
      message.error({
        content: response.message || response.messages || response.error,
        duration: 2,
      });
    }
  };

  const onFinishFailed = (errorInfo) => {
  };

  return (
    <Form
      name="basic"
      style={{ marginTop: "10%" }}
      labelCol={{
        span: 8,
      }}
      wrapperCol={{
        span: 8,
      }}
      initialValues={{
        remember: true,
      }}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      autoComplete="off"
    >
      <Form.Item
        label="email"
        name="email"
        rules={[
          {
            required: true,
            message: "Please input your username/Email!",
          },
        ]}
      >
        <Input placeholder="Username or Email" />
      </Form.Item>

      <Form.Item
        wrapperCol={{
          offset: 8,
          span: 16,
        }}
      >
        <Button type="primary" htmlType="submit">
          Submit
        </Button><br />
        <Link to="/">Back to login</Link>
      </Form.Item>
    </Form>
  );
};
export default ForgetPassword;
