import React, { useEffect } from "react";
import { NavLink, useLocation } from "react-router-dom";
import { Menu } from "antd";
import {
  NotificationOutlined,
  LaptopOutlined
} from "@ant-design/icons";
import Logo from "../../../assets/images/Logo/KardStash.png";
import Logosmall from "../../../assets/images/Logo/black-small-icon.png";
import "./sidebar.less";

// const rootSubmenuKeys = ["sub1", "sub2", "sub3"];
const Sidebarlayout = () => {
  const location = useLocation();
  const [key, setKey] = React.useState("2");
  useEffect(() => {
    if(location.pathname.match(/dashboard/i)) {
      setKey('1');
    }
    if(location.pathname.match(/product/i)) {
      setKey('2');
    }
    if(location.pathname.match(/campaign/i)) {
      setKey('3');
    }
   },[location]);
  const onOpenChange = (keys) => {
    setKey(keys.key);
  };
  return (
    <>
      <div className="logo" type="flex" align="middle">
        <img
          className="logoBig"
          src={Logo}
          width={160}
          style={{
            display: "inline-flex",
            justifyContent: "center",
            alignItems: "center",
            padding: 15
          }}
          alt="Logo"
        />
        <img
          className="logoSmall"
          src={Logosmall}
          width={42}
          style={{
            display: "inline-flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%"
          }}
          alt="Logo"
        />
      </div>
      <Menu
        theme="light"
        mode="inline"
        onSelect={onOpenChange}
        defaultSelectedKeys={[key]}
        selectedKeys={key}
      >
         {/* <Menu.Item key="1" icon={<GlobalOutlined />} title="Dashboard">
          <NavLink to="/public">
            Public page{" "}
          </NavLink>
        </Menu.Item>  */}
        <Menu.Item key="3" icon={<NotificationOutlined />} title="Campaigns">
          <NavLink to="/campaigns">Campaigns </NavLink>
        </Menu.Item>
        <Menu.Item key="2" icon={<LaptopOutlined />} title="Products">
          <NavLink to="/products">Products </NavLink>
        </Menu.Item>
      </Menu>
    </>
  );
};

export default Sidebarlayout;
