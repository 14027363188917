import { Layout, Row, Col, Space } from "antd";
import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { DoubleLeftOutlined } from "@ant-design/icons";
import { PageTitle } from "../../../common/components/layout/";
import Productlist from "./Productlist";
import Storeinformation from "./Storeinformation";
import { ApiRoutes } from "../../../config/ApiRoutes";
import { ApiHelper } from "../../../Helpers";

import "./dashboard.less";

const { Content } = Layout;
const title = "Store Details";
const Storedetails = () => {
  const search = useLocation().search;
  const queryString = new URLSearchParams(search);
  const [storeData, setStoreData] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    getStoreDetails();
    // eslint-disable-next-line
  }, []);

  const getStoreDetails = async () => {
    const response = await new ApiHelper().FetchFromServer(
      ApiRoutes.STORE_DETAILS.service,
      ApiRoutes.STORE_DETAILS.url,
      ApiRoutes.STORE_DETAILS.method,
      ApiRoutes.STORE_DETAILS.authenticate,
      {
        storeId: queryString.get('storeId'),
        campaignId: queryString.get('campaignId')
      }
    );
    setLoading(false);
    if (response.code === 200) {
      if (response.data === undefined) {
        setStoreData(null);
      }
      else {
        setStoreData(response.data.data);
      }
    }
  };
  return (
    <>
      <Space className="single-filter-sec m-d-block headerFix">
        <PageTitle title={title} />        
      </Space>
      <Content
        className="content-body"
        style={{ minHeight: 200 }}
        id="Dashboard"
      >
        <Row>
          <Col lg={24} xs={24}>
          <div
              style={{
                margin: "0px 0px 14px 40px"
              }}
            >
            <Link to={`/campaign/dashboard/${queryString.get('campaignId')}`} className="ant-btn ant-btn-primary">
             {" "}
            <DoubleLeftOutlined /> Back
            </Link>
            </div>
          </Col>
          <Col lg={8} xs={24}>
            <div
              style={{
                margin: "0px 0px 14px 40px"
              }}
            >
              <Storeinformation storeData={storeData} loading={loading} />
            </div>
          </Col>
          <Col lg={16} xs={24}>
            <div
              style={{
                margin: "0px 40px 14px 40px"
              }}
            >
              <Productlist storeData={storeData} loading={loading} />
            </div>
          </Col>
        </Row>
      </Content>
    </>
  );
};
export default Storedetails;
