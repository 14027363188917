import {
  Alert,
  Button,
  Col,
  Layout,
  message,
  notification,
  Result,
  Row,
  Space,
  Spin,
} from "antd";
import React from "react";
import { PageTitle } from "../PageTitle";
const title = "All Loader & Message";
const { Content } = Layout;

const Allloader = () => {
  const [confirmLoading, setConfirmLoading] = React.useState(false);
  const [spinLoading, setSpinLoading] = React.useState(false);
  const showbuttonLoading = () => {
    setConfirmLoading(true);
    setTimeout(() => {
      setConfirmLoading(false);
    }, 2000);
  };
  const showspinLoader = () => {
    setSpinLoading(true);
    setTimeout(() => {
      setSpinLoading(false);
    }, 2000);
  };
  const success = () => {
    message.success("This is a success message");
  };

  const error = () => {
    message.error("This is an error message");
  };

  const warning = () => {
    message.warning("This is a warning message");
  };
  const info = () => {
    message.info("This is a info message");
  };

  const openNotificationWithIcon = (type) => {
    notification[type]({
      message: "Notification Title",
      description:
        "This is the content of the notification. This is the content of the notification. This is the content of the notification.",
    });
  };

  return (
    <>
      <PageTitle title={title} />
      <Spin spinning={spinLoading}>
        <Content className="content-body" style={{ minHeight: 500 }}>
          <Row>
            <Col lg={24} xs={24}>
              <div style={{ margin: "0px 14px 14px 14px" }}>
                <h3>Button Loading</h3>
                <Space direction="vertical">
                  <Button
                    type="primary"
                    loading={confirmLoading}
                    onClick={showbuttonLoading}
                  >
                    Click me! (Show Button Loading)
                  </Button>
                </Space>
              </div>
            </Col>
          </Row>
          <Row>
            <Col lg={24} xs={24}>
              <div style={{ margin: "0px 14px 14px 14px" }}>
                <h3>Process Loader</h3>
                <Space direction="vertical">
                  <Button type="warning" onClick={showspinLoader}>
                    Click me! (Show Loader)
                  </Button>
                </Space>
              </div>
            </Col>
          </Row>
          <Row>
            <Col lg={24} xs={24}>
              <div style={{ margin: "0px 14px 14px 14px" }}>
                <h3>Message</h3>
                <Space>
                  <Button ghost type="success" onClick={success}>
                    Success
                  </Button>
                  <Button ghost type="danger" onClick={error}>
                    Error
                  </Button>
                  <Button ghost type="warning" onClick={warning}>
                    Warning
                  </Button>
                  <Button ghost type="info" onClick={info}>
                    Info
                  </Button>
                </Space>
              </div>
            </Col>
          </Row>
          <Row>
            <Col lg={24} xs={24}>
              <div style={{ margin: "0px 14px 14px 14px" }}>
                <h3>Alert</h3>
                <Space direction="vertical" style={{ width: "100%" }}>
                  <Alert
                    message="Success"
                    banner
                    type="success"
                    showIcon
                    closable
                  />
                  <Alert message="Info" banner type="info" showIcon closable />
                  <Alert
                    message="Warning"
                    banner
                    type="warning"
                    showIcon
                    closable
                  />
                  <Alert
                    message="Error"
                    banner
                    type="error"
                    showIcon
                    closable
                  />
                </Space>
              </div>
            </Col>
          </Row>
          <Row>
            <Col lg={24} xs={24}>
              <div style={{ margin: "0px 14px 14px 14px" }}>
                <h3>Notification</h3>
                <Space>
                  <Button
                    type="success"
                    onClick={() => openNotificationWithIcon("success")}
                  >
                    Success
                  </Button>
                  <Button
                    type="info"
                    onClick={() => openNotificationWithIcon("info")}
                  >
                    Info
                  </Button>
                  <Button
                    type="warning"
                    onClick={() => openNotificationWithIcon("warning")}
                  >
                    Warning
                  </Button>
                  <Button
                    type="danger"
                    onClick={() => openNotificationWithIcon("error")}
                  >
                    Error
                  </Button>
                </Space>
              </div>
            </Col>
          </Row>
          <Row>
            <Col lg={24} xs={24}>
              <div style={{ margin: "0px 14px 14px 14px" }}>
                <h3>404</h3>
                <Result
                  status="404"
                  title="404"
                  subTitle="Sorry, the page you visited does not exist."
                  extra={<Button type="primary">Back Home</Button>}
                />
              </div>
            </Col>
          </Row>
        </Content>
      </Spin>
    </>
  );
};
export { Allloader };
