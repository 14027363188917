import React from "react";
import { Row, Col, Form, Input, Card } from "antd";
import { RightOutlined } from "@ant-design/icons";
const { TextArea } = Input;

const PrivacyPolicyCard = (props) => {
  const changeValue = (e, key) => {
    props.setFormData({ [key]: e.target.value });
  };
  return (
    <>
      <Card className="campaignInformationCard showWhenChecked" hoverable>
        <Row gutter="24">
          <Col xl={8} xs={24}>
            <Form.Item
              label="Privacy Policy"
              name="privacyPolicy"
              initialValue={props?.data?.privacyPolicy}
              onChange={(e) => changeValue(e, "privacyPolicy")}
              rules={[
                {
                  required: true,
                  message: "Please input your privacy policy"
                }
              ]}
            >
              <TextArea rows={8}/>
            </Form.Item>
          </Col>
          <Col xl={8} xs={24}>
            <Form.Item
              label="Website to Brand"
              name="terms"
              initialValue={props?.data?.terms}
              onChange={(e) => changeValue(e, "terms")}
              rules={[
                {
                  required: true,
                  message: "Please input your terms"
                }
              ]}
            >
              <TextArea rows={8}/>
            </Form.Item>
          </Col>
          <Col xl={8} xs={24}>
            <Form.Item
              label="Disclaimer"
              name="disclaimer"
              initialValue={props?.data?.disclaimer}
              onChange={(e) => changeValue(e, "disclaimer")}
              rules={[
                {
                  required: true,
                  message: "Please input your campaign disclaimer"
                }
              ]}
            >
              <TextArea rows={8}/>
            </Form.Item>
          </Col>
          <Col xl={24} xs={24}>
            <label
              htmlFor="step-3"
              onClick="onclickNext('step-3')"
              className="enable-when-valid btnNext ant-btn ant-btn-primary"
            >
              Next <RightOutlined />
            </label>
          </Col>
        </Row>
      </Card>
    </>
  );
};
export { PrivacyPolicyCard };
