import React from "react";
import { Empty } from 'antd';
import noData from '../../assets/images/noDataFound.png'
const EmptyComponents = (props) => {
  return (
    <>
    <Empty
    image={noData}
    imageStyle={{
      height: 120,
    }}
    style={{display: 'flex',flexDirection: 'column',justifyContent: 'center',alignItems: 'center',width: '100%',margin: '0px auto',padding: '32px 0', borderRadius: '10px', backgroundColor:'#fff'}}
    description={
      <span>
        {/* {props.title} */} 
        {props.message}
      </span>
    }
  >
  </Empty>
      {/* <h1>{props.title}</h1>
      <div>{props.message}</div> */}
    </>
  );
};

export { EmptyComponents };
