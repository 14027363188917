import React, { useState, useEffect } from "react";
import { useParams, Link } from "react-router-dom";
import { Layout, message, Row, Col, Button } from "antd";
import { ApiRoutes } from "../../config/ApiRoutes";
import {
  ApiHelper,
  abbreviateNumber,
  getGiftCardTokenData,
  setGiftCardTokenData
} from "../../Helpers";
import Barcode from "react-barcode";
import { Loader } from "../../common/components/Loader";
import { WarningOutlined } from "@ant-design/icons";
import moment from "moment";
import MobileDetect from "mobile-detect";
import { AppConfig } from "../../config/AppConfig";
import "./customer.less";
import "../../modules/public/public.less";

// const { Content } = Layout;
const SaveToPhone = () => {
  const { campaignCode } = useParams();
  const [loading, setLoading] = useState(true);
  const [loadingButton, setLoadingButton] = useState(false);
  const [campaignData, setCampaignData] = useState(null);
  const [osType, setOsType] = useState("");
  const [showMore, setShowMore] = useState(false);
  const [showTermMore, setShowTermMore] = useState(false);
  const [showDisclaimerMore, setDisclaimerShowMore] = useState(false);
  useEffect(() => {
    let type = new MobileDetect(window.navigator.userAgent);
    //alert(type.os()+'=========='+type.tablet())
    setOsType(type.os()===null ? type.tablet()!==null && type.tablet()==='iPad' ? 'iOS' :type.os() : type.os());
    // getBrandDetails();
    // fetch("https://ipapi.co/json")
    fetch("https://api64.ipify.org?format=json")
      .then((res) => res.json())
      .then((json) => {
        getBrandDetails(json.ip);
      });
    // eslint-disable-next-line
  }, []);

  const getBrandDetails = async (ip) => {
    setLoading(true);
    let existData = getGiftCardTokenData(campaignCode);
    const response = await new ApiHelper().FetchFromServer(
      ApiRoutes.STORE_CAMPAIGN_DETAIL.service,
      ApiRoutes.STORE_CAMPAIGN_DETAIL.url,
      ApiRoutes.STORE_CAMPAIGN_DETAIL.method,
      ApiRoutes.STORE_CAMPAIGN_DETAIL.authenticate,
      {
        id: campaignCode,
        type: "voucher",
        ip,
        voucherHistoryId: existData ? existData.voucherHistoryId : ""
      }
    );
    setLoading(false);

    if (response.code === 200) {
      if (response.data === undefined) {
        setCampaignData(null);
      } else {
        if (
          response.data.campaign.Cstatus === "1" ||
          response.data.campaign.Cstatus === 1
        ) {
          message.error({
            content: "Campaign is not yet Published. Please try later.",
            duration: 2
          });
          window.location.href = window.location.origin;
        } else {
          setGiftCardTokenData({
            campaignCode: campaignCode,
            voucherHistoryId: response.data.voucherData.voucherHistoryId
          });
          setCampaignData(response.data);
        }
      }
    } else {
      message.error({
        content: response.messages || response.error,
        duration: 2
      });
      window.location.href = window.location.origin;
    }
  };

  const handleSaveCoupon = async (type) => {
    let existData = getGiftCardTokenData(campaignCode);
    if (type === "gpay") {
      setLoadingButton(true);
      const response = await new ApiHelper().FetchFromServer(
        ApiRoutes.GIFT_CARD_SAVE_GPAY.service,
        ApiRoutes.GIFT_CARD_SAVE_GPAY.url,
        ApiRoutes.GIFT_CARD_SAVE_GPAY.method,
        ApiRoutes.GIFT_CARD_SAVE_GPAY.authenticate,
        {
          campaignCode: campaignCode,
          voucherHistoryId: existData ? existData.voucherHistoryId : ""
        }
      );
      if (response.code === 200) {
        window.location.href = response.data.saveUrl;
        setLoadingButton(false);
      } else {
        setLoadingButton(false);
      }
    } else {
      window.location.href = `${AppConfig.API_ENDPOINT}/brand/customer/save-apple-wallet`;
    }
  };

  const handleSaveCouponPDF = async () => {
    window.location.href = `${process.env.REACT_APP_DEV_URL}/store/campaign/customer-voucher-pdf?pinNumber=${campaignData.voucherData.pinNumber}`;
  };

  return (
    <div id="mobile_body">
      <Layout id="mobile_page">
        {loading ? (
          <section
            className="text-center"
            style={{
              height: "80vh",
              display: "flex",
              justifyContent: "center",
              alignItems: "center"
            }}
          >
            <Loader />
          </section>
        ) : (
          <Row className="campaignDetailSec">
            {campaignData ? (
              moment(moment().format("YYYY-MM-DD")).isAfter(
                moment(campaignData.campaign.CEndDate).format("YYYY-MM-DD")
              ) ? (
                <Col xs={24} xl={24} style={{ minHeight: "500px" }}>
                  <div class="dialog">
                    <WarningOutlined />
                    <br />
                    <div class="h1">Oops! That campaign has expired.</div>
                    <div class="body">
                      The page you are looking for is no longer available.
                    </div>
                  </div>
                </Col>
              ) : (
                <>
                  <Col xs={24} xl={24} className="mheader"></Col>
                  <Col xs={24} xl={24} className="cLogo">
                    <img src={campaignData?.campaign?.logo} alt="logo" />
                    <div className="offerSec">
                      <p className="offerHeading">Offer</p>
                      <h1 className="mb-0">
                        {campaignData?.brandDetails.currencySign}
                        {abbreviateNumber(
                          campaignData?.campaign.Denomination
                        )}{" "}
                        OFF
                      </h1>
                      <h2 className="mb-0">ON YOUR PURCHASE</h2>
                    </div>
                  </Col>
                  <Col xs={24} xl={24}>
                    <div className="text-center">
                      <h3 className="mb-1 campaignCodeSec">
                        Campaign Code:{" "}
                        {campaignData ? campaignData.campaign.CampaignCode : ""}
                      </h3>
                      <p className="expireText mb-1">
                        Offer Valid{" "}
                        {campaignData
                          ? moment(campaignData.campaign.CEndDate).format(
                              "MMMM DD, YYYY"
                            )
                          : ""}
                      </p>
                      {/* <p><strong>MAC Address</strong><br />{campaignData.voucherData.macAddress}</p> */}

                      {campaignData && campaignData.voucherData.pinNumber ? (
                        <h2>
                          <Button
                            type="primary"
                            className="btnBlue"
                            onClick={() =>
                              handleSaveCoupon(
                                osType === "iOS" ? "apple" : "gpay"
                              )
                            }
                            loading={loadingButton}
                          >
                            {osType === "iOS"
                              ? "Save to Apple Wallet"
                              : "Save to G Pay"}
                          </Button>
                        </h2>
                      ) : (
                        ""
                      )}
                      <br />

                      <div className="barcodeBox mb-5">
                        <h3 className="mt-3 mb-0">
                          Redeem in store | Gift Card
                        </h3>
                        {campaignData && campaignData.voucherData.pinNumber ? (
                          <Barcode
                            value={
                              campaignData
                                ? campaignData.voucherData.pinNumber
                                : ""
                            }
                          />
                        ) : (
                          <p>No Gift Card Available</p>
                        )}
                      </div>
                      {campaignData && campaignData.voucherData.pinNumber ? (
                        <Link
                          to="#"
                          onClick={handleSaveCouponPDF}
                          className="mr-3"
                        >
                          <Button type="primary" className="btnOrange">
                            Save as PDF
                          </Button>
                        </Link>
                      ) : (
                        ""
                      )}
                      <Link to={`/c/${campaignCode}/find-store`}>
                        <Button type="primary">Find Store(s)</Button>
                      </Link>
                    </div>
                  </Col>
                  <Col xs={24} xl={24} className="campaign-condition">
                    <p>
                      {campaignData && campaignData?.campaign?.privacyPolicy ? (
                        <>
                          {" "}
                          <h3>Privacy Policy</h3>
                          {showMore || campaignData.campaign.privacyPolicy.length < 250
                            ? <p style={{ whiteSpace: "pre-line" }}>{campaignData.campaign.privacyPolicy}</p>
                            : `${campaignData.campaign.privacyPolicy.substring(
                                0,
                                250
                              )}`}{" "}
                          {campaignData.campaign.privacyPolicy.length > 250 ? (
                            <>
                              <span
                                className="readMoreText"
                                onClick={() => setShowMore(!showMore)}
                              >
                                {showMore ? "Show less" : "Show more"}
                              </span>
                            </>
                          ) : (
                            ""
                          )}
                        </>
                      ) : (
                        ""
                      )}
                    </p>
                    <p>
                      {campaignData && campaignData?.campaign?.terms ? (
                        <>
                          <h3>Website to Brand</h3>{" "}
                          {showTermMore || campaignData.campaign.terms.length < 250
                            ? <p style={{ whiteSpace: "pre-line" }}>{campaignData.campaign.terms}</p> 
                            : `${campaignData.campaign.terms.substring(
                                0,
                                250
                              )}`}{" "}
                          {campaignData.campaign.terms.length > 250 ? (
                            <>
                              <span
                                className="readMoreText"
                                onClick={() => setShowTermMore(!showTermMore)}
                              >
                                {showTermMore ? "Show less" : "Show more"}
                              </span>
                            </>
                          ) : (
                            ""
                          )}
                        </>
                      ) : (
                        ""
                      )}
                    </p>
                    <p>
                      {campaignData && campaignData?.campaign?.disclaimer ? (
                        <>
                          <h3>Disclaimer</h3>{" "}
                          {showDisclaimerMore || campaignData.campaign.disclaimer.length < 250
                            ? <p style={{ whiteSpace: "pre-line" }}>{campaignData.campaign.disclaimer}</p>
                            : `${campaignData.campaign.disclaimer.substring(
                                0,
                                250
                              )}`}{" "}
                          {campaignData.campaign.disclaimer.length > 250 ? (
                            <>
                              <span
                                className="readMoreText"
                                onClick={() =>
                                  setDisclaimerShowMore(!showDisclaimerMore)
                                }
                              >
                                {showDisclaimerMore ? "Show less" : "Show more"}
                              </span>
                            </>
                          ) : (
                            ""
                          )}
                        </>
                      ) : (
                        ""
                      )}
                    </p>
                  </Col>
                </>
              )
            ) : (
              ""
            )}
          </Row>
        )}
      </Layout>
    </div>
  );
};
export default SaveToPhone;
