
import React, { useEffect} from "react";
import {useLocation } from "react-router-dom";
import { Success } from "../../../modules/join/Success";
import "../../../modules/join/join.less";

const JoinCampaignsSuccess = () => {
  const search = useLocation().search;
  const status = new URLSearchParams(search).get('u');
 
  useEffect(() => {
    const div=document.getElementById('wrapper');
    if(div && div.classList.contains("overflow-hidden")){
      div.classList.remove('overflow-hidden')
    }
    // eslint-disable-next-line
  }, []);
  return (
    <div id="Join"><Success status={status} /> </div>
  );
};
export default JoinCampaignsSuccess;
