import { Row, Col, Card } from "antd";
import React, { useState } from "react";
import {
  RightOutlined,
  CloudUploadOutlined,
  CalendarOutlined
} from "@ant-design/icons";
import moment from "moment";
import { EmptyComponents } from "../../common/components/";
import { abbreviateNumber } from "../../Helpers";

import Template1 from "../../assets/images/Template/template.jpeg";
import Qr from "../../assets/images/Template/qr-code.png";
const dateFormat = "YYYY/MM/DD";
const templateData = [{ key: 1, templateImage: Template1 }];

const TemplateSelectionCard = (props) => {
  const { currency, campaignCode, brandLogo } = props;

  const [showCustom, setShowCustom] = useState(props.formData.templateType);
  const [image, setImage] = useState({
    preview: props?.formData?.customImage || "",
    raw: props?.formData?.customImage || ""
  });
  const [data, setData] = React.useState(props.formData);
  const [products, setProducts] = React.useState(props.products);

  React.useEffect(() => {
    setData(props.formData);
    setProducts(props.products);
  }, [props.formData, props.products]);

  const handleChange = (e) => {
    if (e.target.files.length) {
      setImage({
        preview: URL.createObjectURL(e.target.files[0]),
        raw: e.target.files[0]
      });
      let reader = new FileReader();
      let file = e.target.files[0];
      reader.onloadend = () => {
        props.setFormData({ customImage: reader.result, templateId: "" });
      };
      reader.readAsDataURL(file);
      e.target.value='';
    }
  };
  const customFileUpload = (status) => {
    props.setFormData({ "templateType": status });
    setShowCustom(status);
  };
  return (
    <>
      <Card className="templateSelectionCard showWhenChecked" hoverable>
        <Row gutter="48">
          <Col xl={6} xs={24}>
            <label className="heading">Select Template</label>
            <div
              className="templateSelect scrollbar m-p-0"
              style={{ padding: 18, borderRadius: 6 }}
            >
              {/* Start Upload custom template Box */}
              <Row gutter={12}>
                <Col lg={24} xs={24} onClick={() => customFileUpload(1)}>
                  <label htmlFor="custom">
                    <input
                      type="radio"
                      name="test"
                      id="custom"
                      value="custom"
                      checked={showCustom === 1 ? true : false}
                    />
                    <div className="customTemplate">
                      <p>
                        <CloudUploadOutlined style={{ fontSize: "3rem" }} />{" "}
                        <br />
                        Upload custom <br />
                        template
                      </p>
                    </div>
                  </label>
                </Col>
              </Row>
              {/* End Upload custom template Box */}

              <Row gutter={12}>
                {templateData.map((templatepredata) => (
                  <Col
                    lg={24}
                    xs={24}
                    key={templatepredata.key}
                    onClick={() => customFileUpload(2)}
                  >
                    <label htmlFor={templatepredata.key}>
                      <input
                        type="radio"
                        name="test"
                        id={templatepredata.key}
                        value={templatepredata.key}
                        checked={showCustom === 2 ? true : false}
                      />
                      <img src={templatepredata.templateImage} alt="template" />
                    </label>
                  </Col>
                ))}
              </Row>
            </div>
          </Col>
          <Col xl={18} xs={24}>
            {/* Start Select or Upload Template Box */}
            {showCustom === 1 ? (
              <>
                <div
                  className="customTemplateBox"
                  style={{
                    position: "relative",
                    padding: 18
                  }}
                >
                  <div className="innerBox">
                    <label
                      htmlFor="upload-button"
                      style={{ width: "300px", height: "300px" }}
                    >
                      {image.preview ? (
                        <img
                          src={image.preview}
                          alt="dummy"
                          style={{ width: "100%" }}
                        />
                      ) : (
                        <>
                          <div className="ant-upload-select-picture-card">
                            <CloudUploadOutlined />
                            <h5 className="text-center">
                              Upload custom template
                            </h5>
                          </div>
                        </>
                      )}
                    </label>
                    <input
                      type="file"
                      id="upload-button"
                      style={{ display: "none" }}
                      onChange={handleChange}
                    />
                    <br />
                  </div>
                </div>
                <div className="noteBox">
                  <h4>Please Notes:</h4>
                  <ul>
                    <li>
                      Kindly make sure in your custom templates all product
                      details like Name, UPC, Image are correct
                    </li>
                    <li>Use the correct campaign codes and qr code</li>
                    <li>
                      Verify campaign details like Name, start date, end date,
                      denomination, no. of vouchers, validity in states/city
                    </li>
                  </ul>
                </div>{" "}
              </>
            ) : (
              <div className="brandTemplateSec">
                <header>
                  <img src={brandLogo} alt="logo" />
                </header>
                <main>
                  <section className="campaignNameSec">
                    <h1>{data.campaignName || "Campaign Name"}</h1>
                    <span>Campaign code {campaignCode || "-"}</span>
                  </section>
                  <section className="campaignDateOffSec">
                    <div className="dateSec">
                      <div>
                        <p className="icon">
                          <CalendarOutlined />
                        </p>
                        <p>
                          <span className="dateHeading">Start Date</span> <br />
                          <span className="dateValue">
                            {data.startDate
                              ? moment(data.startDate).format(dateFormat)
                              : "-"}
                          </span>
                        </p>
                      </div>
                      <div>
                        <p className="icon">
                          <CalendarOutlined />
                        </p>
                        <p>
                          <span className="dateHeading">End Date</span> <br />
                          <span className="dateValue">
                            {data.endDate
                              ? moment(data.endDate).format(dateFormat)
                              : "-"}
                          </span>
                        </p>
                      </div>
                    </div>
                    <div className="offSec">
                      <span>
                        {currency}
                        {data.denomination
                          ? abbreviateNumber(data.denomination)
                          : 0} Off
                      </span>
                    </div>
                  </section>
                  <section className="campaignStateQRSec">
                    <div className="stateSec">
                      <h4>State</h4>
                      <div className="inner">
                      {data.state.length > 0 ? (
                        props.state &&
                        props.state.map((stateDataPre, key) => {
                          return data.state.includes(stateDataPre.stateId) ? (
                            <span key={key}>{stateDataPre.stateName}</span> 
                          ) : (
                            ""
                          );
                        })
                      ) : (
                        <EmptyComponents
                          title="State"
                          message="No State Selected"
                        />
                      )}
                      </div>
                    </div>
                    <div className="qrSec">
                      <div>
                        <img src={Qr} alt="qr" />
                      </div>
                    </div>
                  </section>
                  <section className="campaignProductSec">
                    {data.templateProducts.length > 0 ? (
                      products &&
                      products.map((product, key) => {
                        return data.templateProducts.includes(
                          product.organizationProductId
                        ) ? (
                          <div className="column" key={key}>
                            <img
                              src={`${product.imageName}`}
                              alt={product.productCodeOveride}
                            />
                            <div className="inner">
                              <p className="text-ellipse1" title={product.productCodeOveride}> {product.productCodeOveride}</p>
                              <p>
                                UPC | <span>{product.upc}</span>
                              </p>
                            </div>
                          </div>
                        ) : (
                          ""
                        );
                      })
                    ) : (
                      <EmptyComponents title="" message="No Product Selected" />
                    )}
                  </section>
                </main>
              </div>
            )}
            {/* End Preview Template Box */}

            {/* Start Custom Template Upload Box */}
          </Col>
        </Row>
        <Row>
          <Col xl={24} xs={24}>
            <label
              htmlFor="step-5"
              onClick="onclickNext('step-5')"
              className="enable-when-valid btnNext ant-btn ant-btn-primary"
            >
              Next <RightOutlined />
            </label>
            {/* <button type="submit" className="enable-when-valid" onClick="onclickNext('step-2')">
                            Submit
                            </button> */}
          </Col>
        </Row>
      </Card>
    </>
  );
};
export { TemplateSelectionCard };
