import { EyeOutlined } from "@ant-design/icons";
import { Row, Col, Tooltip, Card, Spin } from "antd";
import { InfinityTable as Table } from "antd-table-infinity";
import React from "react";
import { Link } from "react-router-dom";
import {
  getCurrency,
  abbreviateNumber,
  getUserData,
  timeZone
} from "../../Helpers";
import moment from "moment";

const CampaignList = (props) => {
  const Currency = getCurrency();
  const timezone = getUserData().timezone;
  const columns = [
    {
      title: "Campaign Name",
      dataIndex: "CampaignName"
    },
    {
      title: "Created Date",
      dataIndex: "createDt",
      render: (date) => (
        <div className="createdBox">
          <p className="mb-0">
            {moment(timeZone(date, timezone)).format("DD MMM yyyy")}
          </p>
          <span>
            {" "}
            {moment(timeZone(date, timezone)).format("HH:mm:ss")}{" "}
          </span>
        </div>
      )
    },
    {
      title: "Start Date",
      dataIndex: "CStartDate",
      render: (date) => (
        <div className="stateDateBox">
          {moment(date).format("DD MMM yyyy")}{" "}
        </div>
      )
    },
    {
      title: "End Date",
      dataIndex: "CEndDate",
      render: (date) => (
        <div className="endDateBox">{moment(date).format("DD MMM yyyy")} </div>
      )
    },
    {
      title: "Total Offer Value",
      dataIndex: "TotalOffer",
      render: (totalOfferValue) => (
        <span className="totalOfferValueText">
          <Tooltip title={`${Currency} ${totalOfferValue}`}>
            {Currency} {abbreviateNumber(totalOfferValue)}
          </Tooltip>
        </span>
      )
    },
    {
      title: "Status",
      dataIndex: "Cstatus",
      render: (Cstatus, data) => {
        let status = "Draft";
        if ( moment().format("YYYY-MM-DD")> data.CEndDate) status = "Expired";
        else if (parseInt(Cstatus) === 2) status = "Published";
        else if (parseInt(Cstatus) === 3) status = "Active";
        else if (parseInt(Cstatus) === 4) status = "Expired";
        else status = "Draft";
        return <span className={`statusBox ${status}`}>{status}</span>;
      }
    },
    {
      title: "View",
      dataIndex: "CampaignId",
      render: (CampaignId) => (
        <span className="viewBtn">
          <Tooltip title="View">
            <Link to={`campaign/details/${CampaignId}`}>
              <EyeOutlined />
            </Link>
          </Tooltip>
        </span>
      ),
      width: "60px"
    }
  ];

  const loadMoreContent = () => (
    <>
      <div
        style={{
          textAlign: "center",
          paddingTop: 40,
          paddingBottom: 40,
          border: "1px solid #e8e8e8"
        }}
      >
        <Spin tip="Loading..." />
      </div>
    </>
  );
  return (
    <>
      <Card className="campaignListCard">
        {/* style={{ height: "800px" }} */}
        <Row gutter="24">
          <Col xl={24} xs={24}>
            <div className="campaignBox">
              {/* <Table
                columns={columns}
                dataSource={props.data}
                pagination={false}
               scroll={{ y: 450 }}
              /> */}
              <Table
                rowKey="CampaignId" // Important, otherwise scrolling page will be a problem
                loading={props.loading}
                onFetch={props.getCampaignList}
                pageSize={100}
                loadingIndicator={loadMoreContent}
                columns={columns}
                scroll={{ y: 750 }}
                dataSource={props.data}
                // bordered
                // debug
              />
            </div>
          </Col>
        </Row>
      </Card>
    </>
  );
};

export { CampaignList };
