import { Row, Col, Input, Card, Checkbox } from "antd";
import React, { useState } from "react";
import { SearchOutlined, RightOutlined } from "@ant-design/icons";

const ProductLocaitonCard = (props) => {
  //const { state } = props;
  const [state, setState] = React.useState(props.state);
  const [products, setProducts] = React.useState(props.products);
  const [checkAll, setCheckAll] = React.useState(false);
  const [checkProductAll, setCheckProductAll] = React.useState(false);
  const [productSelected, setProductSelected] = useState(props?.data?.templateProducts || []);
  const [stateSelected, setStateSelected] = useState(props?.data?.state ||[]);
  const [disabledState,setDisabledState] = useState((props?.data?.Cstatus == "2" || props?.data?.Cstatus == "3" || props?.data?.Cstatus == "4" )?props?.data?.state:[]);

  React.useEffect(() => {
    setProducts(props.products);
    setState(props.state);
  }, [props.products,props.state]);

  const selectAllProduct = (e) => {
    let temp = "";
    if (e.target.checked) {
      setCheckProductAll(true);
      temp = products.map((product) => product.organizationProductId);
    } else {
      setCheckProductAll(false);
      temp = [];
    }
    setProductSelected(temp);
    props.setFormData({ templateProducts: temp });
  };
  const singleProductSelect = (e) => {
    const temp = [...productSelected];
    if (e.target.checked) temp.push(e.target.value);
    else {
      const index = temp.indexOf(e.target.value);
      if (index > -1) {
        temp.splice(index, 1);
      }
    }
    setProductSelected(temp);
    props.setFormData({ templateProducts: temp });
  };
  const productFilter = (e) => {
    const search = e.target.value;
    let tempProduct = "";
    const temp = products.filter((product) => {
      if (
        product.productCodeOveride.toLowerCase().includes(search.toLowerCase()) ||
        product.upc === e.target.value
      ) {
        return product;
      }else{
        return false;
      }
    });
    if (search === "") tempProduct = props.products;
    else tempProduct = temp;
    setProducts(tempProduct);
    // props.setFormData({"products":tempProduct});
  };
  const stateFilter = (e) => {
    const search = e.target.value;
    let tempState = "";
    const temp = state.filter((s) => {
      if (
        s.stateName.toLowerCase().includes(search.toLowerCase()) 
      ) {
        return s;
      }else{
        return false;
      }
    });
    if (search === "") tempState = props.state;
    else tempState = temp;
    setState(tempState);
    // props.setFormData({"products":tempProduct});
  };

  
  const singleStateSelect = (e) => {
    const temp = [...stateSelected];
    if (e.target.checked) temp.push(e.target.value);
    else {
      const index = temp.indexOf(e.target.value);
      if (index > -1) {
        temp.splice(index, 1);
      }
    }
    setStateSelected(temp);
    props.setFormData({ state: temp });
  };
  const onCheckAllChange = (e) => {
    let tempState = "";
    if (e.target.checked) {
      setCheckAll(true);
      tempState = state.map((s) => s.stateId);
    } else {
      setCheckAll(false);
      tempState = [...disabledState];
    }
    setStateSelected(tempState);
    props.setFormData({ state: tempState });
  };
  return (
    <>
      <Card className="productLocationCard showWhenChecked" hoverable>
        <Row gutter="48">
          <Col xl={12} xs={24}>
            <label className="heading">Select Products</label>
            <Input
              size="large"
              placeholder="Search Product..."
              prefix={<SearchOutlined />}
              className="searchBox mb-5"
              onKeyUp={productFilter}
              onKeyDown={(e)=> e.keyCode === 13 ? e.preventDefault(): ''}
            />
            <div className="productBox">
              <div className="ant-table">
                <table>
                  <thead className="ant-table-thead">
                    <tr>
                      <th  width="9%" className="ant-table-cell ant-table-selection-column">
                        <Checkbox
                          onChange={selectAllProduct}
                          checked={checkProductAll}
                        ></Checkbox>
                      </th>
                      <th width="17%" className="ant-table-cell">Image</th>
                      <th width="50%" className="ant-table-cell">Product Name</th>
                      <th   className="ant-table-cell ant-table-cell-scrollbar"></th>
                    </tr>
                  </thead>
                </table>
                <div
                  className="ant-table-body"
                  style={{ overflowY: "scroll", maxHeight: "250px" }}
                >
                  <table>
                    <tbody className="ant-table-tbody">
                      {products &&
                        products.map((product, index) => {
                          return (
                            <tr
                              className="ant-table-row ant-table-row-level-0"
                              key={index}
                            >
                              <td className="ant-table-cell ant-table-selection-column">
                                <Checkbox
                                  onChange={singleProductSelect}
                                  value={product.organizationProductId}
                                  checked={productSelected.includes(
                                    product.organizationProductId
                                  )}
                                ></Checkbox>
                              </td>
                              <td className="ant-table-cell">
                                <img
                                  src={`${product.imageName}`}
                                  alt="icon"
                                />
                              </td>
                              <td className="ant-table-cell">
                                <span className="productNameValue">
                                  {product.productCodeOveride}
                                </span>
                                <p className="upcText mb-0">
                                  UPC | {product.upc}
                                </p>
                              </td>
                            </tr>
                          );
                        })}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </Col>
          <Col xl={12} xs={24}>
            <div className="d-flex justify-content-between">
              <label className="heading">Select State</label>
              <Checkbox onChange={onCheckAllChange} checked={checkAll}>
                Select all
              </Checkbox>
            </div>
            <Input
              size="large"
              placeholder="Search State..."
              prefix={<SearchOutlined />}
              className="searchBox mb-5"
              onKeyUp={stateFilter}
              onKeyDown={(e)=> e.keyCode === 13 ? e.preventDefault(): ''}
            />

            <div className="locationBox">
              <div className="innerBox scrollbar">
                <div className="ant-checkbox-group">
                  {state &&
                    state.map((s, index) => {
                      return (
                        <Checkbox
                          onChange={singleStateSelect}
                          value={s.stateId}
                          key={index}
                          checked={stateSelected.includes(s.stateId)}
                          disabled={disabledState.includes(s.stateId)}
                        >
                          {s.stateName}
                        </Checkbox>
                      );
                    })}
                </div>
              </div>
            </div>
          </Col>
        </Row>
        <Row>
          <Col xl={24} xs={24}>
            <label
              htmlFor="step-2"
              className="enable-when-valid btnNext ant-btn ant-btn-primary"
              onClick="onclickNext('step-2')"
            >
              Next <RightOutlined />
            </label>
          </Col>
        </Row>
      </Card>
    </>
  );
};
export { ProductLocaitonCard };
