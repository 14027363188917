import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Layout, message, Row, Col, Button, Select } from "antd";
import { ApiRoutes } from "../../config/ApiRoutes";
import { ApiHelper, abbreviateNumber } from "../../Helpers";
import { Loader } from "../../common/components/Loader";
import { SearchOutlined, AimOutlined, EnvironmentOutlined, HeatMapOutlined } from "@ant-design/icons"
import Autocomplete from "react-google-autocomplete";
import "../../modules/public/public.less";
import Geocode from "react-geocode";
Geocode.setApiKey(process.env.REACT_APP_MAP_KEY);
const { Option } = Select;


const FindStore = () => {
  const { campaignCode } = useParams();
  const [loading, setLoading] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [isFirstLoaded, setIsFirstLoaded] = useState(false);
  const [campaignData, setCampaignData] = useState(null);
  const [location, setLocation] = useState('');
  const [radius, setRadius] = useState(5);
  const [storeData, setStoreData] = useState([]);

  useEffect(() => {
    getBrandDetails();
    // eslint-disable-next-line
  }, []);

  const getBrandDetails = async () => {
    setLoading(true);
    const response = await new ApiHelper().FetchFromServer(
      ApiRoutes.STORE_CAMPAIGN_DETAIL.service,
      ApiRoutes.STORE_CAMPAIGN_DETAIL.url,
      ApiRoutes.STORE_CAMPAIGN_DETAIL.method,
      ApiRoutes.STORE_CAMPAIGN_DETAIL.authenticate,
      {
        id: campaignCode,
        type: 'voucher'
      }
    );
    setLoading(false);

    if (response.code === 200) {
      if (response.data === undefined) {
        setCampaignData(null);
      } else {
        if (response.data.campaign.Cstatus === "1" || response.data.campaign.Cstatus === 1) {
          message.error({
            content: "Campaign is not yet Published. Please try later.",
            duration: 2
          });
          window.location.href = window.location.origin;
        } else {
          setCampaignData(response.data);
        }
      }
    } else {
      message.error({
        content: response.messages || response.error,
        duration: 2
      });
      window.location.href = window.location.origin;
    }
  };

  const findStore = async () => {
    if (location === "") {
      message.error({
        content: "Location is required",
        duration: 2
      });
      return;
    }
    setStoreData([]);
    setIsLoading(true);
    setIsFirstLoaded(true);
    const response = await new ApiHelper().FetchFromServer(
      ApiRoutes.FIND_STORE_LOCATION.service,
      ApiRoutes.FIND_STORE_LOCATION.url,
      ApiRoutes.FIND_STORE_LOCATION.method,
      ApiRoutes.FIND_STORE_LOCATION.authenticate,
      { location, radius, campaignId: campaignData.campaign.CampaignId }
    );
    setIsLoading(false);

    if (response.code === 200) {
      setStoreData(response.data.data);
    } else {
      setStoreData([]);
    }
  };

  const findCurrentLocation = async () => {
    navigator.geolocation.getCurrentPosition(function (position) {
      Geocode.fromLatLng(position.coords.latitude, position.coords.longitude).then(
        (response) => {
          const address = response.results[0].formatted_address;
          setLocation(address);
        },
        (error) => {
          setLocation("");
          message.error({
            content: error,
            duration: 2
          });
        }
      );
    });
  }


  return (
    <>
      <div id="mobile_body">
        <Layout id="mobile_page" >
          {loading ? (
            <section
              className="text-center"
              style={{
                height: "80vh",
                display: "flex",
                justifyContent: "center",
                alignItems: "center"
              }}
            >
              <Loader />
            </section>
          ) :
            <Row className="campaignDetailSec">
              <Col xs={24} xl={24} className="mheader">
              </Col>
              <Col xs={24} xl={24} className="cLogo">

                <img src={campaignData?.campaign?.logo} alt="logo" />
                <div className="offerSec">
                  <p className="offerHeading">Offer</p>
                  <h1 className="mb-0">{campaignData?.brandDetails.currencySign}{abbreviateNumber(campaignData?.campaign.Denomination)} OFF</h1>
                  <h2 className="mb-0">On Your Purchase</h2>
                </div>
              </Col>
              <Col xs={24} xl={24} style={{ minHeight: "500px", padding: '15px' }}>
                <div className="geoSearchBox mb-2">
                  <Autocomplete
                    apiKey={process.env.REACT_APP_MAP_KEY}
                    defaultValue={location}
                    value={location}
                    onChange={(e) => {
                      setLocation(e.target.value);
                    }}
                    onPlaceSelected={(place) => {
                      if (place.name) {
                        setLocation(place.name);
                      }
                      else {
                        setLocation(place.formatted_address);
                      }
                    }}
                  />
                  <Button type="primary" onClick={findCurrentLocation} icon={<AimOutlined />}></Button></div>
                <div className="geoSearchButtonBox mb-3">
                  {campaignData ?
                    <Select
                      placeholder="Select radius"
                      defaultValue={[`5 ${campaignData?.brandDetails.country === 1 ? 'Miles' : 'KM'}`]}
                      onChange={(radius) => {
                        setRadius(radius);
                      }}
                      style={{ width: "100px" }}
                    >
                      <Option value="5">5 {campaignData?.brandDetails.country === 1 ? 'Miles' : 'KM'}</Option>
                      <Option value="10">10 {campaignData?.brandDetails.country === 1 ? 'Miles' : 'KM'}</Option>
                      <Option value="15">15 {campaignData?.brandDetails.country === 1 ? 'Miles' : 'KM'}</Option>
                      <Option value="25">25 {campaignData?.brandDetails.country === 1 ? 'Miles' : 'KM'}</Option>
                      <Option value="50">50 {campaignData?.brandDetails.country === 1 ? 'Miles' : 'KM'}</Option>
                    </Select>
                    : ""}
                  <Button type="primary" onClick={findStore}><SearchOutlined /> Search</Button>
                </div>
                {storeData.length > 0 && storeData.map((item, key) => {
                  return <>
                    <div className="merchantsBox" key={key}>
                      <h4>{item.organizationName}</h4>
                      <p> <EnvironmentOutlined /> {`${item.addressLine1}${item.cityName ? ',' : ''} ${item.cityName}`} </p>
                      <p className="footerBox"><a target="_blank"  rel="noreferrer" href={`https://maps.google.com/?q=${item.latitude},${item.longitude}`} className="direction"><HeatMapOutlined />Directions</a> <span className="radiusText">{item.distance} {campaignData?.brandDetails.country === 1 ? 'Miles' : 'KM'}</span> {/* <Link to="/" className="view"><GlobalOutlined />View Page</Link> */}</p>
                    </div>
                    {/* <Card key={key}>
                      <p>
                        Organization Name: {item.organizationName}<br />
                        Address Line 1: {item.addressLine1}
                      </p>
                      <Button>Directions</Button>&nbsp;&nbsp;&nbsp;
                      <Button>View Page</Button>
                    </Card> */}
                  </>
                })}
                <p className="text-center"><br />
                  {isLoading === false && storeData.length === 0 && isFirstLoaded === true ?
                    <b>No stores Available</b>
                    : ""}
                  {isLoading === true && isFirstLoaded === true ?
                    <Loader />
                    : ""}</p>
              </Col>
              {/* <Col xs={16} xl={16}>
                Map
              </Col> */}
            </Row>
          }
        </Layout>
      </div>
    </>
  );
};
export default FindStore;
