import React, { useEffect, useState, useRef } from "react";
import { Layout, Row, Col, Tooltip, message } from "antd";
import { DownloadOutlined } from "@ant-design/icons";
import { CampaignAdd } from "../../modules/campaigns/";
import "../../modules/campaigns/campaigns.less";
import { ApiRoutes } from "../../config/ApiRoutes";
import { AppConfig } from "../../config/AppConfig";
import { ApiHelper, getCurrency, getUserData } from "../../Helpers";
import { Loader, ClipboardCopy } from "../../common/components/";
import { triggerBase64Download } from "react-base64-downloader";
import QRImage from "react-qr-image";

const { Content } = Layout;

const EditCampaigns = (props) => {
  const query = new URLSearchParams(props.match.params);
  const campaignId = query.get("id");
  const Currency = getCurrency();
  const imageRef = useRef(null);
  const [loader, setLoader] = useState(true);
  const [campaignCode, setCampaignCode] = useState("");
  const [state, setState] = useState([]);
  const [products, setProducts] = useState([]);
  const [policyData, setPolicyData] = useState({});
  const [data, setData] = useState({
    campaignCode: "",
    denomination: "",
    noOfVoucher: "",
    totalOfferValue: "",
    campaignName: "",
    startDate: "",
    endDate: "",
    templateProducts: "",
    state: "",
    customImage: "",
    templateId: "",
    templateType: 1,
    Cstatus:""
  });
  const getCampaign = async () => {
    const response = await new ApiHelper().FetchFromServer(
      ApiRoutes.CAMPAIGN_DETAIL.service,
      ApiRoutes.CAMPAIGN_DETAIL.url,
      ApiRoutes.CAMPAIGN_DETAIL.method,
      ApiRoutes.CAMPAIGN_DETAIL.authenticate,
      { id: campaignId }
    );
    if (response.code === 200) {
      if (response.data.campaign !== "") {
        console.log(response.data.campaign.Cstatus)
        setCampaignCode(response.data.campaign.CampaignCode);
        const temp = data;
        temp.campaignCode = response.data.campaign.CampaignCode;
        temp.denomination = response.data.campaign.Denomination;
        temp.noOfVoucher = response.data.campaign.CardCount;
        temp.totalOfferValue = response.data.campaign.TotalOffer;
        temp.campaignName = response.data.campaign.CampaignName;
        temp.startDate = response.data.campaign.CStartDate;
        temp.endDate = response.data.campaign.CEndDate;
        temp.terms = response.data.campaign.terms;
        temp.disclaimer = response.data.campaign.disclaimer;
        temp.privacyPolicy = response.data.campaign.privacyPolicy;
        temp.Cstatus = response.data.campaign.Cstatus
        temp.templateProducts = response.data.products.map((value) => {
          return value.organizationProductId;
        });
        temp.state = response.data.stateCity.map((value) => {
          return value.StateId;
        });
        temp.customImage = response.data?.template?.CustomCode
          ? `${response.data?.template?.CustomCode}`
          : "";

        temp.customerTemplate = {
          preview: response.data.campaign.customerTemplateUrl || '',
          urlTemplate: response.data.campaign.customerTemplateUrl || '',
          textBackgroundColor: response.data.campaign.textBackgroundColor || '',
          textColor: response.data.campaign.textColor || '',
          textBorderColor: response.data.campaign.textBorderColor || '',
          position: response.data.campaign.buttonPosition ? JSON.parse(response.data.campaign.buttonPosition) : {
            x: 0,
            y: 0
          }
        };
        temp.templateType = response.data?.template?.type === "Default" ? 2 : 1;
        temp.templateId = response.data?.template?.TemplateId
          ? `${response.data?.template?.TemplateId}`
          : "";
        setData(temp);
      } else {
        message.error({
          content: response.messages || response.error,
          duration: 2
        });
      }
    } else {
      message.error({
        content: response.messages || response.error,
        duration: 2
      });
    }
    setLoader(false);
  };
  const fetchData = async () => {
    const response = await new ApiHelper().FetchFromServer(
      ApiRoutes.CAMPAIGN_FETCH_DATA.service,
      ApiRoutes.CAMPAIGN_FETCH_DATA.url,
      ApiRoutes.CAMPAIGN_FETCH_DATA.method,
      ApiRoutes.CAMPAIGN_FETCH_DATA.authenticate
    );
    if (response.code === 200) {
      if (response.data?.states && response.data?.states.length > 0)
        setState(response.data.states);
      if (response.data?.products && response.data?.products.length > 0)
        setProducts(response.data.products);
      if (response.data.policyData) setPolicyData(response.data.policyData);
    }
  };
  useEffect(() => {
    getCampaign();
    fetchData();
    // eslint-disable-next-line
  }, []);
  return (
    <>
      {loader ? (
        <section
          className="text-center"
          style={{
            height: "80vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center"
          }}
        >
          <Loader />
        </section>
      ) : (
        <>
          <section id="Campaigns" className="headerFix">
            <div className="campaignHeader">
              <span className="codeBox">Campaign Code #{campaignCode}</span>
              <div className="copyBox">
                <ClipboardCopy
                  copyText={`${AppConfig.API_ENDPOINT}/${campaignCode}`}
                />
              </div>
              <div
                className="downloadBox"
                onClick={() =>
                  triggerBase64Download(
                    imageRef.current.getElementsByTagName("img")[0].src,
                    "QRCode"
                  )
                }
              >
                <Tooltip title="Download">
                  {" "}
                  <DownloadOutlined />
                </Tooltip>
              </div>
              <div
                className="qrBox"
                ref={imageRef}
                onClick={() =>
                  triggerBase64Download(
                    imageRef.current.getElementsByTagName("img")[0].src,
                    "QRCode"
                  )
                }
              >
                <Tooltip title="Qr Code">
                  {" "}
                  <QRImage text={`${AppConfig.API_ENDPOINT}/${campaignCode}`} />
                </Tooltip>
              </div>
            </div>
          </section>
          <Content
            className="content-body"
            style={{ minHeight: 200, margin: "0px 25px" }}
            id="Campaigns"
          >
            <Row>
              <Col lg={24} xs={24}>
                <div style={{ margin: "0px 14px 14px 14px" }}>
                  <CampaignAdd
                    state={state}
                    products={products}
                    campaignCode={campaignCode}
                    currency={Currency}
                    policyData={policyData}
                    setLoader={setLoader}
                    data={data}
                    campaignId={campaignId}
                    brandLogo={getUserData().brandImage}
                  />
                </div>
              </Col>
            </Row>
          </Content>
        </>
      )}
    </>
  );
};
export default EditCampaigns;
