import 'bootstrap/dist/js/bootstrap.bundle.min';
import React from "react";
import FrontendHeader from '../../common/components/frontend/header'
import FrontendFooter from '../../common/components/frontend/footer';
import FrontendBusinesses from '../../modules/frontend/Businesses'
import "../../modules/frontend/frontend.less";

const Businesses = () => {
  return (
    <div id="frontend">
      <FrontendHeader />
      <main>
        <div className="page-body">
          <FrontendBusinesses />
        </div>
      </main>
      <FrontendFooter />
    </div>
  );
};
export default Businesses;
