export const abbreviateNumber = (value) => {
   let num = value;
   let str; 
   if (num >= 1000000000000) {
      num = (num / 1000000000000).toFixed(1).replace(/\.0$/, '');
       str = num.toString().split('.');
      if (str[0].length >= 5) {
         str[0] = str[0].replace(/(\d)(?=(\d{3})+$)/g, '$1,');
      }
      if (str[1] && str[1].length >= 5) {
         str[1] = str[1].replace(/(\d{3})/g, '$1 ');
      }
      num = str.join('.') + 'T';
   }
   else if (num >= 1000000000) {
      num = (num / 1000000000).toFixed(1).replace(/\.0$/, '');
       str = num.toString().split('.');
      if (str[0].length >= 5) {
         str[0] = str[0].replace(/(\d)(?=(\d{3})+$)/g, '$1,');
      }
      if (str[1] && str[1].length >= 5) {
         str[1] = str[1].replace(/(\d{3})/g, '$1 ');
      }
      num = str.join('.') + 'B';
   }
   else if (num >= 1000000) {
      return (num / 1000000).toFixed(2).replace(/\.0$/, '') + 'M';
   }
   else if (num >= 1000) {
      return (num / 1000).toFixed(2).replace(/\.0$/, '') + 'K';
   }
   else {
      return Number(num).toFixed(2).replace(/\.0$/, '');
   }
   return num;
};
