import { Col, Row, Skeleton, Select } from "antd";
import { motion } from "framer-motion";
import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { Search, Emptymsg } from "../../common/components/layout/";
const { Option } = Select;
const container = {
  hidden: { opacity: 1, scale: 0, width: "100%", padding: 0 },
  visible: {
    opacity: 1,
    scale: 1,
    transition: {
      delayChildren: 0.3,
      staggerChildren: 0.2,
    },
  },
};

const item = {
  hidden: { y: 20, opacity: 0, listStyleType: "none" },
  visible: {
    y: 0,
    opacity: 1,
  },
};

const Stores = (props) => {
  const { store, state, handleStoreFilter, isLoader, campaignId } = props;
  const [loading, setLoading] = React.useState(true);
  useEffect(() => {
    setTimeout(() => setLoading(false), 1000);
  }, []);

  return (
    <>

      <Col lg={24} xs={24}>
        <div style={{ borderRadius: 4 }} className="storeFilterSec">
          <Row gutter={24}>
            <Col lg={8} xs={24}>
              <Search placeholder="Search stores..." action={(e) => handleStoreFilter(e)} />
            </Col>
            <Col lg={8} xs={24}>
              {/* <StateCity /> */}
              <Select
                placeholder="Select State"
                onChange={handleStoreFilter}
                style={{ width: '100%' }}
              >
                <Option key="">Select State</Option>
                {state.map((stateData) => (
                  <Option key={stateData.stateId}>{stateData.stateName}</Option>
                ))}
              </Select>
            </Col>
          </Row>
        </div>
      </Col>

      <Col lg={24} xs={24}>
        <div style={{ borderRadius: 4 }}>
          <div className="products-selected-wrapper">
            <motion.ul
              className="container"
              variants={container}
              initial="hidden"
              animate="visible"
            >
              <Row gutter={16}>
                {store.map((productsdata, index) => (
                  <Col lg={6} xs={24}>
                    <Link to={`/storedetails?storeId=${productsdata.organizationId}&campaignId=${campaignId}`}>
                      <motion.li key={index} className="item" variants={item}>

                        <div className="box">
                          <Skeleton
                            paragraph={{ rows: 3 }}
                            loading={loading}
                            active
                          >
                            <div className="inner">
                              <svg xmlns="http://www.w3.org/2000/svg" width="47.781" height="39.719" viewBox="0 0 47.781 39.719">
                                <path id="Icon_awesome-store" data-name="Icon awesome-store" d="M46.7,9.2,41.666,1.164A2.483,2.483,0,0,0,39.563,0H8.223a2.483,2.483,0,0,0-2.1,1.164L1.086,9.2c-2.6,4.15-.295,9.922,4.561,10.581a8.062,8.062,0,0,0,1.063.07,7.66,7.66,0,0,0,5.725-2.568,7.666,7.666,0,0,0,11.45,0,7.666,7.666,0,0,0,11.45,0,7.685,7.685,0,0,0,5.725,2.568,7.975,7.975,0,0,0,1.063-.07C47,19.13,49.307,13.359,46.7,9.2ZM41.076,22.342a9.843,9.843,0,0,1-2.288-.295v7.742H9V22.047a10.277,10.277,0,0,1-2.288.295,10.468,10.468,0,0,1-1.4-.093,9.756,9.756,0,0,1-1.272-.279V37.237a2.48,2.48,0,0,0,2.482,2.482H41.278a2.48,2.48,0,0,0,2.482-2.482V21.97a7.921,7.921,0,0,1-1.272.279A10.789,10.789,0,0,1,41.076,22.342Z" transform="translate(-0.004)" fill="#2e333e" opacity="0.89" />
                              </svg>
                              <h4 title={productsdata.organizationName}>
                                {productsdata.organizationName}
                              </h4>
                              {productsdata.address ? <p title={productsdata.address}>{productsdata.address}</p> : <p>NA</p>}
                            </div>
                            <div className="d-flex product-count-box">
                              <h3>Total stock</h3>
                              <h2>{parseInt(productsdata.totalStock) || 0}</h2>
                            </div>
                          </Skeleton>
                        </div>
                      </motion.li>
                    </Link>
                  </Col>
                ))}
                {isLoader === false && store.length === 0 ?
                  <Col lg={24} xs={24}>
                    <Emptymsg
                      emptytitle={""}
                      emptydescription={"No store available"}
                    />
                  </Col>
                  : ''}
              </Row>
            </motion.ul>
          </div>
        </div>
      </Col>
    </>
  );
};
export { Stores };
