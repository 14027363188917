import 'bootstrap/dist/js/bootstrap.bundle.min';
import React from "react";
import FrontendHeader from '../../common/components/frontend/header'
import FrontendFooter from '../../common/components/frontend/footer';
import FrontendContact from '../../modules/frontend/FrontendContact'
import "../../modules/frontend/frontend.less";

const Contact = () => {
  return (
    <div id="frontend">
      <FrontendHeader />
      <main>
        <div className="page-body">
          <FrontendContact />
        </div>
      </main>
      <FrontendFooter />
    </div>
  );
};
export default Contact;
