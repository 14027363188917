export const ApiRoutes = {
  LOGIN: {
    service: "/brand/user",
    url: "/login",
    method: "POST",
    authenticate: false
  },
  PROFILE_UPDATE: {
    service: "/brand/user",
    url: "/profile",
    method: "POST",
    authenticate: false
  },
  PRODUCT_LIST: {
    service: "/brand/product",
    url: "/",
    method: "GET",
    authenticate: true
  },
  PRODUCT_ADD: {
    service: "/brand/product",
    url: "/add",
    method: "POST",
    authenticate: true
  },
  PRODUCT_EDIT: {
    service: "/brand/product",
    url: "/update",
    method: "POST",
    authenticate: true
  },
  CAMPAIGN_CODE: {
    service: "/brand/campaign",
    url: "/campaignCode",
    method: "GET",
    authenticate: true
  },
  CAMPAIGN_LIST: {
    service: "/brand/campaign",
    url: "/",
    method: "GET",
    authenticate: true
  },
  CAMPAIGN_DETAIL: {
    service: "/brand/campaign",
    url: "/campaignDetail",
    method: "GET",
    authenticate: true
  },
  CAMPAIGN_FETCH_DATA: {
    service: "/brand/campaign",
    url: "/fetchData",
    method: "GET",
    authenticate: true
  },
  CAMPAIGN_ADD: {
    service: "/brand/campaign",
    url: "/add",
    method: "POST",
    authenticate: true
  },
  CAMPAIGN_EDIT: {
    service: "/brand/campaign",
    url: "/edit",
    method: "PUT",
    authenticate: true
  },
  FORGET_PASSWORD: {
    service: "/brand/user",
    url: "/forget-password",
    method: "POST",
    authenticate: false
  },
  RESET_PASSWORD: {
    service: "/brand/user",
    url: "/reset-password/:id",
    method: "POST",
    authenticate: false
  },
  CHANGE_PASSWORD: {
    service: "/brand/user",
    url: "/change-password",
    method: "POST",
    authenticate: true
  },
  STORE_CAMPAIGN_LIST: {
    service: "/store/campaign",
    url: "/",
    method: "GET",
    authenticate: false
  },
  STORE_CAMPAIGN_DETAIL: {
    service: "/store/campaign",
    url: "/campaignDetail",
    method: "GET",
    authenticate: false
  },
  STORE_PRODUCT_LIST: {
    service: "/brand/store",
    url: "/productList",
    method: "GET",
    authenticate: false
  },
  STORE_JOIN_CAMPAIGN: {
    service: "/brand/store",
    url: "/joinCampaign",
    method: "POST",
    authenticate: false
  },

  CAMPAIGN_PRODUCT_STOCK: {
    service: "/brand/campaign",
    url: "/productData",
    method: "GET",
    authenticate: false
  },
  CAMPAIGN_JOIN_STORE: {
    service: "/brand/campaign",
    url: "/storeList",
    method: "GET",
    authenticate: false
  },
  CAMPAIGN_HEAT_MAP: {
    service: "/brand/campaign",
    url: "/heatmap",
    method: "GET",
    authenticate: false
  },
  GET_LOCATION: {
    service: "/brand/store",
    url: "/getLocation",
    method: "POST",
    authenticate: false
  },
  SAVE_LATLONG: {
    service: "/brand/store",
    url: "/saveLatLong",
    method: "POST",
    authenticate: false
  },
  CAMPAIGN_BRAND_LIST: {
    service: "/store/campaign",
    url: "/brandList",
    method: "GET",
    authenticate: false
  },
  COUNTRY_LIST: {
    service: "/store/campaign",
    url: "/country",
    method: "GET",
    authenticate: false
  },
  STATE_LIST: {
    service: "/store/campaign",
    url: "/state",
    method: "GET",
    authenticate: false
  },
  CITY_LIST: {
    service: "/store/campaign",
    url: "/city",
    method: "GET",
    authenticate: false
  },
  VERIFY_TOKEN: {
    service: "/web/v1/user",
    url: "/verify-token",
    method: "POST",
    authenticate: false,
  },
  CAMPAIGN_FETCH_STATE: {
    service: "/brand/campaign",
    url: "/fetchState",
    method: "GET",
    authenticate: true
  },
  REGISTER_MERCHANT: {
    service: "/brand/store",
    url: "/register-merchant",
    method: "POST",
    authenticate: false
  },
  STORE_DETAILS: {
    service: "/brand/store",
    url: "/store-details",
    method: "GET",
    authenticate: true
  },
  VERIFY_OTP: {
    service: "/brand/store",
    url: "/verifyOtp",
    method: "POST",
    authenticate: false
  },
  RESEND_OTP: {
    service: "/brand/store",
    url: "/resendOtp",
    method: "POST",
    authenticate: false
  },
  BRAND_DETAILS: {
    service: "/brand/campaign",
    url: "/brandDetails",
    method: "GET",
    authenticate: false
  },
  NOTIFICATION_LIST: {
    service: "/brand/campaign",
    url: "/notification-list",
    method: "GET",
    authenticate: false
  },
  NOTIFICATION_COUNT: {
    service: "/brand/campaign",
    url: "/count-notification",
    method: "GET",
    authenticate: false
  },
  NOTIFICATION_STATUS: {
    service: "/brand/campaign",
    url: "/notification-status",
    method: "POST",
    authenticate: false
  },

  STORE_PRODUCT_ASSIGN: {
    service: "/brand/store",
    url: "/store-product-assign",
    method: "POST",
    authenticate: false
  },
  STORE_ADD: {
    service: "/brand/store",
    url: "/store-add",
    method: "POST",
    authenticate: false
  },
  VERIFY_PLAID_STATUS: {
    service: "/plaid/auth",
    url: "/verify-status",
    method: "GET",
    authenticate: false
  },
  BUSINESS_CATEGORY_LIST: {
    service: "/store/campaign",
    url: "/business-category",
    method: "GET",
    authenticate: false
  },
  BUSINESS_SUBCATEGORY_LIST: {
    service: "/store/campaign",
    url: "/business-subcategory",
    method: "GET",
    authenticate: false
  },
  CAMPAIGN_STATUS: {
    service: "/store/campaign",
    url: "/campaignStatus",
    method: "POST",
    authenticate: false
  },
  BRAND_STATUS: {
    service: "/store/campaign",
    url: "/brandStatus",
    method: "POST",
    authenticate: false
  },
  CHECK_UPS_NUMBER: {
    service: "/brand/product",
    url: "/checkUSPNumber",
    method: "GET",
    authenticate: true
  },
  SETTING_FETCH: {
    service: "/brand/user",
    url: "/settings",
    method: "GET",
    authenticate: true
  },
  SETTING_UPDATE: {
    service: "/brand/user",
    url: "/settings",
    method: "POST",
    authenticate: true
  },
  FIND_STORE_LOCATION: {
    service: "/brand/store",
    url: "/find-store-location",
    method: "GET",
    authenticate: true
  },
  CAMPAIGN_CUSTOMER_DASHBOARD: {
    service: "/brand/campaign",
    url: "/customerDashboard",
    method: "GET",
    authenticate: true
  },
  GIFT_CARD_SAVE_GPAY: {
    service: "/brand/customer",
    url: "/save-g-pay",
    method: "GET",
    authenticate: false
  },
};
