import { message } from "antd";
import { useHistory, useLocation } from "react-router-dom";
import React, { useEffect } from "react";
import { ApiRoutes } from "../../config/ApiRoutes";
import { ApiHelper, setCampaignUserData } from "../../Helpers";
import { Loader } from "../../common/components/Loader";

const ProxyLogin = () => {
  const history = useHistory();
  const search = useLocation().search;
  const queryString = new URLSearchParams(search);
  const token = queryString.get("token");
  const campaignId = queryString.get("campaignId");
  const verifyToken = async () => {
    const response = await new ApiHelper().FetchFromServer(
      ApiRoutes.VERIFY_TOKEN.service,
      ApiRoutes.VERIFY_TOKEN.url,
      ApiRoutes.VERIFY_TOKEN.method,
      ApiRoutes.VERIFY_TOKEN.authenticate,
      undefined,
      { token: token }
    );
    if (response.code === 200) {
      if (response.data.data) {
        await setCampaignUserData({
          organizationId: response.data.data.organizationId,
          employeeId: response.data.data.employeeId,
          country: response.data.data.country,
          state: response.data.data.state,
          currencySign: response.data.data.currencySign,
          language: response.data.data.language,
        });
        if (campaignId)
          history.push(`/join-campaign/detail?campaignId=${campaignId}`);
        else history.push("/join-campaign");
      } else {
        window.location.href = "";
      }
    } else {
      message.error({
        content: response.message || response.messages || response.error,
        duration: 2
      });
    }
  };

  useEffect(() => {
    verifyToken();
    // eslint-disable-next-line
  }, []);

  return (
    <section
      className="text-center"
      style={{
        height: "90vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
      }}
    >
      <Loader />
    </section>
  );
};
export default ProxyLogin;
