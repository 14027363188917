import { Layout, Row, Space, Col, message } from "antd";
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Search, PageTitle } from "../../common/components/layout/";
import { PlusOutlined } from "@ant-design/icons";
import { ProductList } from "../../modules/products/";
import { ApiRoutes } from "../../config/ApiRoutes";
import { ApiHelper } from "../../Helpers";
import { limit } from "../../config/AppConfig";
import InfiniteScroll from "react-infinite-scroller";
import { Loader, EmptyComponents } from "../../common/components/";
import "../../modules/products/products.less";

const { Content } = Layout;
const title = "Products";
const Products = () => {
  const [offset, setOffset] = useState(0);
  const [product, setProduct] = useState([]);
  const [loader, setLoader] = useState(true);
  const [hasMore, setHasMore] = useState(true);
  const [searchWord, setSearch] = useState("");
  const [fetching, setFetching] = useState(false);
  useEffect(() => {
    setProduct([]);
    getProductList(offset);
    // eslint-disable-next-line
  }, []);
  const productInfoUpdate = (data, key) => {
    const temp = product;
    temp[key].imageName = data.imageName;
    temp[key].productCodeOveride = data.name;
    temp[key].upc = data.upc;
    setProduct([...temp]);
  };
  const getProductList = async (
    page = offset,
    search = searchWord,
    filter = true
  ) => {
    console.log('fetching',fetching)
    if (fetching) {
      return;
    }

    setFetching(true);
    try {
      //setLoader(true);
      const response = await new ApiHelper().FetchFromServer(
        ApiRoutes.PRODUCT_LIST.service,
        ApiRoutes.PRODUCT_LIST.url,
        ApiRoutes.PRODUCT_LIST.method,
        ApiRoutes.PRODUCT_LIST.authenticate,
        {
          limit: limit,
          offset: page + 1,
          search: search
        }
      );
      setOffset(page);
      if (response.code === 200) {
        console.log("response.data.data", response.data.data);
        if (response.data.data.length > 0) {
          if (product.length > 0 && filter) {
            setProduct([...product, ...response.data.data]);
          } else {
            if (response.data.data.length < limit) setHasMore(false);
            setProduct(response.data.data);
          }
        } else setHasMore(false);
      } else {
        message.error({
          content: response.messages || response.error,
          duration: 2
        });
      }
      setLoader(false);
    } finally {
      setFetching(false);
    }
  };
  //   const params = props.match.params;
  const filterProduct = (e) => {
    getProductList(0, e.target.value, false);
    // setOffset(0);
    setSearch(e.target.value);
    setLoader(true);
    setProduct([]);
    setHasMore(true);
  };

  return (
    <>
      <Space className="filter-sec headerFix">
        <PageTitle title={title} />
        <Search action={(e) => filterProduct(e)} />
        <Link to="/product/add" className="btn-primary">
          {" "}
          <PlusOutlined /> Create Product
        </Link>
      </Space>
      <Content
        className="content-body"
        style={{ minHeight: 200, margin: "0px 25px" }}
        id="Products"
      >
        <Row>
          <Col lg={24} xs={24}>
            <div style={{ margin: "0px 14px 14px 14px" }}>
              {loader ? (
                <section
                  className="text-center"
                  style={{
                    height: "80vh",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center"
                  }}
                >
                  <Loader />
                </section>
              ) : product.length > 0 ? (
                <InfiniteScroll
                  pageStart={offset}
                  loadMore={getProductList}
                  hasMore={fetching && hasMore}
                  pullDownToRefresh
                  pullDownToRefreshThreshold={50}
                  loader={
                    <section
                      className="text-center"
                      style={{
                        height: "80vh",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center"
                      }}
                    >
                      <Loader />
                    </section>
                  }
                >
                  <ProductList
                    product={product}
                    productInfoUpdate={productInfoUpdate}
                  />
                </InfiniteScroll>
              ) : (
                // </InfiniteScroll>
                <EmptyComponents
                  title="Product"
                  message="No Product Available "
                />
              )}
            </div>
          </Col>
        </Row>
      </Content>
    </>
  );
};
export default Products;
