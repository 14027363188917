
import React, { useState, useEffect } from "react";
import { message } from "antd";
import { Details } from "../../../modules/join/Details";
import "../../../modules/join/join.less";
import { useLocation,useHistory } from "react-router-dom";
import { ApiRoutes } from "../../../config/ApiRoutes";
import { ApiHelper, getCampaignUserData } from "../../../Helpers";

const JoinCampaignsDetails = () => {
  const search = useLocation().search;
  const history = useHistory()
  const campaign = new URLSearchParams(search).get('campaignId');
  const [campaignId, setCampaignId] = useState(0);
  const [campaignData, setCampaignData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [loadingBrandBtn, setLoadingBrandBtn] = useState(false);
  const [loadingCampaigndBtn, setLoadingCampaigndBtn] = useState(false);

  useEffect(() => {
    if (campaignId) {
      getCampaignList();
    }
    // eslint-disable-next-line
  }, [campaignId]);
  useEffect(() => {
    setCampaignId(campaign);
    // eslint-disable-next-line
  }, [campaign]);
  useEffect(() => {
    const div = document.getElementById('wrapper');
    if (div && div.classList.contains("overflow-hidden")) {
      div.classList.remove('overflow-hidden')
    }
    // eslint-disable-next-line
  }, []);

  const getCampaignList = async () => {
    setLoading(true);
    const response = await new ApiHelper().FetchFromServer(
      ApiRoutes.STORE_CAMPAIGN_DETAIL.service,
      ApiRoutes.STORE_CAMPAIGN_DETAIL.url,
      ApiRoutes.STORE_CAMPAIGN_DETAIL.method,
      ApiRoutes.STORE_CAMPAIGN_DETAIL.authenticate,
      {
        id: campaignId,
        organizationId: getCampaignUserData().organizationId,
      }
    );
    setLoading(false);
    setLoadingCampaigndBtn(false);
    setLoadingBrandBtn(false);
      console.log(response)
    if (response.code === 200) {
      if (response.data === undefined) {
        setCampaignData(null);
      }
      else {
        setCampaignData(response.data);
      }
    } 
    else if(response.code === 400 || response.code === 404){
      message.error({
        content: response.messages || response.error,
        duration: 2,
      });
      setTimeout(()=>{
        history.goBack()
      },2000)
    }
    else {
      message.error({
        content: response.messages || response.error,
        duration: 2,
      });
    }
  };

  const handleCampaignStatus = async (status) => {
    setLoadingCampaigndBtn(true);
    const response = await new ApiHelper().FetchFromServer(
      ApiRoutes.CAMPAIGN_STATUS.service,
      ApiRoutes.CAMPAIGN_STATUS.url,
      ApiRoutes.CAMPAIGN_STATUS.method,
      ApiRoutes.CAMPAIGN_STATUS.authenticate,
      undefined,
      {
        organizationId: getCampaignUserData().organizationId,
        campaignId: campaignId,
        status: status
      }
    );
    if (response.code === 200) {
      getCampaignList();
      message.success({
        content: response.messages || response.error,
        duration: 2,
      });
    } else {
      setLoadingCampaigndBtn(false);
      message.error({
        content: response.messages || response.error,
        duration: 2,
      });
    }
  };

  const handleBrandStatus = async (status) => {
    setLoadingBrandBtn(true);
    const response = await new ApiHelper().FetchFromServer(
      ApiRoutes.BRAND_STATUS.service,
      ApiRoutes.BRAND_STATUS.url,
      ApiRoutes.BRAND_STATUS.method,
      ApiRoutes.BRAND_STATUS.authenticate,
      undefined,
      {
        organizationId: getCampaignUserData().organizationId,
        brandOrganizationId : campaignData.campaign.OrganizationId,
        status: status
      }
    );
    if (response.code === 200) {
      getCampaignList();
      message.success({
        content: response.messages || response.error,
        duration: 2,
      });
    } else {
      setLoadingBrandBtn(false);
      message.error({
        content: response.messages || response.error,
        duration: 2,
      });
    }
  };

  return (
    <>
      <div id="Join"> <Details campaignData={campaignData} loading={loading} handleCampaignStatus={handleCampaignStatus} handleBrandStatus={handleBrandStatus} loadingBrandBtn={loadingBrandBtn} loadingCampaigndBtn={loadingCampaigndBtn} /> </div>
    </>
  );
};
export default JoinCampaignsDetails;
