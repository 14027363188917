import React from "react";
import { NavLink } from "react-router-dom";
// import { Avatar, Switch, Tooltip } from "antd";
import { Avatar, Tooltip } from "antd";
// import { useThemeSwitcher } from "react-css-theme-switcher";
import { logout } from "../../../Helpers/";
import "./sidebarprofile.less";
// import Avtar from "../../../assets/images/Logo/kardstash-fill-icon.png";
import Logosmall from "../../../assets/images/Logo/kardstashaWhiteLogo.png";
 
const Sidebarprofile = () => {
  // const [isDarkMode, setIsDarkMode] = React.useState();
  // const { switcher, themes } = useThemeSwitcher();
  // const toggleTheme = (isChecked) => {
  //   setIsDarkMode(isChecked);
  //   switcher({ theme: isChecked ? themes.dark : themes.light });
  // };
  return (
    <>
      <ul className="profile-sider">
        <Avatar src={Logosmall} shape="square" className="avtar" />
        {/* <img className="" src={Logosmall} width={42} style={{ display: 'inline-flex', justifyContent: 'center', alignItems: 'center',padding:8,margin: 'auto' }} alt="Logo" /> */}
        <li key="1">
          <Tooltip placement="right" title="My Profile">
            <NavLink to="/profile">
            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="19" viewBox="0 0 142.27 182">
              <g id="Group_24663" data-name="Group 24663" transform="translate(-4870 -9377)">
                <path id="Ellipse_906" data-name="Ellipse 906" d="M51.5,18A33.5,33.5,0,1,0,85,51.5,33.538,33.538,0,0,0,51.5,18m0-18A51.5,51.5,0,1,1,0,51.5,51.5,51.5,0,0,1,51.5,0Z" transform="translate(4888.005 9377)" fill="#fff"/>
                <path id="Union_1" data-name="Union 1" d="M124,61.289V48.725H124l.009-.38V41.289a9.033,9.033,0,0,1,.213-1.957l0-.188s-1.53-18.536-16.779-20.124-41.087-1.085-41.087-1.085H37.215s-7.451.33-12.4,5-7.27,8.562-7.4,13.681V52.289h83.593a9,9,0,1,1,0,18H9A9,9,0,0,1,.122,62.748L0,62.771V38.111s-.58-13.165,10.957-25.5C18.2,4.924,28.082,1.107,38.74.541S103.345,0,103.345,0s16.739.37,27.169,12.218,11.961,24.547,11.734,37.017l-.244,0V61.289a9,9,0,0,1-18,0Z" transform="translate(4870 9488.711)" fill="#fff"/>
              </g>
            </svg>

            </NavLink>
          </Tooltip>
        </li>
        <li key="2">
          <Tooltip placement="right" title="Settings">
            <NavLink to="/settings">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="17.715"
                height="17.723"
                viewBox="0 0 27.715 27.723"
              >
                <path
                  id="Icon_ionic-ios-settings"
                  data-name="Icon ionic-ios-settings"
                  d="M29.927,18.358a3.566,3.566,0,0,1,2.288-3.327A14.136,14.136,0,0,0,30.5,10.909a3.614,3.614,0,0,1-1.451.31A3.559,3.559,0,0,1,25.8,6.211,14.094,14.094,0,0,0,21.685,4.5a3.563,3.563,0,0,1-6.655,0,14.137,14.137,0,0,0-4.121,1.711A3.559,3.559,0,0,1,7.654,11.22a3.5,3.5,0,0,1-1.451-.31,14.449,14.449,0,0,0-1.7,4.128,3.565,3.565,0,0,1,.007,6.655,14.137,14.137,0,0,0,1.711,4.121,3.56,3.56,0,0,1,4.7,4.7,14.22,14.22,0,0,0,4.121,1.711,3.557,3.557,0,0,1,6.64,0A14.137,14.137,0,0,0,25.8,30.512a3.564,3.564,0,0,1,4.7-4.7,14.219,14.219,0,0,0,1.711-4.121A3.583,3.583,0,0,1,29.927,18.358Zm-11.5,5.767A5.774,5.774,0,1,1,24.2,18.35,5.772,5.772,0,0,1,18.423,24.124Z"
                  transform="translate(-4.5 -4.5)"
                  fill="#fff"
                />
              </svg>
            </NavLink>
          </Tooltip>
        </li>
        <li key="3">
          <Tooltip placement="right" title="Change Password">
            <NavLink to="/change-password">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="17.404"
                height="20.626"
                viewBox="0 0 27.404 35.626"
              >
                <path
                  id="Icon_ionic-ios-unlock"
                  data-name="Icon ionic-ios-unlock"
                  d="M30.729,15.707h-16.1V11.6a5.823,5.823,0,0,1,11.647,0h0a1.2,1.2,0,0,0,2.4,0h0a8.222,8.222,0,1,0-16.443.111v4H10.176A3.436,3.436,0,0,0,6.75,19.133V35.575A3.436,3.436,0,0,0,10.176,39H30.729a3.436,3.436,0,0,0,3.426-3.426V19.133A3.436,3.436,0,0,0,30.729,15.707ZM21.651,26.395v5.892a1.228,1.228,0,0,1-1.139,1.233,1.2,1.2,0,0,1-1.259-1.2V26.395a2.74,2.74,0,1,1,2.4,0Z"
                  transform="translate(-6.75 -3.374)"
                  fill="#fff"
                />
              </svg>
            </NavLink>
          </Tooltip>
        </li>
        {/* <li className="mt-auto">
          {" "}
          <Tooltip placement="right" title="Theme Switch">
            <Switch checked={isDarkMode} onChange={toggleTheme} />
          </Tooltip>
        </li> */}
        <li key="4" className="mt-auto active">
          <Tooltip placement="right" title="Logout">
            <span
              onClick={logout}
              style={{
                color: "#fff",
                cursor: "pointer",
                top: "5px",
                position: "relative"
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="18.06"
                height="21.888"
                viewBox="0 0 30.06 33.888"
              >
                <g
                  id="Group_45"
                  data-name="Group 45"
                  transform="translate(1 0)"
                >
                  <path
                    id="Subtraction_2"
                    data-name="Subtraction 2"
                    d="M14.031,27.452A14.032,14.032,0,0,1,8.291.615V2.378a12.427,12.427,0,1,0,10.582-.422c-.243-.1-.493-.2-.742-.286V0a14.143,14.143,0,0,1,7.12,5,14.031,14.031,0,0,1-11.22,22.454Z"
                    transform="translate(0 5.436)"
                    fill="#fff"
                    stroke="#fff"
                    strokeWidth="2"
                  />
                  <rect
                    id="Rectangle_28"
                    data-name="Rectangle 28"
                    width="3.28"
                    height="11.479"
                    transform="translate(11.572 0)"
                    fill="#fc7f00"
                  />
                </g>
              </svg>
            </span>
          </Tooltip>
        </li>
      </ul>
    </>
  );
};

export default Sidebarprofile;
