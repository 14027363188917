import React from "react";
import { Spin } from "antd";
const Loader = (props) => {
  return (
    <div className="Loader">
      <Spin />
    </div>
  );
};

export { Loader };
