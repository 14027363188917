import React, { useEffect, useState } from "react";
import {
  Layout,
  Row,
  Col,
  Input,
  Space,
  message,
  Card,
  Form,
  Button
} from "antd";
import { PageTitle } from "../../common/components/layout/";
import "../../modules/campaigns/campaigns.less";
import { ApiRoutes } from "../../config/ApiRoutes";
import { ApiHelper } from "../../Helpers";
const { TextArea } = Input;
// import { Loader } from "../../common/components/";

const { Content } = Layout;
const title = "Campaign Setting";

const Settings = () => {
  const [form] = Form.useForm();
  const [isPageLoader, setIsPageLoader] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState();
  useEffect(() => {
    /* eslint-disable-next-line */
    fetchData(); /* eslint-disable-line */
  }, []);
  const onFinish = async (values) => {
    setIsLoading(true);
    const response = await new ApiHelper().FetchFromServer(
      ApiRoutes.SETTING_UPDATE.service,
      ApiRoutes.SETTING_UPDATE.url,
      ApiRoutes.SETTING_UPDATE.method,
      ApiRoutes.SETTING_UPDATE.authenticate,
      undefined,
      values
    );
    setIsLoading(false);
   
    if (response.code === 200) {
      message.success({
        content: response.data.message,
        duration: 2
      });
    } else {
      message.error({
        content: response.messages || response.error,
        duration: 2
      });
    }
  };
  const fetchData = async () => {
    const response = await new ApiHelper().FetchFromServer(
      ApiRoutes.SETTING_FETCH.service,
      ApiRoutes.SETTING_FETCH.url,
      ApiRoutes.SETTING_FETCH.method,
      ApiRoutes.SETTING_FETCH.authenticate
    );
    setIsPageLoader(true);
    setData(response.data.data);
    if (response.data.data)
      form.setFieldsValue({
        privacyPolicy: response.data.data?.privacyPolicy,
        websiteToBrand: response.data.data?.websiteToBrand,
        disclaimer: response.data.data?.disclaimer
      });
  };

  return (
    <>
      <Space className="single-filter-sec m-d-block headerFix">
        <PageTitle title={title} />
      </Space>
      <Content
        className="content-body"
        style={{ minHeight: 200, margin: "0px 25px" }}
        id="Campaigns"
      >
        <Row>
          <Col lg={24} xs={24}>
            <div style={{ margin: "0px 14px 14px 14px" }}>
              <Row className="campaignAddBox" gutter="24">
                {isPageLoader ? (
                  <Col xl={24} xs={24}>
                    <Form
                      layout="vertical"
                      onFinish={onFinish}
                      validateTrigger="onSubmit"
                      form={form}
                    >
                      <div>
                        <Card className="campaignInformationCard profileSec">
                          <Form.Item
                            label="Privacy Policy"
                            name="privacyPolicy"
                            initialValue={data?.privacyPolicy}
                            // onChange={(e) => changeValue(e, "terms")}
                            // rules={[
                            //   {
                            //     required: true,
                            //     message: "Please input your privacy policy"
                            //   }
                            // ]}
                          >
                            <TextArea />
                          </Form.Item>
                          <Form.Item
                            label="Website to brand"
                            name="websiteToBrand"
                            initialValue={data?.websiteToBrand}
                            // onChange={(e) => changeValue(e, "terms")}
                            // rules={[
                            //   {
                            //     required: true,
                            //     message: "Please input your website to brand"
                            //   }
                            // ]}
                          >
                            <TextArea />
                          </Form.Item>
                          <Form.Item
                            label="Disclaimer"
                            name="disclaimer"
                            initialValue={data?.disclaimer}
                            // onChange={(e) => changeValue(e, "terms")}
                            // rules={[
                            //   {
                            //     required: true,
                            //     message: "Please input your disclaimer"
                            //   }
                            // ]}
                          >
                            <TextArea />
                          </Form.Item>
                          <Col xl={24} xs={24}>
                            {isLoading ? (
                              <Button
                                type="primary"
                                loading
                                htmlType="submit"
                                style={{ float: "right" }}
                              >
                                Submit
                              </Button>
                            ) : (
                              <Button
                                type="primary"
                                htmlType="submit"
                                style={{ float: "right" }}
                              >
                                Submit
                              </Button>
                            )}
                          </Col>
                        </Card>
                      </div>
                    </Form>
                  </Col>
                ) : (
                  ""
                )}
              </Row>
            </div>
          </Col>
        </Row>
      </Content>
    </>
  );
};
export default Settings;
