import React, { useState, useEffect } from "react";
import { Inventory } from "../../../modules/join/Inventory";
import "../../../modules/join/join.less";
import { useLocation, useHistory } from "react-router-dom";
import { message, Form } from "antd";
import { Loader } from "../../../common/components/Loader";
import { ApiRoutes } from "../../../config/ApiRoutes";
import { ApiHelper, getCampaignUserData } from "../../../Helpers";
import Geocode from "react-geocode";
Geocode.setApiKey(process.env.REACT_APP_MAP_KEY);

const JoinCampaignsInventory = () => {
  let lan = getCampaignUserData() ? getCampaignUserData().language : "EN";
  const history = useHistory();
  const search = useLocation().search;
  const queryString = new URLSearchParams(search);
  const [inventoryData, setInventoryData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [code, setCode] = React.useState("");
  const [stateData, setStateData] = React.useState([]);
  const [cityData, setCityData] = React.useState([]);
  const [latitude, setLatitude] = useState("");
  const [longitude, setLongitude] = useState("");
  const [currentState, setCurrentState] = useState(0);
  // const [locationData, setLocationData] = useState([]);
  const [initialLocation, setInitialLocation] = useState({
    lat: "",
    lng: ""
  });
  const [isEdit, setIsEdit] = useState(true);
  const [isCampaignJoin, setIsCampaignJoin] = useState(false);
  const [loading, setLoading] = useState(true);
  const [form] = Form.useForm();
  const [storeForm] = Form.useForm();
  const [campaignData, setCampaignData] = useState(null);
  const [accountStatus, setAccountStatus] = useState("");

  useEffect(() => {
    getInventoryList();
    getStateList();

    // eslint-disable-next-line
  }, []);
  useEffect(() => {
    const div = document.getElementById("wrapper");
    if (div && div.classList.contains("overflow-hidden")) {
      div.classList.remove("overflow-hidden");
    }
    // eslint-disable-next-line
  }, []);

  const getInventoryList = async () => {
    setIsLoading(true);
    const response = await new ApiHelper().FetchFromServer(
      ApiRoutes.STORE_PRODUCT_LIST.service,
      ApiRoutes.STORE_PRODUCT_LIST.url,
      ApiRoutes.STORE_PRODUCT_LIST.method,
      ApiRoutes.STORE_PRODUCT_LIST.authenticate,
      {
        organizationId: getCampaignUserData().organizationId,
        campaignId: queryString.get("campaignId")
      }
    );
    const responseCampaign = await new ApiHelper().FetchFromServer(
      ApiRoutes.STORE_CAMPAIGN_DETAIL.service,
      ApiRoutes.STORE_CAMPAIGN_DETAIL.url,
      ApiRoutes.STORE_CAMPAIGN_DETAIL.method,
      ApiRoutes.STORE_CAMPAIGN_DETAIL.authenticate,
      {
        id: queryString.get("campaignId"),
        organizationId: getCampaignUserData().organizationId
      }
    );
    const responseLocation = await new ApiHelper().FetchFromServer(
      ApiRoutes.GET_LOCATION.service,
      ApiRoutes.GET_LOCATION.url,
      ApiRoutes.GET_LOCATION.method,
      ApiRoutes.GET_LOCATION.authenticate,
      undefined,
      {
        organizationId: getCampaignUserData().organizationId,
        employeeId: getCampaignUserData().employeeId
      }
    );
    const responseStatus = await new ApiHelper().FetchFromServer(
      ApiRoutes.VERIFY_PLAID_STATUS.service,
      ApiRoutes.VERIFY_PLAID_STATUS.url,
      ApiRoutes.VERIFY_PLAID_STATUS.method,
      ApiRoutes.VERIFY_PLAID_STATUS.authenticate,
      {
        organizationId: getCampaignUserData().organizationId
      },
      undefined
    );
    setIsLoading(false);
    setLoading(false);
    if (responseStatus.code === 200) {
      setAccountStatus(responseStatus.data.status);
    }
    if (responseCampaign.code === 200) {
      if (responseCampaign.data !== undefined) {
        setCampaignData(responseCampaign.data);
        if (responseCampaign.data.campaign.campaignJoin > 0) {
          if (responseStatus.data.status === "unverified") {
            setCurrentState(1);
          } else {
            setCurrentState(2);
          }
          setIsCampaignJoin(true);
        }
      }
    }
    if (response.code === 200) {
      if (response.data === undefined) {
        setInventoryData(null);
      } else {
        for (let x in response.data.data) {
          if (response.data.data[x].currentQty === "0.00") {
            response.data.data[x].currentQty = "";
          } else {
            response.data.data[x].currentQty = parseInt(
              response.data.data[x].currentQty
            );
          }
        }
        setInventoryData(response.data.data);
      }
    }
    if (responseLocation.code === 200) {
      if (responseLocation.data === undefined) {
        // setLocationData([]);
      } else {
        if (responseLocation.data.data[0]) {
          await getCityList(responseLocation.data.data[0].stateId);
          storeForm.setFieldsValue({
            state: responseLocation.data.data[0].stateId,
            city: responseLocation.data.data[0].city,
            streetAddress: responseLocation.data.data[0].addressLine1,
            zip: responseLocation.data.data[0].zip
          });
          if (
            responseLocation.data.data[0].latitude &&
            responseLocation.data.data[0].longitude
          ) {
            setLatitude(responseLocation.data.data[0].latitude);
            setLongitude(responseLocation.data.data[0].longitude);
            setInitialLocation({
              lat: responseLocation.data.data[0].latitude,
              lng: responseLocation.data.data[0].longitude
            });
          } else {
            handleCityChange();
          }
        }
        // setLocationData(responseLocation.data.data);
      }
    }
  };

  const onFinish = async () => {
    const values = await form.validateFields();
    let checkQty;
    for (let x in values.products) {
      if (values.products[x].currentQty) {
        checkQty = values.products[x].currentQty;
      }
    }
    if (!checkQty) {
      message.error({
        content: "Please fill stock quantity of at least one product.",
        duration: 5
      });
      return;
    }
    setIsLoading(true);
    const response = await new ApiHelper().FetchFromServer(
      ApiRoutes.STORE_JOIN_CAMPAIGN.service,
      ApiRoutes.STORE_JOIN_CAMPAIGN.url,
      ApiRoutes.STORE_JOIN_CAMPAIGN.method,
      ApiRoutes.STORE_JOIN_CAMPAIGN.authenticate,
      undefined,
      {
        products: JSON.stringify(values.products),
        organizationId: getCampaignUserData().organizationId,
        campaignId: queryString.get("campaignId")
      }
    );
    setIsLoading(false);
    if (response.code === 200) {
      const status =
        campaignData && campaignData.campaign.campaignJoin > 0 ? 1 : 0;
      history.push(`/join-campaign/success?u=${status}`);
    } else {
      message.error({
        content: response.messages || response.error,
        duration: 2
      });
    }
  };

  const handleStoreSubmit = async () => {
    const values = await storeForm.validateFields();
    setIsLoading(true);
    let cityName, stateName;
    for (let x in stateData) {
      if (stateData[x].stateId === values.state) {
        stateName = stateData[x].stateName;
        break;
      }
    }
    for (let x in cityData) {
      if (cityData[x].cityId === values.city) {
        cityName = cityData[x].cityName;
        break;
      }
    }
    const address = `${values.streetAddress} ${cityName} ${stateName} ${values.zip}`;
    const latLng = await Geocode.fromAddress(address);
    if (latLng.status === "OK") {
      const { lat, lng } = latLng.results[0].geometry.location;
      setLatitude(lat);
      setLongitude(lng);
      setIsEdit(false);
      setInitialLocation({
        lat,
        lng
      });
    }
    // alert(`${values.streetAddress} ${cityName} ${stateName} ${values.zip} ${process.env.REACT_APP_MAP_KEY}`);
    if (latitude === "" || longitude === "longitude") {
      message.info({
        content: "Verify or change location then click on next button.",
        duration: 2
      });
      setIsLoading(false);
      return false;
    }
    setIsLoading(true);
    const response = await new ApiHelper().FetchFromServer(
      ApiRoutes.SAVE_LATLONG.service,
      ApiRoutes.SAVE_LATLONG.url,
      ApiRoutes.SAVE_LATLONG.method,
      ApiRoutes.SAVE_LATLONG.authenticate,
      undefined,
      {
        organizationId: getCampaignUserData().organizationId,
        employeeId: getCampaignUserData().employeeId,
        latitude: latitude,
        longitude: longitude,
        state: values.state,
        city: values.city,
        addressLine1: values.addressLine1,
        zip: values.zip
      }
    );
    setIsLoading(false);
    if (response.code === 200) {
      if (accountStatus === "unverified") {
        setCurrentState(1);
      } else {
        setCurrentState(2);
      }
    } else {
      message.error({
        content: response.messages || response.error,
        duration: 2
      });
    }
  };

  const getStateList = async () => {
    const response = await new ApiHelper().FetchFromServer(
      ApiRoutes.STATE_LIST.service,
      ApiRoutes.STATE_LIST.url,
      ApiRoutes.STATE_LIST.method,
      ApiRoutes.STATE_LIST.authenticate,
      { country: getCampaignUserData().country }
    );
    if (response.code === 200) {
      setStateData(response.data.data);
    }
  };

  const getCityList = async (state) => {
    if (!state) {
      setCityData([]);
      return false;
    }
    const response = await new ApiHelper().FetchFromServer(
      ApiRoutes.CITY_LIST.service,
      ApiRoutes.CITY_LIST.url,
      ApiRoutes.CITY_LIST.method,
      ApiRoutes.CITY_LIST.authenticate,
      { state: state }
    );
    if (response.code === 200) {
      setCityData(response.data.data);
    }
  };

  const handleStateChange = async (value) => {
    storeForm.setFieldsValue({
      city: ""
    });
    getCityList(value);
    handleCityChange();
  };

  const handleCityChange = async () => {
    // const values = await storeForm.validateFields();
    const values = await storeForm.getFieldsValue(true);
    if (values.state && values.city && values.streetAddress && values.zip) {
      let cityName, stateName;
      for (let x in stateData) {
        if (stateData[x].stateId === values.state) {
          stateName = stateData[x].stateName;
          break;
        }
      }
      for (let x in cityData) {
        if (cityData[x].cityId === values.city) {
          cityName = cityData[x].cityName;
          break;
        }
      }
      const address = `${values.streetAddress} ${cityName} ${stateName} ${values.zip}`;
      const latLng = await Geocode.fromAddress(address);
      if (latLng.status === "OK") {
        const { lat, lng } = latLng.results[0].geometry.location;
        setInitialLocation({
          lat: "",
          lng: ""
        });
        setLatitude(lat);
        setLongitude(lng);
        // setIsEdit(false);
        setInitialLocation({
          lat,
          lng
        });
      }
    }
  };

  const onChangeEdit = async (e) => {
    setIsEdit(e);
    setInitialLocation({
      lat: "",
      lng: ""
    });
    setLatitude("");
    setLongitude("");
  };

  return (
    <>
      {loading ? (
        <section
          className="text-center"
          style={{
            height: "80vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center"
          }}
        >
          <Loader />
        </section>
      ) : (
        <div id="Join">
          {inventoryData.length > 0 ? (
            <Inventory
              inventoryData={inventoryData}
              onFinish={onFinish}
              isLoading={isLoading}
              code={code}
              setCode={setCode}
              form={form}
              storeForm={storeForm}
              handleStoreSubmit={handleStoreSubmit}
              stateData={stateData}
              cityData={cityData}
              handleStateChange={handleStateChange}
              handleCityChange={handleCityChange}
              currentState={currentState}
              setCurrentState={setCurrentState}
              setLatitude={setLatitude}
              setLongitude={setLongitude}
              isCampaignJoin={isCampaignJoin}
              initialLocation={initialLocation}
              isEdit={isEdit}
              onChangeEdit={onChangeEdit}
              campaignData={campaignData}
              accountStatus={accountStatus}
              lan={lan}
            />
          ) : (
            ""
          )}
        </div>
      )}
    </>
  );
};
export default JoinCampaignsInventory;
