
import React,{useEffect} from "react";
import { AllNotification } from "../../../modules/join/AllNotification";
import "../../../modules/join/join.less";


const Notifications = () => {
  useEffect(() => {
    const div=document.getElementById('wrapper');
    if(div && div.classList.contains("overflow-hidden")){
      div.classList.remove('overflow-hidden')
    }
    // eslint-disable-next-line
  }, []);
  // const search = useLocation().search;
   return (
    <>
      <div id="Join"> 
            <AllNotification/>
      </div>
    </>
  );
};
export default Notifications;
