import React, { Component } from 'react';
import { Map, GoogleApiWrapper, Marker } from 'google-maps-react';

class Maps extends Component {
    constructor(props) {
        super(props);
        this.state = {
            markers: [
                {
                    name: "Current position",
                    position: this.props.initialLocation
                }
            ],
            centerPosition: {
                lat: '',
                lng: ''
            },
            showMap: false
        }
    }

    onMarkerDragEnd = (coord, index) => {
        const { latLng } = coord;
        const lat = latLng.lat();
        const lng = latLng.lng();
        this.props.setLatitude(lat);
        this.props.setLongitude(lng);

        this.setState(prevState => {
            const markers = [...this.state.markers];
            markers[index] = { ...markers[index], position: { lat, lng } };
            return { markers };
        });
    }

    getLocation = () => {
        if (navigator && navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(pos => {
                const coords = pos.coords;

                let newState = Object.assign({}, this.state);
                newState.markers[0].position.lat = coords.latitude;
                newState.markers[0].position.lng = coords.longitude;

                this.setState(newState);
                this.setState({
                    centerPosition: { lat: coords.latitude, lng: coords.longitude }
                }, () => {
                    this.setState({ showMap: true })
                })
                /* this.setState(prevState => {
                    const markers = [...this.state.markers];
                    markers[0] = { ...markers[0], position: { lat: coords.latitude, lng: coords.longitude } };
                    return { markers };
                }); */
            });
        }
        else {
            this.setState({
                showMap: true
            })
        }
    }

    componentDidMount() {
        // this.getLocation()
        this.setState({
            showMap: true
        })
    }

    render() {
        if (this.state.showMap) {

            return (

                <div style={{position:'relative', display: 'inline-block', width: '100%', height: '100%'}}>
                    <Map
                        google={this.props.google}
                        style={{
                            width: "100%",
                            height: "300px"
                        }}
                        zoom={14}
                        initialCenter={{ lat: this.state.markers[0].position.lat, lng: this.state.markers[0].position.lng }}
                        // initialCenter={{ lat: this.state.centerPosition.lat, lng: this.state.centerPosition.lng }}
                    >
                        {this.state.markers.map((marker, index) => (
                            <Marker
                                key={index}
                                position={marker.position}
                                draggable={true}
                                onDragend={(t, map, coord) => this.onMarkerDragEnd(coord, index)}
                                name={marker.name}
                            />
                        ))}
                    </Map>
                </div>
            );
        }
        else {
            return (
                <p></p>
            )
        }
    }
}

export default GoogleApiWrapper({
    apiKey: process.env.REACT_APP_MAP_KEY
})(Maps);