import { Row, Col, Button } from "antd";
import { motion } from "framer-motion";
import React from "react";
import { Link } from "react-router-dom";
// import Successful from '../../assets/images/Join/successful.png'
const Success = (props) => {
  const { status } = props;
  return (
    <>
      <Row gutter={[24, 24]}>
        <Col className="successContainer" xs={{ span: 24, offset: 0 }} lg={{ span: 8, offset: 8 }}>
          <section className="successSec shadow">
            <svg
              className="checkmark"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 52 52"
            >
              <circle
                className="checkmark__circle"
                cx="26"
                cy="26"
                r="25"
                fill="none"
              />
              <path
                className="checkmark__check"
                fill="none"
                d="M14.1 27.2l7.1 7.2 16.7-16.8"
              />
            </svg>
            <motion.div
              className="container"
              initial={{ scale: 0 }}
              animate={{ scale: 1 }}
              transition={{
                delayChildren: 0.9,
                staggerChildren: 0.8
              }}
            >
              {status===1 || status==="1"  ? (
                <>
                  <h1 className="mt-5">Successfully Updated!</h1>
                  <p>Stock Updated Successfully</p>
                </>
              ) : (
                <>
                  <h1 className="mt-5">Successfully Joined!</h1>
                  <p>You have Successfully joined the campaign</p> 
                </>
              )}

              <Link to={`/join-campaign`}>
                <Button type="primary" className="btnDone">
                  Done
                </Button>
              </Link>
            </motion.div>
          </section>
        </Col>
      </Row>
    </>
  );
};

export { Success };
