let data;
export const EnviornmentType = {
  DEV: "development",
  PROD: "production",
};
export const limit = 12;
export const env = EnviornmentType.DEV;
data = {
  API_ENDPOINT:process.env.REACT_APP_DEV_URL,
  API_VERSION: "",
  CAMPAIGN_URL: process.env.REACT_APP_CAMPAIGN_URL,
  JOIN_LOGIN_URL: process.env.REACT_APP_JOIN_LOGIN_URL,
  DOMAIN_LENGTH:process.env.REACT_APP_DOMAIN_LENGTH
};
data.DEFAULT_DATE_FORMAT = "LLL";

export const AppConfig = data;
