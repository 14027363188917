/** @format */

const messages = {
  searchCampaigns: {
    EN: "Search Campaigns",
    ES: "Campañas de búsqueda",
    FR: "Campagnes de recherche",
    PT: "Campanhas de pesquisa",
    GU: "શોધો ઝુંબેશ",
    ZH: "搜索广告系列",
  },
  sortByBrand: {
    EN: "Sort By : Brand",
    ES: "Ordenar por : Marca",
    FR: "Trier par : Marque",
    PT: "Classificar por: marca",
    GU: "દ્વારા સૉર્ટ કરો : બ્રાન્ડ",
    ZH: "排序方式 : 品牌",
  },
  join: {
    EN: "Join",
    ES: "Entrar",
    FR: "Rejoindre",
    PT: "Junte-se",
    GU: "જોડાઓ",
    ZH: "加入",
  },
  editStock: {
    EN: "Edit Stock",
    ES: "Editar existencias",
    FR: "Modifier l'action",
    PT: "Editar estoque",
    GU: "ઇન્વેન્ટરી સંપાદિત કરો",
    ZH: "编辑股票",
  },
  blockCampaign: {
    EN: "Block Campaign",
    ES: "Bloquear campaña",
    FR: "Bloquer la campagne",
    PT: "Bloquear campanha",
    GU: "બ્લોક ઝુંબેશ",
    ZH: "阻止活动",
  },
  unblockCampaign: {
    EN: "Unblock Campaign",
    ES: "Desbloquear campaña",
    FR: "Débloquer la campagne",
    PT: "Desbloquear campanha",
    GU: "ઝુંબેશને અનાવરોધિત કરો",
    ZH: "取消阻止活动",
  },
  blockBrand: {
    EN: "Block Brand",
    ES: "Marca de bloque",
    FR: "Marque de bloc",
    PT: "Marca do bloco",
    GU: "બ્લોક બ્રાન્ડ",
    ZH: "块品牌",
  },
  unblockBrand: {
    EN: "Unblock Brand",
    ES: "Desbloquear marca",
    FR: "Débloquer la marque",
    PT: "Desbloquear marca",
    GU: "બ્રાન્ડને અનાવરોધિત કરો",
    ZH: "解锁品牌",
  },
  products: {
    EN: "Products",
    ES: "Productos",
    FR: "Des produits",
    PT: "Produtos",
    GU: "ઉત્પાદનો",
    ZH: "产品",
  },
  off: {
    EN: "Off",
    ES: "Apagada",
    FR: "Désactivé",
    PT: "Fora",
    GU: "બંધ",
    ZH: "离开",
  },
  noOfPaymentCodes: {
    EN: "No of Payment Codes",
    ES: "Nº de códigos de pago",
    FR: "Nombre de codes de paiement",
    PT: "Nº de códigos de pagamento",
    GU: "ચુકવણી કોડની સંખ્યા",
    ZH: "付款代码数量",
  },
  totalOfferValue: {
    EN: "Total Offer Value",
    ES: "Valor total de la oferta",
    FR: "Valeur totale de l'offre",
    PT: "Valor total da oferta",
    GU: "કુલ ઓફર મૂલ્ય",
    ZH: "总报价",
  },
  storeInformation: {
    EN: "Store Information",
    ES: "Almacenar información",
    FR: "Informations sur le magasin",
    PT: "Guardar informação",
    GU: "સ્ટોર માહિતી",
    ZH: "储存信息",
  },
  verifyAccount: {
    EN: "Verify Account",
    ES: "Verificar Cuenta",
    FR: "vérifier le compte",
    PT: "Verificar conta",
    GU: "એકાઉન્ટ ચકાસો",
    ZH: "验证账户",
  },
  addInventory: {
    EN: "Add Inventory",
    ES: "Agregar inventario",
    FR: "Ajouter un inventaire",
    PT: "Adicionar inventário",
    GU: "ઈન્વેન્ટરી ઉમેરો",
    ZH: "添加库存",
  },
  next: {
    EN: "Next",
    ES: "Próxima",
    FR: "Suivante",
    PT: "Próxima",
    GU: "આગળ",
    ZH: "下一个",
  },
  previous: {
    EN: "Previous",
    ES: "Previa",
    FR: "Précédente",
    PT: "Anterior",
    GU: "અગાઉનું",
    ZH: "以前的",
  },
  joinCampaign: {
    EN: "Join Campaign",
    ES: "Únete a la campaña",
    FR: "Rejoindre la campagne",
    PT: "Junte-se à campanha",
    GU: "ઝુંબેશમાં જોડાઓ",
    ZH: "加入活动",
  },
  updateStock: {
    EN: "Update Stock",
    ES: "Actualizar existencias",
    FR: "Mettre à jour les stocks",
    PT: "Atualizar estoque",
    GU: "સ્ટોક અપડેટ કરો",
    ZH: "更新库存",
  },
  state: {
    EN: "State",
    ES: "Expresar",
    FR: "État",
    PT: "Estada",
    GU: "રાજ્ય",
    ZH: "状态",
  },
  selectState: {
    EN: "Select State",
    ES: "Seleccione estado",
    FR: "Sélectionnez l'état",
    PT: "Selecionar estado",
    GU: "રાજ્ય પસંદ કરો",
    ZH: "选择州",
  },
  pleaseSelectYourState: {
    EN: "Please select your state",
    ES: "Por favor seleccione su estado",
    FR: "Veuillez sélectionner votre état",
    PT: "Veuillez sélectionner votre état",
    GU: "કૃપા કરીને તમારું રાજ્ય પસંદ કરો",
    ZH: "请选择您所在的州",
  },
  city: {
    EN: "City",
    ES: "Ciudad",
    FR: "Ville",
    PT: "Cidade",
    GU: "શહેર",
    ZH: "城市",
  },
  pleaseSelectYourCity: {
    EN: "Please select your city",
    ES: "Por favor selecciona tu ciudad",
    FR: "Veuillez sélectionner votre ville",
    PT: "Por favor, selecione sua cidade",
    GU: "કૃપા કરીને તમારું શહેર પસંદ કરો",
    ZH: "请选择您的城市",
  },
  selectCity: {
    EN: "Select City",
    ES: "Ciudad selecta",
    FR: "Sélectionnez une ville",
    PT: "Selecione a cidade",
    GU: "શહેર પસંદ કરો",
    ZH: "选择城市",
  },
  streetAddress: {
    EN: "Street Address",
    ES: "Dirección",
    FR: "Adresse de la rue",
    PT: "Endereço da Rua",
    GU: "શેરીનુ સરનામું",
    ZH: "街道地址",
  },
  pleaseInputYourStreetAddress: {
    EN: "Please input your street address",
    ES: "Por favor ingrese su dirección postal",
    FR: "Veuillez saisir votre adresse postale",
    PT: "Por favor, insira seu endereço",
    GU: "કૃપા કરીને તમારું શેરી સરનામું ઇનપુટ કરો",
    ZH: "请输入您的街道地址",
  },
  invalidStreetAddress: {
    EN: "Invalid street address",
    ES: "Dirección de calle no válida",
    FR: "Adresse municipale invalide",
    PT: "Endereço inválido",
    GU: "અમાન્ય શેરી સરનામું",
    ZH: "街道地址无效",
  },
  enterAddress: {
    EN: "Enter Address",
    ES: "Ingresa la direccion",
    FR: "Entrer l'adresse",
    PT: "Insira o endereço",
    GU: "સરનામું દાખલ કરો",
    ZH: "输入地址",
  },
  zipCode: {
    EN: "Zip Code",
    ES: "Código postal",
    FR: "Code postal",
    PT: "Código postal",
    GU: "પિન કોડ",
    ZH: "邮政编码",
  },
  pleaseInputYourZipCode: {
    EN: "Please input your zip code",
    ES: "Por favor ingrese su código postal",
    FR: "Veuillez entrer votre code postal",
    PT: "Por favor, insira seu CEP",
    GU: "કૃપા કરીને તમારો પિન કોડ દાખલ કરો",
    ZH: "请输入您的邮政编码",
  },
  invalidZipCode: {
    EN: "Invalid zip code",
    ES: "Código postal no válido",
    FR: "code postal invalide",
    PT: "CEP inválido",
    GU: "અમાન્ય પિન કોડ",
    ZH: "无效的邮政编码",
  },
  setGeocoordinate: {
    EN: "Set Geocoordinate",
    ES: "Establecer geocoordenada",
    FR: "Définir la géocoordonnée",
    PT: "Definir geocoordenada",
    GU: "Geocoordinate સેટ કરો",
    ZH: "设置地理坐标",
  },
  pleaseFillInTheFollowingFields: {
    EN: "Please, fill in the following fields",
    ES: "Por favor, rellene los siguientes campos",
    FR: "Veuillez remplir les champs suivants",
    PT: "Por favor, preencha os seguintes campos",
    GU: "કૃપા કરીને, નીચેના ફીલ્ડ્સ ભરો",
    ZH: "请填写以下字段",
  },
  verifyAccountDetails: {
    EN: "Verify Account Details",
    ES: "Verificar los detalles de la cuenta",
    FR: "Vérifier les détails du compte",
    PT: "Verificar detalhes da conta",
    GU: "એકાઉન્ટ વિગતો ચકાસો",
    ZH: "验证帐户详细信息",
  },
  verify: {
    EN: "Verify",
    ES: "Verificar",
    FR: "Vérifier",
    PT: "Verificar",
    GU: "ખરાઈ કરો",
    ZH: "核实",
  },
  pleaseVerifyYourAccount: {
    EN: "Please verify your account",
    ES: "Por favor, verifique su cuenta",
    FR: "Veuillez vérifier votre compte",
    PT: "Por favor, verifique sua conta",
    GU: "કૃપા કરીને તમારું એકાઉન્ટ ચકાસો",
    ZH: "请验证您的帐户",
  },
  verified: {
    EN: "Verified",
    ES: "Verificada",
    FR: "Vérifié",
    PT: "Verificada",
    GU: "ચકાસણી",
    ZH: "已验证",
  },
  yourAccountDetailSuccessfullyVerified: {
    EN: "Your account detail successfully verified",
    ES: "Los detalles de su cuenta se verificaron con éxito",
    FR: "Les détails de votre compte ont été vérifiés avec succès",
    PT: "Os detalhes da sua conta foram verificados com sucesso",
    GU: "તમારા એકાઉન્ટની વિગતો સફળતાપૂર્વક ચકાસવામાં આવી",
    ZH: "您的帐户详细信息已成功验证",
  },
  upc: {
    EN: "UPC",
    ES: "UPC",
    FR: "CUP",
    PT: "UPC",
    GU: "UPC",
    ZH: "UPC",
  },
  invalidStock: {
    EN: "Invalid stock",
    ES: "Stock no válido",
    FR: "Stock invalide",
    PT: "Estoque inválido",
    GU: "અમાન્ય ઇન્વેન્ટરી",
    ZH: "无效库存",
  },
  enterStock: {
    EN: "Enter Stock",
    ES: "Ingresar existencias",
    FR: "Entrer en stock",
    PT: "Insira o estoque",
    GU: "ઇન્વેન્ટરી દાખલ કરો",
    ZH: "输入库存",
  },
}

export { messages };
