import React, { useState, useEffect } from "react";
import { Layout, message } from "antd";
import { CampaignList } from "../../modules/public/";
import InfiniteScroll from "react-infinite-scroller";
import { Loader, EmptyComponents } from "../../common/components/";
import { ApiRoutes } from "../../config/ApiRoutes";
import { ApiHelper, getCampaignUserData } from "../../Helpers";
import { limit } from "../../config/AppConfig";

import "../../modules/public/public.less";
const { Content } = Layout;
const Public = () => {
  const [offset, setOffset] = useState(0);
  const [hasMore, setHasMore] = useState(true);
  const [loading, setLoading] = useState(true);
  const [campaign, setCampaign] = useState([]);

  useEffect(async () => {
    const userInfo = await getCampaignUserData();
    if (userInfo.organizationId)
      getCampaignList(offset, true, userInfo.organizationId);
    // eslint-disable-next-line
  }, []);

  const getCampaignList = async (
    page = offset,
    filter = true,
    organizationId = getCampaignUserData().organizationId
  ) => {
    //setLoading(true);
    const response = await new ApiHelper().FetchFromServer(
      ApiRoutes.STORE_CAMPAIGN_LIST.service,
      ApiRoutes.STORE_CAMPAIGN_LIST.url,
      ApiRoutes.STORE_CAMPAIGN_LIST.method,
      ApiRoutes.STORE_CAMPAIGN_LIST.authenticate,
      {
        limit: limit,
        search: "",
        stateId: "",
        brandOrganizationId: organizationId,
        blockStatus: "",
        offset: page + 1
      }
    );
    setOffset(page + 1);

    if (response.code === 200) {
      if (response.data.data.length > 0) {
        if (response.data.data.length < limit) setHasMore(false);
        if (campaign.length > 0 && filter) {
          setCampaign([...campaign, ...response.data.data]);
        } else setCampaign(response.data.data);
      } else setHasMore(false);
    } else {
      message.error({
        content: response.messages || response.error,
        duration: 2
      });
      setHasMore(false);
      setCampaign([]);
    }
    setLoading(false);
  };

  return (
    <>
      <Layout>
        <Content className="publicContentContainer"
          style={{ minHeight: 200, padding: "80px", background: "#fff" }}
        >
          {loading ? (
            <section
              className="text-center"
              style={{
                height: "80vh",
                display: "flex",
                justifyContent: "center",
                alignItems: "center"
              }}
            >
              <Loader />
            </section>
          ) : campaign.length > 0 ? (
            <div id="Join">
              <InfiniteScroll
                pageStart={offset}
                loadMore={getCampaignList}
                hasMore={hasMore}
                loader={
                  <section
                    className="text-center"
                    style={{
                      height: "80vh",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center"
                    }}
                  >
                    <Loader />
                  </section>
                }
              >
                <CampaignList
                  campaign={campaign}
                  loading={loading}
                  brandData={getCampaignUserData()}
                />{" "}
              </InfiniteScroll>
            </div>
          ) : (
            <EmptyComponents
              title="Campaign"
              message="No campaigns available."
            />
          )}
        </Content>
      </Layout>
    </>
  );
};
export default Public;
