import { Row, Col } from "antd";
import React from "react";
import Managestock from "./Managestock";
import Nigeriamap from "./ng/Nigeriamap";
import Ukmap from "./uk/Ukmap";
import Usmap from "./us/Usmap";
import moment from "moment"
import { Emptymsg } from "../../common/components/layout/";

const CountryGraph = (props) => {
  const { campaign, heatMap, loader,campaignData } = props;
   let status =
  moment().format("YYYY-MM-DD") > campaignData.CEndDate
      ? "Expired"
      : parseInt(campaignData?.Cstatus) === 2
      ? "Published"
      : parseInt(campaignData?.Cstatus) === 3
      ? "Active"
      : parseInt(campaignData?.Cstatus) === 4
      ? "Expired"
      : "Draft";
  return (
    <Row gutter={[16, 16]}>
      {campaign?.total === null && loader === false ?
        <Col lg={24} xs={24}>
          <Emptymsg
            emptytitle={""}
            emptydescription={"No merchant has been joined"}
          />
        </Col>
        : <>
          <Col lg={24} xs={24}>
              <span className={`float-right statusBox ${status}`}>{status}</span>
          </Col>
          <Col lg={8} xs={24}>
            <Managestock country={campaign?.isoCode} campaign={campaign}  />
          </Col>
          <Col lg={16} xs={24}>
            {campaign?.isoCode === "UK" ? (<>
              {heatMap ? <Ukmap heatMap={heatMap} /> : ''}
            </>) : ''}
            {campaign?.isoCode === "NGA"  ? (<>
              {heatMap ? <Nigeriamap heatMap={heatMap} /> : ''}
            </>) : ''}
            {campaign?.isoCode === "US" || campaign?.isoCode === "USA" ? (<>
              {heatMap ? <Usmap heatMap={heatMap} /> : ''}
            </>) : ''}
          </Col>
        </>
      }
    </Row>
  );
};
export { CountryGraph };
