import React, { useEffect, useState } from "react";
import { Input, Button, message, Form } from "antd";
import { MailOutlined, LockOutlined } from "@ant-design/icons";
import { useHistory, Link } from "react-router-dom";
import { ApiRoutes } from "../../config/ApiRoutes";
import { ApiHelper, setUserData, getToken } from "../../Helpers";

const FrontendLoginBusiness = (props) => {
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    if (getToken() !== "" && getToken() !== null) {
      history.push({
        pathname: `/campaigns`
      });
    }
    // eslint-disable-next-line
  }, []);
  const onFinish = async (values) => {
    setIsLoading(true);
    const response = await new ApiHelper().FetchFromServer(
      ApiRoutes.LOGIN.service,
      ApiRoutes.LOGIN.url,
      ApiRoutes.LOGIN.method,
      ApiRoutes.LOGIN.authenticate,
      undefined,
      {
        email: values.email,
        password: values.password,
        type : props.type
      }
    );
    setIsLoading(false);
    if (response.code === 200) {
      response.data.data.token = response.data.token;
      if (props.type === 1 || props.type === "1") {
        setUserData(response.data.data);

        history.push({
          pathname: `/campaigns`
        });
      } else {
        window.location.href = `${process.env.REACT_APP_JOIN_LOGIN_URL}p?token=${response.data.token}`;
        // window.location.href = `${process.env.REACT_APP_JOIN_LOGIN_URL}/?token=${response.data.token}`;
      }
    } else {
      message.error({
        content: response.message || response.messages || response.error,
        duration: 2
      });
    }
  };

  const onFinishFailed = (errorInfo) => { };

  return (
    <>
      <Form
        layout="vertical"
        className="login-form mt-3"
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        initialValues={{
          remember: true
        }}
      >
        <Form.Item
          label="Email"
          name="email"
          rules={[
            {
              required: true,
              message: "Please input your username/Email!"
            }
          ]}
        >
          <Input
            placeholder="Enter email"
            suffix={<MailOutlined className="site-form-item-icon" />}
          />
        </Form.Item>
        <Form.Item
          label="Password"
          name="password"
          rules={[
            {
              required: true,
              message: "Please input your password!"
            }
          ]}
        >
          <Input.Password
            placeholder="Enter password"
            suffix={<LockOutlined className="site-form-item-icon" />}
          />
        </Form.Item>
        <Form.Item className="btn-section mb-0">
          {isLoading ?
            <Button type="primary" loading>
              Login
            </Button>
            : <Button type="primary" htmlType="submit">
              Login
            </Button>}
          <Link className="login-form-forgot" to="/forget-password">
            Forgot password?
          </Link>
        </Form.Item>
      </Form>
    </>
  );
};

export default FrontendLoginBusiness;
